import { ReportMetric } from '@mnd-frontend/graphql-schema';

export const getVisibleMetrics = (
  visibleMetrics: ReportMetric[],
  engagement: number,
  socialMediaReach: number,
): ReportMetric[] => {
  return visibleMetrics.reduce((selectedMetrics: ReportMetric[], currentMetric) => {
    if (
      (currentMetric === ReportMetric.Engagement && engagement === 0) ||
      (currentMetric === ReportMetric.EstimatedSocialMediaReach && socialMediaReach === 0)
    ) {
      return selectedMetrics;
    }

    return [...selectedMetrics, currentMetric];
  }, []);
};
