import React from 'react';
import { Trans } from 'react-i18next';
import styled, { css } from 'styled-components';
import { GREY_1000, GREY_600, TEXT_FONT } from '../../theme';
import TooltipHelp from '../TooltipHelp';

const StyledLabel = styled.label<{
  pointer?: boolean;
  top?: boolean;
  $bold?: boolean;
  $disabled?: boolean;
}>`
  color: ${props => (props.$disabled ? GREY_600 : GREY_1000)};
  font-family: ${TEXT_FONT};
  font-size: 1rem;
  font-weight: ${props => (!props.$bold ? 400 : 600)};
  line-height: 1.25;
  cursor: ${props => (props.pointer ? 'pointer' : undefined)};
  ${({ top }) =>
    top
      ? css`
          display: block;
          flex-direction: column;
          align-items: flex-start;
        `
      : css`
          display: flex;
          align-items: center;
          align-content: center;
          & > * {
            flex-grow: inherit;
          }
        `};
`;

const LabelLeft = styled.span`
  flex-shrink: 0;
  flex-grow: 0;

  button {
    margin-left: 0.25rem;
  }
`;

/**
 * Exported to be overwritable.
 */
export const LabelRight = styled.span`
  flex-shrink: 0;
  flex-grow: 0;
  margin-left: 1rem;
`;

const LabelTop = styled.div`
  margin-bottom: 0.25rem;
`;

const LabelDescription = styled.p`
  margin: 0.25rem 0;
  display: block;
  font-weight: normal;
  color: ${GREY_1000};
  font-size: 1rem;
  line-height: 1.5rem;
`;

const Label = ({
  text,
  description,
  position = 'right',
  pointer = false,
  children,
  help,
  bold = true,
  htmlFor = undefined,
  disabled = false,
}: {
  text?: string;
  description?: string;
  position?: 'left' | 'right' | 'top';
  pointer?: boolean;
  children?: React.ReactNode;
  help?: string;
  bold?: boolean;
  htmlFor?: string;
  disabled?: boolean;
}): React.ReactElement | null => {
  if (!text) {
    return <>{children}</>;
  }

  return (
    <StyledLabel
      top={position === 'top'}
      pointer={pointer}
      htmlFor={htmlFor}
      $bold={bold}
      $disabled={disabled}
    >
      {position === 'top' && (
        <LabelTop>
          <Trans>{text}</Trans>
          {help && <TooltipHelp header={text} content={help} placement="top" />}
          {description && (
            <LabelDescription>
              {' '}
              <Trans>{description}</Trans>
            </LabelDescription>
          )}
        </LabelTop>
      )}
      {position === 'left' && (
        <LabelLeft>
          <Trans>{text}</Trans>
          {help && <TooltipHelp header={text} content={help} placement="top" />}
        </LabelLeft>
      )}
      {children}
      {position === 'right' && (
        <LabelRight>
          <Trans>{text}</Trans>
          {help && <TooltipHelp header={text} content={help} placement="top" />}
        </LabelRight>
      )}
    </StyledLabel>
  );
};
export default Label;
