import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SHADOW_2 } from '../../theme';
import { Button } from '../Button';
import media from '../utils/media';

const SidePanelContainer = styled.div<{ $mobileOpen: boolean; $wide?: boolean }>`
  background: white;
  box-shadow: ${SHADOW_2};
  padding: 1.5rem 1.5rem 6rem 1.5rem;
  width: ${props => (props.$wide ? '30rem' : '20rem')};
  ${media.lessThan('tablet')} {
    padding: 1rem 1rem 6rem 0.5rem;
    position: fixed;
    z-index: 102; /* Over header and code editor (code editor z-index 101) */
    width: 100%;
    right: -100%;
    top: 0;
    bottom: 0;
    transition: transform 0.25s;
    transform: ${props => props.$mobileOpen && 'translateX(-100%)'};
    overflow-y: scroll;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  ${media.greaterThan('tablet')} {
    display: none;
  }
`;

export const FacehuggerSidePanel = ({
  mobileOpen,
  wide,
  setMobileOpen,
  children,
}: {
  mobileOpen: boolean;
  setMobileOpen: (b: boolean) => void;
  wide?: boolean;
  children: React.ReactNode;
}) => {
  const [t] = useTranslation();
  return (
    <SidePanelContainer $wide={wide} $mobileOpen={mobileOpen}>
      <ButtonWrapper>
        <Button configuration="toned" type="button" onClick={() => setMobileOpen(false)}>
          {t('common_close')}
        </Button>
      </ButtonWrapper>
      {children}
    </SidePanelContainer>
  );
};
