import { components, defaultTheme } from 'react-select';
import styled, { css } from 'styled-components';
import { GREY_800 } from '../../../../theme';

type Props = Parameters<typeof components.GroupHeading>[number];

const GroupHeading = (props: Props) => <components.GroupHeading {...props} theme={defaultTheme} />;

const StyledGroupHeading: any = styled(GroupHeading)`
  && {
    color: ${GREY_800};
    color: #a0a9b3;
    font-size: 0.75rem;
    font-weight: 400;
    margin: 0;
    text-transform: uppercase;

    ${({ children }) =>
      children
        ? css`
            padding: 0.5rem 0.5rem 0.5rem 1rem;
          `
        : ''}
  }
`;

export default StyledGroupHeading;
