import styled, { css } from 'styled-components';
import { ContentType } from './types';

type CellProps = {
  $withWrap?: boolean;
  $textAlign?: 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit';
  $contentType?: ContentType;
  $withOverflow?: boolean;
};

const Cell = styled.td<CellProps>`
  padding: 1.5rem;
  text-align: ${({ $textAlign }) => $textAlign ?? 'initial'};
  color: black;
  background-color: white;
  white-space: ${({ $withWrap }) => ($withWrap ? 'normal' : 'nowrap')};
  overflow-wrap: ${({ $withWrap }) => ($withWrap ? 'break-word' : 'normal')};
  word-wrap: ${({ $withWrap }) => ($withWrap ? 'break-word' : 'normal')};
  word-break: ${({ $withWrap }) => ($withWrap ? 'break-word' : 'normal')};
  ${({ $withOverflow }) => !$withOverflow && 'overflow: hidden'};

  ${({ $contentType }) =>
    $contentType === 'numeric' &&
    css`
      text-align: right;
    `}
`;

export default Cell;
