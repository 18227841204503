import React, { Children, cloneElement } from 'react';
import styled, { css } from 'styled-components';
import Flex, { FlexType } from '../Flex/Flex';
import Item from './LayoutItem';
import { marginLeft, marginTop, rem } from './helper';

const LayoutContainer = styled.div`
  display: inline-block;
  width: 100%;
`;

const FlexWithGaps = styled(Flex)<{ $gap?: number | number[] }>`
  flex-wrap: wrap;
  ${props =>
    props.$gap &&
    css`
      margin-top: ${rem(-marginTop(props.$gap))};
      margin-left: ${rem(-marginLeft(props.$gap))};
    `}
  pointer-events: none; /* to not catch events from around due to the negative margin */

  & > * {
    flex-shrink: 0;
    ${props =>
      props.$gap &&
      css`
        margin-top: ${rem(marginTop(props.$gap))};
        margin-left: ${rem(marginLeft(props.$gap))};
      `}
    pointer-events: auto;
  }
`;

const Layout: React.FC<FlexType & { gap?: number | number[]; children?: React.ReactNode }> = ({
  children,
  gap,
  ...props
}) => (
  <LayoutContainer>
    <FlexWithGaps $gap={gap} {...props}>
      {Children.map(children, (child: any) =>
        child && (child.type?.displayName === Item.name || child.type === Item)
          ? cloneElement(child, { $gap: gap })
          : child,
      )}
    </FlexWithGaps>
  </LayoutContainer>
);

Layout.displayName = 'Layout';

export default Layout;
