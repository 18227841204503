import { ReactNode } from 'react';
import styled, { DefaultTheme } from 'styled-components';
import {
  DESKTOP_BADGE_HORIZONTAL_GAP,
  DESKTOP_BADGE_HORIZONTAL_PADDING,
  SIZING_1_5,
  SIZING_2_5,
  SPACING_1_5,
  SPACING_2,
} from '../../theme';

export type BadgeVariant =
  | 'commercial'
  | 'informational'
  | 'success'
  | 'warning'
  | 'danger'
  | 'neutral';

export type BadgeProps = {
  text: string;
  variant?: BadgeVariant;
  icon?: ReactNode;
  onDark?: boolean;
};

interface VariantToColorProps {
  variant: BadgeVariant;
  onDark: boolean;
  theme: DefaultTheme;
}

export type MaterialCardStatusVariant =
  | 'archived'
  | 'draft'
  | 'published'
  | 'scheduled'
  | 'info'
  | 'active'
  | 'inactive';

export const materialStatusToBadgeVariant = (status?: MaterialCardStatusVariant): BadgeVariant => {
  switch (status) {
    case 'archived':
    case 'draft':
      return 'neutral';
    case 'active':
    case 'published':
      return 'success';
    case 'info':
      return 'informational';
    case 'inactive':
    case 'scheduled':
      return 'neutral';
    default:
      return 'informational';
  }
};

const variantToBackgroundColor = ({ variant, onDark, theme }: VariantToColorProps) => {
  switch (variant) {
    case 'commercial':
      return onDark
        ? theme.colorBackgroundBadgeOnDarkCommercial
        : theme.colorBackgroundBadgeOnLightCommercial;
    case 'informational':
      return onDark
        ? theme.colorBackgroundBadgeOnDarkInformational
        : theme.colorBackgroundBadgeOnLightInformational;
    case 'success':
      return onDark
        ? theme.colorBackgroundBadgeOnDarkSuccess
        : theme.colorBackgroundBadgeOnLightSuccess;
    case 'warning':
      return onDark
        ? theme.colorBackgroundBadgeOnDarkWarning
        : theme.colorBackgroundBadgeOnLightWarning;
    case 'danger':
      return onDark
        ? theme.colorBackgroundBadgeOnDarkDanger
        : theme.colorBackgroundBadgeOnLightDanger;
    default:
      return onDark
        ? theme.colorBackgroundBadgeOnDarkNeutral
        : theme.colorBackgroundBadgeOnLightNeutral;
  }
};

const variantToTextColor = ({ variant, onDark, theme }: VariantToColorProps) => {
  switch (variant) {
    case 'commercial':
      return onDark ? theme.colorTextBadgeOnDarkCommercial : theme.colorTextBadgeOnLightCommercial;
    case 'informational':
      return onDark
        ? theme.colorTextBadgeOnDarkInformational
        : theme.colorTextBadgeOnLightSecondary;
    case 'success':
      return onDark ? theme.colorTextBadgeOnDarkSuccess : theme.colorTextBadgeOnLightSuccess;
    case 'warning':
      return onDark ? theme.colorTextBadgeOnDarkWarning : theme.colorTextBadgeOnLightWarning;
    case 'danger':
      return onDark ? theme.colorTextBadgeOnDarkDanger : theme.colorTextBadgeOnLightDanger;
    default:
      return onDark ? theme.colorTextBadgeOnDarkNeutral : theme.colorTextBadgeOnLightNeutral;
  }
};

const BadgeWrapper = styled.span<{ $variant: BadgeVariant; $onDark: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: ${DESKTOP_BADGE_HORIZONTAL_GAP};
  /* height: ${props => props.theme.sizingPillHeight}; sizingPillHeight not in Design tokens yet*/
  height: ${SIZING_2_5}; //temporary
  padding: 0 ${DESKTOP_BADGE_HORIZONTAL_PADDING};
  border-radius: ${props => props.theme.radiusBadge};
  font-weight: 600;
  font-size: ${SPACING_1_5};
  line-height: ${SPACING_2};
  font-size: ${SIZING_1_5};
  width: fit-content;
  color: ${props =>
    variantToTextColor({ variant: props.$variant, onDark: props.$onDark, theme: props.theme })};
  background-color: ${props =>
    variantToBackgroundColor({
      variant: props.$variant,
      onDark: props.$onDark,
      theme: props.theme,
    })};
  i {
    width: ${SIZING_1_5};
    height: ${SIZING_1_5};
  }
`;

export const Badge = ({ variant = 'neutral', icon, text, onDark = false }: BadgeProps) => {
  return (
    <BadgeWrapper $variant={variant} $onDark={onDark} role="status" data-testid={text}>
      {icon}
      <span>{text}</span>
    </BadgeWrapper>
  );
};
