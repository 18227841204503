import React from 'react';
import styled from 'styled-components';
import { Tag } from '../atoms';
import { ItemStyle, TagData } from '../atoms/Tag';

const TagUl = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-left: -0.5rem;
  margin-top: -0.5rem;

  > * {
    margin: 0.5rem 0 0 0.5rem;
  }

  ::after {
    content: '';
    flex-grow: 1000000000;
  }
`;

type CommonProps = {
  items: TagData[];
  itemStyle?: ItemStyle;
  onInteraction: (data: TagData) => void;
  shadow?: boolean;
  variant?: 'primary' | 'secondary' | 'dark' | 'purple' | 'prAssistant';
};

type Props =
  | (CommonProps & {
      interactionType?: 'dismiss';
      dismissAriaLabel: string;
    })
  | (CommonProps & {
      interactionType: 'none';
      dismissAriaLabel?: undefined;
    })
  | (CommonProps & {
      interactionType: 'click';
      dismissAriaLabel?: undefined;
    });

const TagList: React.FC<Props> = props => (
  <TagUl>
    {props.items.map(item => (
      <Tag key={item.id} data={item} {...props} />
    ))}
  </TagUl>
);

export default TagList;
