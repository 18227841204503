import styled from 'styled-components';
import { GREY_400 } from '../../theme';

const StyledSegment = styled.div<{ $centered?: boolean }>`
  padding: 1.5rem;
  text-align: ${props => props.$centered && 'center'};

  &:not(:last-child) {
    border-bottom: 1px solid ${GREY_400};
  }
`;

export default StyledSegment;
