import { components, defaultTheme } from 'react-select';
import styled, { css } from 'styled-components';
import { GREY_400 } from '../../../../theme';

type Props = Parameters<typeof components.Group>[number];

const Group = (props: Props) => <components.Group {...props} theme={defaultTheme} />;

const StyledGroup: any = styled(Group)`
  && {
    margin: 0;
    padding: 0rem 0 0;

    ${({ data }) =>
      !data.label &&
      css`
        border-top: 1px solid ${GREY_400};
      `}

    &:first-child {
      padding: 0.5rem 0 0;
    }
  }
`;

export default StyledGroup;
