import { UntypedFunction } from '@mnd-frontend/ts';
import { ChangeEventHandler, KeyboardEventHandler, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { EffectReducer, useEffectReducer } from 'use-effect-reducer';
import { Input, InputProps } from '../Input';

const isHexColor = (hex: string) => hex.length === 6 && !Number.isNaN(Number(`0x${hex}`));
const normalizeColor = (hex: string) => {
  let normalized = hex.replace(/^#+/, '');
  if (normalized.length === 3) {
    normalized = normalized
      .split('')
      .map(c => c + c)
      .join('');
  }
  return normalized.toUpperCase();
};

const Wrapper = styled.div<{ $disabled: boolean }>`
  position: relative;
`;

const NativeColorPicker = styled.input.attrs(props => ({ ...props, type: 'color' }))`
  margin: 0;
  padding: 0;
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 3rem;
  z-index: 3;
  opacity: 0;
  cursor: pointer;
`;

const ColorSwatch = styled.div`
  height: 1.5rem;
  width: 1.5rem;
`;

const ColorSwatchBlock = styled.div`
  display: block;
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 0.125rem;
`;

type OnChange = (event: {
  target: {
    value: string;
    id?: string;
    name?: string;
  };
}) => void;

type Props = InputProps & {
  value?: string;
  onChange?: OnChange;
  id?: string;
  name?: string;
  disabled?: boolean;
};

const supportsNativeColorPicker = (() => {
  try {
    const input = document.createElement('input');
    input.type = 'color';
    input.value = '!';
    return input.type === 'color' && input.value !== '!';
  } catch (e) {
    return false;
  }
})();

const debounceOptions = {
  maxWait: 100,
  leading: true,
};

type State = {
  swatchOpen: boolean;
  lastValidValue: string;
  presentationValue: string;
  propValue: string;
};

const initialState: (value: string) => State = value => ({
  swatchOpen: false,
  lastValidValue: value,
  presentationValue: value,
  propValue: value,
});

type Effect = {
  type: 'onChange';
  value: string;
};

type Action =
  | {
      type: 'SWATCH_BLUR';
    }
  | {
      type: 'TEXT_BLUR';
    }
  | {
      type: 'SWATCH_FOCUS';
    }
  | {
      type: 'TEXT_FOCUS';
    }
  | {
      type: 'TEXT_CHANGE';
      value: string;
    }
  | {
      type: 'SWATCH_CHANGE';
      value: string;
    }
  | {
      type: 'NEW_PROP_VALUE';
      value: string;
    }
  | {
      type: 'TEXT_ENTER';
      key: string;
    };

const reducer: EffectReducer<State, Action, Effect> = (state, action, exec): State => {
  switch (action.type) {
    case 'NEW_PROP_VALUE':
      return {
        ...state,
        propValue: action.value,
        presentationValue: action.value.toUpperCase(),
      };
    case 'SWATCH_BLUR':
      exec({
        type: 'onChange',
        value: state.presentationValue,
      });
      return {
        ...state,
        lastValidValue: state.presentationValue,
        swatchOpen: false,
      };
    case 'SWATCH_FOCUS':
      return {
        ...state,
        lastValidValue: state.propValue,
        swatchOpen: true,
      };
    case 'TEXT_FOCUS':
      return {
        ...state,
        lastValidValue: state.propValue,
      };
    case 'TEXT_CHANGE': {
      const value = action.value.toUpperCase();
      if (!state.swatchOpen) {
        exec({
          type: 'onChange',
          value,
        });
      }

      return {
        ...state,
        presentationValue: value,
      };
    }
    case 'SWATCH_CHANGE':
      return {
        ...state,
        presentationValue: action.value.toUpperCase(),
      };
    case 'TEXT_BLUR':
    case 'TEXT_ENTER': {
      const color = normalizeColor(state.presentationValue);
      const newValue = isHexColor(color) ? `#${color}` : state.lastValidValue;
      exec({
        type: 'onChange',
        value: newValue,
      });
      return {
        ...state,
        lastValidValue: newValue,
        presentationValue: newValue,
      };
    }
  }
};

const noop: UntypedFunction = () => {}; // eslint-disable-line @typescript-eslint/no-empty-function

const ColorInput = ({
  value = '#FFFFFF',
  onChange = noop,
  id,
  name,
  disabled = false,
  ...props
}: Props) => {
  const [state, dispatch] = useEffectReducer<State, Action, Effect>(
    reducer,
    () => initialState(value),
    {
      onChange: (_state, event) => {
        onChange({
          target: {
            value: event.value,
            id,
            name,
          },
        });
      },
    },
  );

  useEffect(() => {
    dispatch({ type: 'NEW_PROP_VALUE', value });
  }, [dispatch, value]);

  const handleTextChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    e => dispatch({ type: 'TEXT_CHANGE', value: e.target.value }),
    [dispatch],
  );
  const handleTextKeydown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    e => {
      if (e.key === 'Enter') {
        e.preventDefault();
        dispatch({ type: 'TEXT_ENTER', key: e.key });
      }
    },
    [dispatch],
  );
  const handleColorInputFocus = useCallback(() => dispatch({ type: 'SWATCH_FOCUS' }), [dispatch]);
  const handleTextInputFocus = useCallback(() => dispatch({ type: 'TEXT_FOCUS' }), [dispatch]);
  const handleTextBlur = useCallback(() => dispatch({ type: 'TEXT_BLUR' }), [dispatch]);
  const handleColorBlur = useCallback(() => dispatch({ type: 'SWATCH_BLUR' }), [dispatch]);
  const pickerChange = useCallback(
    (value: string) => dispatch({ type: 'SWATCH_CHANGE', value }),
    [dispatch],
  );

  const debouncedPickerChange = useDebouncedCallback(pickerChange, 20, debounceOptions);

  const handlePickerChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      debouncedPickerChange(event.target.value);
    },
    [debouncedPickerChange],
  );

  const color = normalizeColor(state.presentationValue);
  const presentationColor = isHexColor(color) ? `#${color}` : state.lastValidValue;

  return (
    <Wrapper $disabled={disabled}>
      {supportsNativeColorPicker && (
        <NativeColorPicker
          aria-label="color-swatch"
          disabled={disabled}
          onBlur={handleColorBlur}
          onFocus={handleColorInputFocus}
          onChange={handlePickerChange}
          tabIndex={-1}
          value={presentationColor}
        />
      )}
      <Input
        {...props}
        id={id}
        disabled={disabled}
        onBlur={handleTextBlur}
        onFocus={handleTextInputFocus}
        onChange={handleTextChange}
        onKeyDown={handleTextKeydown}
        value={state.presentationValue}
        leadingIcon={
          <ColorSwatch role="presentation">
            <ColorSwatchBlock style={{ background: presentationColor }} />
          </ColorSwatch>
        }
      />
    </Wrapper>
  );
};

export default ColorInput;
