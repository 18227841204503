import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

import { v1 as uuid } from 'uuid';
import { Toast, Toaster, useTranslation } from '../';

type Toasts = {
  showSuccessToast: (message: Toast['message'], icon?: Toast['icon']) => void;
  showWarningToast: (message: Toast['message']) => void;
  showFailureToast: (message?: Toast['message'] | null) => void;
  showLoadingToast: (toast: Omit<Toast, 'id' | 'type' | 'onClose'>) => {
    close: () => void;
    updateMessage: (message: Toast['message']) => void;
  };
};

const ToastsContext = createContext<Toasts>({
  showSuccessToast: () => null,
  showFailureToast: () => null,
  showWarningToast: () => null,
  showLoadingToast: () => ({
    close: () => null,
    updateMessage: () => null,
  }),
});

export const ToastsContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { t } = useTranslation();
  const [toasts, setToasts] = useState<Toast[]>([]);

  const showSuccessToast: Toasts['showSuccessToast'] = useCallback((message, icon) => {
    const id = uuid();
    setToasts(currentToasts => [
      ...currentToasts,
      {
        id,
        type: 'SUCCESS',
        message,
        icon,
        onClose: () => {
          setToasts(currentToasts => currentToasts.filter(t => t.id !== id));
        },
      },
    ]);
  }, []);
  const showWarningToast: Toasts['showWarningToast'] = useCallback(message => {
    const id = uuid();
    setToasts(currentToasts => [
      ...currentToasts,
      {
        id,
        type: 'WARNING',
        message,
        onClose: () => {
          setToasts(currentToasts => currentToasts.filter(t => t.id !== id));
        },
      },
    ]);
  }, []);
  const showLoadingToast: Toasts['showLoadingToast'] = useCallback(toast => {
    const id = uuid();
    setToasts(currentToasts => [
      ...currentToasts,
      {
        id,
        type: 'LOADING',
        message: toast?.message,
        action: toast?.action,
        onClose: () => {
          setToasts(currentToasts => currentToasts.filter(t => t.id !== id));
        },
      },
    ]);
    return {
      close: () => {
        setToasts(currentToasts => currentToasts.filter(t => t.id !== id));
      },
      updateMessage: message => {
        setToasts(currentToasts => currentToasts.map(t => (t.id === id ? { ...t, message } : t)));
      },
    };
  }, []);
  const showFailureToast: Toasts['showFailureToast'] = useCallback(
    message => {
      const id = uuid();
      setToasts(currentToasts => [
        ...currentToasts,
        {
          id,
          type: 'ERROR',
          message: message ?? t('common_general_error'),
          onClose: () => {
            setToasts(currentToasts => currentToasts.filter(t => t.id !== id));
          },
        },
      ]);
    },
    [t],
  );
  const onRemove = useCallback(({ id }: Pick<Toast, 'id'>) => {
    setToasts(currentToasts => currentToasts.filter(toast => toast.id !== id));
  }, []);

  return (
    <ToastsContext.Provider
      value={useMemo(
        () => ({ showSuccessToast, showWarningToast, showFailureToast, showLoadingToast }),
        [showFailureToast, showWarningToast, showSuccessToast, showLoadingToast],
      )}
    >
      {children}
      <Toaster toasts={toasts} onRemove={onRemove} />
    </ToastsContext.Provider>
  );
};

export const useToasts = () => useContext(ToastsContext);
