import { components, defaultTheme } from 'react-select';
import styled from 'styled-components';
import { GREY_1000, GREY_400 } from '../../../../theme';

type Props = Parameters<typeof components.Placeholder>[number];

const Placeholder = (props: Props) => <components.Placeholder {...props} theme={defaultTheme} />;

const StyledPlaceholder: any = styled(Placeholder)`
  && {
    color: ${props => (props.isDisabled ? GREY_400 : GREY_1000)};
    font-style: italic;
    margin: 0;
    opacity: 0.7;
    background-color: transparent;
  }
`;

export default StyledPlaceholder;
