import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Card } from '../Card';
import CardSection from '../Card/components/CardSection';
import { Number } from '../Typo';
import { Counter } from '../atoms';

const StyledCardSection = styled(CardSection)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

interface NumberCardProps {
  icon: ReactNode;
  number?: number | null;
  text: ReactNode;
  precision?: BigIntToLocaleStringOptions['maximumFractionDigits'];
  isTrend?: boolean;
  type?: 'small' | 'big' | 'default' | 'time' | 'percentage';
  isLoading?: boolean;
}

const NumberCard: React.FunctionComponent<NumberCardProps> = ({
  icon,
  number,
  text,
  type,
  precision,
  isTrend,
  isLoading = false,
}) => (
  <Card loading={isLoading}>
    <StyledCardSection>
      {icon}
      <Number>
        {isTrend && number && number > 0 ? '+' : null}
        <Counter
          value={number ?? 0}
          precision={precision ?? (type === 'small' ? 2 : undefined)}
          minPrecision={precision ?? (type === 'small' ? 0 : undefined)}
          abbreviate={type === 'big'}
          isTime={type === 'time'}
        />
        {type === 'percentage' && '%'}
      </Number>
      {text}
    </StyledCardSection>
  </Card>
);

export default NumberCard;
