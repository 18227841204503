import { NetworkContactImportedVia } from '@mnd-frontend/graphql-schema';
import { useState } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from '../../contexts/UIContext';
import { GREY_200, SIZING_1 } from '../../theme';
import Icons from '../Icons';
import Tooltip from '../Tooltip';

type BaseSize = 'xs' | 'sm' | 'md' | 'lg';

export type ContactSize = BaseSize | 'xl';

type ThumbnailSize = BaseSize;

export const getInitials = (name = ''): string => {
  if (!name.trim()) return '';

  const tokens = name.trim().split(/\s+/);
  if (tokens.length === 0) return '';

  const firstInitial = tokens[0][0];
  const lastInitial = tokens.length > 1 ? tokens[tokens.length - 1][0] : '';

  return `${firstInitial}${lastInitial}`.toUpperCase();
};

const ContactWrapper = styled.div<{ $size?: ContactSize; $withImage?: boolean }>`
  display: grid;
  align-items: center;
  justify-content: center;
  background: ${({ $withImage, theme }) =>
    $withImage ? 'transparent' : theme.colorBackgroundAvatarContact};
  border-radius: 50%;
  color: ${({ theme }) => theme.colorTextAvatarContact};
  font-weight: 700;
  flex-shrink: 0;
  overflow: hidden;
  ${({ $size }) => {
    switch ($size) {
      case 'xs':
        return css`
          font-size: 0.5rem;
          height: ${({ theme }) => theme.sizingAvatarContactXs};
          width: ${({ theme }) => theme.sizingAvatarContactXs};
          i {
            width: ${SIZING_1};
            height: ${SIZING_1};
          }
        `;
      case 'sm':
        return css`
          font-size: 0.75rem;
          height: ${({ theme }) => theme.sizingAvatarContactSm};
          width: ${({ theme }) => theme.sizingAvatarContactSm};
          i {
            width: ${({ theme }) => theme.sizingIconXxs};
            height: ${({ theme }) => theme.sizingIconXxs};
          }
        `;
      case 'md':
        return css`
          font-size: 1.25rem;
          height: ${({ theme }) => theme.sizingAvatarContactMd};
          width: ${({ theme }) => theme.sizingAvatarContactMd};
          i {
            width: ${({ theme }) => theme.sizingIconXs};
            height: ${({ theme }) => theme.sizingIconXs};
          }
        `;
      case 'lg':
        return css`
          font-size: 2rem;
          height: ${({ theme }) => theme.sizingAvatarContactLg};
          width: ${({ theme }) => theme.sizingAvatarContactLg};
          i {
            width: ${({ theme }) => theme.sizingIconSm};
            height: ${({ theme }) => theme.sizingIconSm};
          }
        `;
      case 'xl':
        return css`
          font-size: 2.5rem;
          height: ${({ theme }) => theme.sizingAvatarContactXl};
          width: ${({ theme }) => theme.sizingAvatarContactXl};
          i {
            width: ${({ theme }) => theme.sizingIconMd};
            height: ${({ theme }) => theme.sizingIconMd};
          }
        `;
      default:
        return css`
          font-size: 1.25rem;
          height: ${({ theme }) => theme.sizingAvatarContactMd};
          width: ${({ theme }) => theme.sizingAvatarContactMd};
        `;
    }
  }}
`;

const AvatarImage = styled.img<{ $error?: boolean }>`
  object-fit: cover;
  width: 100%;
  height: 100%;
  ${({ $error }) =>
    $error &&
    css`
      display: none;
    `}
`;

const RelativeContainer = styled.div`
  position: relative;
`;

const ContactIconWrapper = styled.div`
  position: absolute;
  bottom: -2px;
  right: 0;
  pointer-events: all;
`;

const ThumbnailWrapper = styled.div<{ $size?: ThumbnailSize; $withImage?: boolean }>`
  background: ${({ $withImage }) => ($withImage ? 'transparent' : GREY_200)};
  flex-shrink: 0;
  overflow: hidden;
  ${({ $size }) => {
    switch ($size) {
      case 'xs':
        return css`
          border-radius: ${({ theme }) => theme.radiusAvatarMediaOutletXs};
          height: ${({ theme }) => theme.sizingAvatarMediaOutletXs};
          width: ${({ theme }) => theme.sizingAvatarMediaOutletXs};
        `;
      case 'sm':
        return css`
          border-radius: ${({ theme }) => theme.radiusAvatarMediaOutletSm};
          height: ${({ theme }) => theme.sizingAvatarMediaOutletSm};
          width: ${({ theme }) => theme.sizingAvatarMediaOutletSm};
        `;
      case 'md':
        return css`
          border-radius: ${({ theme }) => theme.radiusAvatarMediaOutletMd};
          height: ${({ theme }) => theme.sizingAvatarMediaOutletMd};
          width: ${({ theme }) => theme.sizingAvatarMediaOutletMd};
        `;
      case 'lg':
        return css`
          border-radius: ${({ theme }) => theme.radiusAvatarMediaOutletLg};
          height: ${({ theme }) => theme.sizingAvatarMediaOutletLg};
          width: ${({ theme }) => theme.sizingAvatarMediaOutletLg};
        `;
      default:
        return css`
          border-radius: ${({ theme }) => theme.radiusAvatarMediaOutletSm};
          height: ${({ theme }) => theme.sizingAvatarMediaOutletSm};
          width: ${({ theme }) => theme.sizingAvatarMediaOutletSm};
        `;
    }
  }}
`;

type ContactContentProps = {
  image?: string | null;
  name?: string;
  icon?: React.ReactNode;
  imageError: boolean;
  onImageError: () => void;
};

const ContactContent = ({ image, name, icon, imageError, onImageError }: ContactContentProps) => {
  if (image && !imageError)
    return (
      <AvatarImage
        src={image}
        alt={name}
        data-testid="avatar-image"
        onError={onImageError}
        $error={imageError}
      />
    );
  else if (name) return <span>{getInitials(name)}</span>;
  else if (icon) return icon;
  return <Icons.Contact />;
};

type AvatarBase<Size extends string> = {
  size?: Size;
  image?: string | null;
};

type Contact = AvatarBase<ContactSize> & {
  type: 'contact';
  name?: string;
  icon?: React.ReactNode;
  importedVia?: NetworkContactImportedVia | null;
};

type Thumbnail = AvatarBase<ThumbnailSize> & {
  type: 'thumbnail';
};

type AvatarProps = Contact | Thumbnail;

const Avatar = ({ size, image, type, ...props }: AvatarProps) => {
  const [contactImageError, setContactImageError] = useState(false);
  const [thumbnailImageError, setThumbnailImageError] = useState(false);
  const { t } = useTranslation();

  const importedFromMnd =
    'importedVia' in props && props?.importedVia
      ? [
          NetworkContactImportedVia.MediaRelations,
          NetworkContactImportedVia.FindJournalist,
          NetworkContactImportedVia.AudienceTargeting,
        ].includes(props.importedVia)
      : false;

  switch (type) {
    case 'thumbnail':
      return (
        <ThumbnailWrapper $size={size} $withImage={!!image}>
          {image && !thumbnailImageError && (
            <AvatarImage
              src={image}
              data-testid="thumbnail-image"
              $error={thumbnailImageError}
              onError={() => setThumbnailImageError(true)}
            />
          )}
        </ThumbnailWrapper>
      );
    case 'contact':
      return (
        <RelativeContainer>
          <ContactWrapper $size={size} $withImage={!!image && !contactImageError}>
            <ContactContent
              image={image}
              imageError={contactImageError}
              onImageError={() => setContactImageError(true)}
              {...props}
            />
          </ContactWrapper>
          {importedFromMnd && size !== 'xs' && size !== 'sm' && (
            <ContactIconWrapper>
              <Tooltip content={t('tooltip-imported_from_mynewsdesk')} placement="bottom">
                <Icons.MndFilled $color="RED_700" $size={size === 'xl' ? 'xs' : 'xxs'} />
              </Tooltip>
            </ContactIconWrapper>
          )}
        </RelativeContainer>
      );
    default:
      return null;
  }
};

export default Avatar;
