import React from 'react';
import styled from 'styled-components';
import { PURPLE_300 } from '../../../theme';

const Mark = styled.mark`
  background-color: ${PURPLE_300};
`;

type Props = { highlight?: string; children: string };

const Highlighter = ({ highlight, children }: Props) => {
  if (!highlight?.trim()) return <>{children}</>;

  const regex = new RegExp(`(${highlight})`, 'gi');
  const parts = children.split(regex);
  return (
    <>
      {parts
        .filter(part => part)
        .map((part, i) =>
          regex.test(part) ? (
            <Mark key={i}>{part}</Mark>
          ) : (
            <React.Fragment key={i}>{part}</React.Fragment>
          ),
        )}
    </>
  );
};

export default Highlighter;
