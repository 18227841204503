import { ReactNode, useEffect, useId, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { DESKTOP_MAIN_TABS_HORIZONTAL_GAP, SIZING_0_5, SPACING_1, SPACING_2 } from '../../theme';
import media from '../utils/media';

const Wrapper = styled.div`
  background: inherit;
`;

export const TabHeader = styled.div<{ $sticky?: boolean }>`
  ${props =>
    props.$sticky &&
    css`
      position: sticky;
      z-index: 3;
      top: 0;
      background: inherit;
    `}
`;

const Filter = styled.div`
  padding: ${SPACING_2} 0;
  ${media.lessThan('tablet')} {
    padding: ${SPACING_1} 0;
  }
`;

export const TabList = styled.div<{
  $variant: 'subTabs' | 'mainTabs';
  $fullWidth: boolean;
}>`
  display: ${props => (props.$variant === 'mainTabs' || props.$fullWidth ? 'flex' : 'inline-flex')};
  justify-content: ${props => (props.$fullWidth ? 'stretch' : 'flex-start')};
  overflow-x: auto;
  ${props =>
    props.$variant === 'subTabs'
      ? css`
          border: 1px solid ${props.theme.colorBorderSubTabDefault};
          border-radius: ${props.theme.radiusSubTabs};
          background: ${props.theme.colorBackgroundSubTabInactive};
        `
      : css`
          border-bottom: 1px solid ${props.theme.colorBorderDividerDefault};
          gap: ${props.$fullWidth ? false : DESKTOP_MAIN_TABS_HORIZONTAL_GAP};
        `}
  height: ${props =>
    props.$variant === 'subTabs'
      ? props.theme.sizingSubTabsHeight
      : props.theme.sizingMainTabItemHeight};

  > button {
    cursor: pointer;
    height: 100%;
    flex-shrink: 1;
    flex: ${props => props.$fullWidth && '1'};
    border: 0;

    ${props =>
      props.$variant === 'subTabs'
        ? css`
            border-radius: ${props.theme.radiusSubTabItem};
            color: ${props => props.theme.colorTextSubTabItemInactive};
            padding: 0 1rem;

            &:hover {
              color: ${props.theme.colorTextSubTabItemHover};
              background: ${props.theme.colorBackgroundSubTabHover};
            }
          `
        : css`
            border-bottom: ${SIZING_0_5} solid transparent;
            color: ${props.theme.colorTextMainTabItemInactive};
            padding: ${props.$fullWidth && '0 0.5rem'};
            &:hover {
            }
          `}
    font-weight: 500;
    background: inherit;
    white-space: nowrap;
    &[aria-selected='true'] {
      ${props =>
        props.$variant === 'subTabs'
          ? css`
              color: ${props => props.theme.colorTextSubTabItemActive};
              background: ${props => props.theme.colorBackgroundSubTabActive};
            `
          : css`
              border-bottom: ${SIZING_0_5} solid
                ${props => props.theme.colorBorderMainTabItemActive};
            `}
      pointer-events: none;
    }
  }
`;

export const Tabs = ({
  tabs,
  activeTab,
  setActiveTab,
  variant,
  fullWidth = true,
  stickyHeader,
}: {
  variant: 'subTabs' | 'mainTabs';
  tabs: {
    title: string;
    dataTestId?: string;
    renderContent: () => ReactNode;
    renderFilter?: () => ReactNode;
  }[];
  fullWidth?: boolean;
  stickyHeader?: boolean;
} & (
  | {
      activeTab: number;
      setActiveTab: (t: number) => void;
    }
  | {
      activeTab?: undefined;
      setActiveTab?: undefined;
    }
)) => {
  const prefix = useId();
  const [uncontrolledSelectedTab, setUncontrolledSelectedTab] = useState(0);
  const selectedTab = typeof activeTab === 'number' ? activeTab : uncontrolledSelectedTab;
  const setSelectedTab =
    typeof setActiveTab === 'function' ? setActiveTab : setUncontrolledSelectedTab;
  const tabRefs = useRef<Array<HTMLButtonElement>>([]);

  useEffect(() => {
    tabRefs.current = tabRefs.current.slice(0, tabs.length);
  }, [tabs]);

  return (
    <Wrapper>
      <TabHeader $sticky={stickyHeader}>
        <TabList
          $fullWidth={fullWidth}
          $variant={variant}
          role="tablist"
          onKeyDown={e => {
            if (e.key === 'ArrowLeft') {
              const newSelectedTab = Math.max(selectedTab - 1, 0);
              setSelectedTab(newSelectedTab);
              tabRefs.current[newSelectedTab]?.focus();
            } else if (e.key === 'ArrowRight') {
              const newSelectedTab = Math.min(selectedTab + 1, tabs.length - 1);
              setSelectedTab(newSelectedTab);
              tabRefs.current[newSelectedTab]?.focus();
            }
          }}
        >
          {tabs.map((tab, i) => (
            <button
              key={i}
              type="button"
              role="tab"
              data-testid={tab.dataTestId}
              ref={el => {
                if (el) {
                  tabRefs.current[i] = el;
                }
              }}
              tabIndex={selectedTab === i ? 0 : -1}
              id={`${prefix}-tab-${i}`}
              aria-controls={`${prefix}-tabpanel-${i}`}
              aria-selected={selectedTab === i}
              onClick={() => setSelectedTab(i)}
            >
              {tab.title}
            </button>
          ))}
        </TabList>
        {tabs[selectedTab]?.renderFilter ? (
          <Filter>{tabs[selectedTab].renderFilter?.()}</Filter>
        ) : null}
      </TabHeader>

      <div>
        {tabs.map((tab, i) => (
          <div
            key={i}
            role="tabpanel"
            tabIndex={0}
            aria-labelledby={`${prefix}-tab-${i}`}
            id={`${prefix}-tabpanel-${i}`}
            hidden={selectedTab !== i}
          >
            {selectedTab === i ? tab.renderContent() : null}
          </div>
        ))}
      </div>
    </Wrapper>
  );
};
