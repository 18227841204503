import { ReactNode, useId } from 'react';
import styled from 'styled-components';
import { DESKTOP_PROGRESS_BAR_VERTICAL_GAP, SIZING_0_5 } from '../../theme';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${DESKTOP_PROGRESS_BAR_VERTICAL_GAP};
`;
const ProgressBarWrapper = styled.div`
  position: relative;
  border-radius: ${props => props.theme.radiusProgressBarBackground};
  background-color: ${props => props.theme.colorBackgroundProgressBarBackgroundDefault};
  height: ${SIZING_0_5};
`;
const ProgressIndicator = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  transition: width 0.5 s linear;
  border-radius: ${props => props.theme.radiusProgressBarProgress};
  background-color: ${props => props.theme.colorBackgroundProgressBarProgressDefault};
`;
const ProgressBarLabel = styled.label`
  color: ${props => props.theme.colorTextProgressBarLabelDefault};
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1rem;
  text-align: center;
  cursor: inherit;
`;

export const ProgressBar = ({ percent, label }: { percent: number; label?: ReactNode }) => {
  const clamped = Math.min(percent, 100);
  const labelId = useId();

  return (
    <Wrapper>
      {label && <ProgressBarLabel id={labelId}>{label}</ProgressBarLabel>}
      <ProgressBarWrapper
        aria-labelledby={label ? labelId : undefined}
        role="progressbar"
        aria-valuenow={clamped}
      >
        <ProgressIndicator style={{ width: `${clamped}%` }} />
      </ProgressBarWrapper>
    </Wrapper>
  );
};
