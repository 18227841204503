import { components, defaultTheme } from 'react-select';
import styled, { css } from 'styled-components';
import {
  DANGER_700,
  FOCUS_SHADOW_PR_ASSISTANT,
  GREY_1000,
  TEAL_100,
  TEAL_200,
  TEAL_700,
  TEAL_800,
  TEXT_FONT,
} from '../../../../theme';

type Props = Parameters<typeof components.SelectContainer>[number];

const SelectContainer = (props: Props) => (
  <components.SelectContainer {...props} theme={defaultTheme} />
);

const StyledSelectContainer: any = styled(SelectContainer).attrs(props => ({
  innerProps: {
    ...props.innerProps,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    'data-testid': props.selectProps['data-testid'] ?? 'select-container',
  },
}))`
  && {
    color: ${GREY_1000};
    font-family: ${TEXT_FONT};
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.25;
    display: inline-block;
    text-align: left;
    width: ${({ selectProps }: { selectProps: any }) => selectProps.width ?? '100%'};
    min-width: ${({ selectProps }: { selectProps: any }) => selectProps.minWidth};
    max-width: ${({ selectProps }: { selectProps: any }) => selectProps.maxWidth};

    vertical-align: top;
    pointer-events: auto;
    background: transparent;
    outline: 1px solid;
    outline-offset: -1px;
    border-radius: 0.5rem;
    resize: none;
    min-height: 2.75rem;

    ${({ selectProps }: { selectProps: any }) =>
      selectProps.errorMessage &&
      css`
        border-color: ${DANGER_700};

        box-shadow: 0 0 0 1px ${DANGER_700};
      `};

    ${({ isFocused }) =>
      isFocused &&
      css`
        outline: 2px solid;
        outline-offset: -2px;
        border-color: transparent;
      `}

    ${({ isDisabled }) =>
      isDisabled &&
      css`
        opacity: 0.5;
      `}

    li {
      margin-bottom: auto;
    }

    input {
      color: ${GREY_1000};
      font-family: ${TEXT_FONT};
      font-size: 1rem;
      font-weight: normal;
      line-height: 1.25;
    }
  }
`;

export const PrAssistantSelectContainer: any = styled(StyledSelectContainer)`
  && {
    color: ${TEAL_700};
    background: ${TEAL_100};
    border: none;

    &:hover {
      color: ${TEAL_800} !important;
      background-color: ${TEAL_200};
    }

    &:focus-within {
      outline: none;
      border: 1px solid ${TEAL_700};
      box-shadow: ${FOCUS_SHADOW_PR_ASSISTANT};
    }
  }
`;

export default StyledSelectContainer;
