import { rgba } from 'polished';
import { useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Spinner, media } from '..';
import { GREY_1000, NEUTRAL_WHITE, TEXT_FONT } from '../../theme';
import StackedCards from '../StackedCards';
import { TabNavigation } from '../TabNavigation';
import Actions from './components/Actions';
import Column from './components/Column';
import Header from './components/Header';

const wait = keyframes`
    0% { opacity: 0; }
    100% {opacity: 0; }
`;

const fadeIn = keyframes`
    0% { opacity: 0; }
    100% {opacity: 1; }
`;

type PageProps = {
  /* Indicate if page is waiting for data to be loaded */
  loading?: boolean;
  newNavigation?: boolean;
  showImageBank?: boolean;

  /* Accessibility attribute to indicate if page is waiting for data to be loaded ( depricated: prefer loading attribute ) */
  'aria-busy'?: 'true' | 'false';
  children: React.ReactNode;
};

const SpinnerWrapper = styled.div`
  position: fixed;
  top: calc(50vh - 3rem);
  left: calc(50vw - 3rem);
  z-index: 1001;
  opacity: 0;
  animation: ${fadeIn} 1200ms cubic-bezier(0.25, 0.55, 0.3, 1) 500ms;
  animation-fill-mode: forwards;
`;

/**
 * Footer and header is sized based on content.
 * The purpose of this variable and the min-height
 * is to avoid the footer popping up when the available
 * content is super small.
 */
const APPROXIMATION_OF_FOOTER_AND_HEADER = '170px';

const PageStyles = styled.main.attrs<Omit<PageProps, 'loading'> & { $loading?: boolean }>(
  ({ children, $loading, 'aria-busy': ariaBusy = 'false' }) => ({
    'aria-busy': $loading ? 'true' : ariaBusy,
    children: (
      <>
        {children}
        {$loading && (
          <SpinnerWrapper>
            <Spinner id="page-loader" size="xxl" />
          </SpinnerWrapper>
        )}
      </>
    ),
  }),
)<Omit<PageProps, 'loading' | 'newNavigation'> & { $loading?: boolean; $newNavigation: boolean }>`
  font-family: ${TEXT_FONT};
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.25;

  color: ${GREY_1000};
  margin: 0 auto;
  max-width: var(--page-width);
  ${props =>
    props.$newNavigation
      ? css`
          ${media.greaterThan('navigationBreakpoint')} {
            padding: 3rem 0;
          }
        `
      : css`
          padding: 5rem 0.5rem;
          min-height: calc(100vh - ${APPROXIMATION_OF_FOOTER_AND_HEADER});
        `};

  ${TabNavigation} + * {
    margin-top: 1.5rem;
  }

  ${Header} + * {
    margin-top: 1.5rem;
  }

  ${Actions} + * {
    margin-top: 1.5rem;
  }

  ${Column} + ${Column} {
    margin-top: 1.5rem;
  }

  ${StackedCards} {
    margin-top: 0.75rem;
  }

  ${({ $loading }) =>
    $loading &&
    css`
      ${Header} {
        z-index: 1001;
        position: relative;
      }
      &:after {
        display: block;
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        background: ${rgba(NEUTRAL_WHITE, 0.75)};
        width: 100vw;
        height: 100vh;
        z-index: 1000;
        animation:
          ${wait} 500ms,
          ${fadeIn} 1200ms cubic-bezier(0.25, 0.55, 0.3, 1) 500ms;
      }
    `}
`;

const Page = ({ loading, newNavigation = false, showImageBank, ...props }: PageProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <PageStyles $loading={loading} $newNavigation={newNavigation} {...props} />;
};

export default Page;
