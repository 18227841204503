import { useCallback } from 'react';
import { matchPath } from 'react-router-dom';
import styled from 'styled-components';
import { useLocation, useNavigate } from '../../contexts/UIContext';
import { GREY_1000, GREY_800, RED_700 } from '../../theme';

type TabNavigationItemProps = {
  to: string;
  children?: React.ReactNode;
  exact?: boolean;
  disableRouter?: boolean;
  customPathMatch?: string | null;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
};

const InnerTabNavigationItem = ({
  children,
  to,
  onClick,
  exact,
  customPathMatch,
  disableRouter,
  ...props
}: TabNavigationItemProps) => {
  const path = (customPathMatch || to).replace(/\/$/, '');
  const location = useLocation();
  const currentPath = location.pathname.replace(/\/$/, '');
  const match = exact ? path === currentPath : matchPath(path, currentPath);
  const ariaCurrent = location.hash === to || match ? true : undefined;
  const navigate = useNavigate();
  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = useCallback(
    event => {
      if (disableRouter) return;

      event.preventDefault();
      onClick?.(event);

      if (typeof to === 'string' && to.charAt(0) === '#') {
        navigate({ ...location, hash: to });
      } else {
        navigate(to);
      }
    },
    [disableRouter, onClick, to, navigate, location],
  );
  return (
    <a href={to} onClick={handleClick} {...props} aria-current={ariaCurrent}>
      {children}
    </a>
  );
};

export const TabNavigationItem = styled(InnerTabNavigationItem)`
  color: ${GREY_800};
  display: inline-block;
  padding: 1rem 0.7rem;
  position: relative;
  text-decoration: none;

  &:hover {
    color: ${GREY_1000};
  }

  &[aria-current] {
    color: ${RED_700};
  }

  &[aria-current]:after {
    box-shadow: inset 0px -4px 0px 0px ${RED_700};
    content: '';
    display: block;
    height: 100%;
    left: 0.5rem;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: calc(100% - 1rem);
  }
`;
