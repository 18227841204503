export const SIZING_0_5 = '0.25rem';
export const SIZING_1 = '0.5rem';
export const SIZING_1_5 = '0.75rem';
export const SIZING_2 = '1rem';
export const SIZING_2_5 = '1.25rem';
export const SIZING_3 = '1.5rem';
export const SIZING_4 = '2rem';
export const SIZING_5 = '2.5rem';
export const SIZING_5_5 = '2.75rem';
export const SIZING_6 = '3rem';
export const SIZING_7 = '3.5rem';
export const SIZING_8 = '4rem';
export const SIZING_9 = '4.5rem';
export const SIZING_10 = '5rem';
export const SIZING_11 = '5.5rem';
export const SIZING_12 = '6rem';
export const SIZING_13 = '8rem';
export const SIZING_14 = '10rem';
