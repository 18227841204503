import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { DANGER_700, SUCCESS_600, WARNING_700 } from '../../theme';

type Contact = {
  unsubscribed: boolean;
  optedOut: boolean;
  unreachable: boolean;
  follower: boolean;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  avatarUrl?: string | null;
};

const badgesConfig = (t: TFunction) => ({
  follower: {
    color: SUCCESS_600,
    text: t('contacts_common_follower'),
  },
  unsubscribed: {
    color: DANGER_700,
    text: t('contacts_filter_unsubscribed'),
  },
  unreachable: {
    color: WARNING_700,
    text: t('contacts_filter_unreachable'),
  },
  optedOut: {
    color: DANGER_700,
    text: t('contacts_status_opted-out'),
  },
});

const badges = (t: TFunction, contact?: Contact) => {
  if (contact?.unsubscribed) return badgesConfig(t).unsubscribed;
  if (contact?.optedOut) return badgesConfig(t).optedOut;
  if (contact?.unreachable) return badgesConfig(t).unreachable;
  if (contact?.follower) return badgesConfig(t).follower;

  return;
};

export type BadgeConfigType = ReturnType<typeof badges>;

export const useContactsBadgeConfig = () => {
  const [t] = useTranslation();

  return (contact?: Contact) => badges(t, contact);
};
