import styled, { css } from 'styled-components';
import { media } from '../utils/media';

const getJustifyContent = (value: string) => {
  switch (value) {
    case 'right':
      return 'flex-end';
    case 'left':
      return 'flex-start';
    case 'center':
      return 'center';
    default:
      return 'auto';
  }
};

const Group = styled.div<{ $separateWithSpace?: boolean; $align?: string }>`
  ${({ $separateWithSpace }) =>
    !$separateWithSpace &&
    css`
      display: flex;
      flex-wrap: nowrap;

      > *:not(:first-child) > *,
      > *:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      > *:not(:last-child) > *,
      > *:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      > *:not(:last-child) {
        margin-right: -1px;
      }
    `}

  ${({ $separateWithSpace }) =>
    $separateWithSpace &&
    css`
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      & > * + * {
        margin-top: 0.5rem;
      }
      ${media.lessThan('phablet')} {
        & > * {
          width: 100%;
        }
      }
      ${media.greaterThan('phablet')} {
        flex-direction: row;
        & > * + * {
          margin-top: 0;
          margin-left: 1rem;
        }
      }
    `}

  ${({ $align }) =>
    $align &&
    css`
      justify-content: ${getJustifyContent($align)};
    `}
`;

Group.displayName = 'Group';

export default Group;
