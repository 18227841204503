import styled, { css, keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xxl';

const SIZES = { xxl: '5.5rem', lg: '3rem', md: '2rem', sm: '1.5rem', xs: '1rem' };

const sizeToRem = (size: Size) => SIZES[size];

export const SpinnerStyles = styled.div<{ $size: Size; $horizontalCenter?: boolean }>`
  display: ${props => (props.$horizontalCenter ? 'block' : 'inline-block')};
  ${props =>
    props.$horizontalCenter &&
    css`
      margin-left: auto;
      margin-right: auto;
    `};
  position: relative;
  line-height: 0;
  width: ${props => sizeToRem(props.$size)};
  height: ${props => sizeToRem(props.$size)};
  color: var(--spinner-color, ${props => props.theme.colorBackgroundSpinnerOnLightDefault});
  transform-origin: center center;
  > *:first-child {
    animation: ${rotate} 0.9s linear infinite;
  }
`;

export const Spinner = ({
  size = 'md',
  id,
  horizontalCenter,
  'aria-label': ariaLabel = 'Loading...', // For tests :/
}: {
  size?: Size;
  id?: string;
  horizontalCenter?: boolean;
  'aria-label'?: string;
}) => {
  return (
    <SpinnerStyles $size={size} $horizontalCenter={horizontalCenter} id={id} aria-label={ariaLabel}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.0007 4.00016C9.37323 4.00016 4.00065 9.37275 4.00065 16.0002C4.00065 22.6276 9.37323 28.0002 16.0007 28.0002C16.737 28.0002 17.334 28.5971 17.334 29.3335C17.334 30.0699 16.737 30.6668 16.0007 30.6668C7.90047 30.6668 1.33398 24.1003 1.33398 16.0002C1.33398 7.89999 7.90047 1.3335 16.0007 1.3335C24.1008 1.3335 30.6673 7.89999 30.6673 16.0002C30.6673 16.7365 30.0704 17.3335 29.334 17.3335C28.5976 17.3335 28.0006 16.7365 28.0006 16.0002C28.0006 9.37275 22.6281 4.00016 16.0007 4.00016Z"
          fill="currentColor"
        />
      </svg>
    </SpinnerStyles>
  );
};
