import { useEffect, useState } from 'react';

/**
 *
 * const active = useMediaQuery('@media (min-width: 30em)');
 *
 * @param {string} mediaQueryString valid media query string.
 * @returns {boolean} media-query matches value
 */
export const useMediaQuery = (queryInput: string) => {
  const query = queryInput.replace(/^@media/, '').trim();
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const queryList = window.matchMedia(query);
    setMatches(queryList.matches);
    const handleMatchesChange = (event: MediaQueryListEvent) => {
      setMatches(event.matches);
    };
    queryList.addEventListener('change', handleMatchesChange);

    return () => {
      queryList.removeEventListener('change', handleMatchesChange);
    };
  }, [query]);

  return matches;
};
