import styled from 'styled-components';
import { GREY_1000, WARNING_100, WARNING_400 } from '../../theme';

const Warning = styled.div`
  color: ${GREY_1000};
  border: 1px solid ${WARNING_400};
  background-color: ${WARNING_100};
  padding: 1.5rem;
  border-radius: 0.25rem;
`;

export default Warning;
