import React from 'react';
import Cell from './Cell';
import Row from './Row';
import { ContentType } from './types';

interface RenderRowsProps {
  row: React.ReactNode[];
  index: number;
  contentTypes: ContentType[];
  colspan: number | false;
  withOverflow?: boolean;
  withWrap?: boolean;
}

const RenderRow = ({
  row,
  index: rowIndex,
  contentTypes,
  colspan,
  withOverflow,
  withWrap,
}: RenderRowsProps) => (
  <Row>
    {row.map((col, index) => (
      <Cell
        key={`${rowIndex}_${index}`}
        data-col={index}
        colSpan={colspan || 1}
        $textAlign={colspan ? 'center' : 'left'}
        $withWrap={withWrap}
        $contentType={contentTypes[index]}
        $withOverflow={withOverflow}
      >
        {col}
      </Cell>
    ))}
  </Row>
);

export type TableBodyProps = {
  rows: React.ReactNode[][];
  rowKeys?: React.Key[];
  contentTypes: ContentType[];
  withOverflow?: boolean;
  withWrap?: boolean;
};

const TableBody = React.memo(
  ({ rows, rowKeys = [], contentTypes, withOverflow, withWrap = true }: TableBodyProps) => (
    <tbody>
      {rows.map((row, index) => (
        <RenderRow
          row={row}
          index={index}
          key={rowKeys[index] ?? index}
          contentTypes={contentTypes}
          colspan={rows.length === 1 && rows[0].length === 1 && contentTypes.length}
          withOverflow={withOverflow}
          withWrap={withWrap}
        />
      ))}
    </tbody>
  ),
);

export default TableBody;
