export const RED_100 = '#FFF4F7';
export const RED_200 = '#FFE2E9';
export const RED_300 = '#FFC7D5';
export const RED_400 = '#FFB4C7';
export const RED_500 = '#FF8FAB';
export const RED_600 = '#FF698F';
export const RED_700 = '#FF4473';
export const RED_800 = '#DA365F';
export const RED_900 = '#B4294C';
export const RED_1000 = '#690E25';

export const PURPLE_100 = '#F5F4FF';
export const PURPLE_200 = '#EBEAFB';
export const PURPLE_300 = '#D8D7F5';
export const PURPLE_400 = '#C1C0EB';
export const PURPLE_500 = '#A09DE4';
export const PURPLE_600 = '#7A75E9';
export const PURPLE_700 = '#4E49C7';
export const PURPLE_800 = '#3E3A93';
export const PURPLE_900 = '#313066';
export const PURPLE_1000 = '#242445';

export const WARNING_100 = '#FFF1E5';
export const WARNING_200 = '#FFDABF';
export const WARNING_300 = '#FFC199';
export const WARNING_400 = '#FFAC70';
export const WARNING_500 = '#FF8D4F';
export const WARNING_600 = '#FF7C3F';
export const WARNING_700 = '#FF6B2F';
export const WARNING_800 = '#E65B26';
export const WARNING_900 = '#C64B1D';
export const WARNING_1000 = '#A53B14';

export const DANGER_100 = '#FFEBEC';
export const DANGER_200 = '#FFD5D8';
export const DANGER_300 = '#FFBEC3';
export const DANGER_400 = '#FF8F9A';
export const DANGER_500 = '#FF6071';
export const DANGER_600 = '#FF525D';
export const DANGER_700 = '#FF4B5C';
export const DANGER_800 = '#E43D4D';
export const DANGER_900 = '#C32F3E';
export const DANGER_1000 = '#A2202F';

export const GREY_100 = '#FAFAFB';
export const GREY_200 = '#F7F7F9';
export const GREY_300 = '#EEEEF1';
export const GREY_400 = '#E3E3E9';
export const GREY_500 = '#D1D0DA';
export const GREY_600 = '#BAB9C8';
export const GREY_700 = '#9897AC';
export const GREY_800 = '#6E6D86';
export const GREY_900 = '#4F4E65';
export const GREY_1000 = '#363645';

export const NEUTRAL_WHITE = '#FFFFFF';

export const SUCCESS_100 = '#E9FAEC';
export const SUCCESS_200 = '#CBF5D4';
export const SUCCESS_300 = '#ADF0BB';
export const SUCCESS_400 = '#7FE48E';
export const SUCCESS_500 = '#52D867';
export const SUCCESS_600 = '#40CC58';
export const SUCCESS_700 = '#2FC049';
export const SUCCESS_800 = '#28A73E';
export const SUCCESS_900 = '#20872D';
export const SUCCESS_1000 = '#186618';

export const TEAL_100 = '#F0F9F9';
export const TEAL_200 = '#E7F5F6';
export const TEAL_300 = '#DDEFF0';
export const TEAL_400 = '#C4E5E5';
export const TEAL_500 = '#9FD5D5';
export const TEAL_600 = '#65BEBE';
export const TEAL_700 = '#059191';
export const TEAL_800 = '#03737A';
export const TEAL_900 = '#00565B';
export const TEAL_1000 = '#00383C';

export const SOCIAL_FACEBOOK = '#2C64F6';
export const SOCIAL_TWITTER = '#51ADDF';
export const SOCIAL_X = '#000000';
export const SOCIAL_LINKEDIN = '#0077B5';
export const SOCIAL_GOOGLE = '#CE4A42';
export const SOCIAL_YOUTUBE = '#FF0000';
