// different style from default 👇
import { GroupBase, StylesConfig } from 'react-select';
import Creatable, { CreatableProps } from 'react-select/creatable';
import { GREY_1000, GREY_400 } from '../../../../theme';
import Control from '../ContactSelect/Control';
import _MultiValue from '../MultipleSelect/MultiValue';
import ValueContainer from '../MultipleSelect/ValueContainer';
import { Group, GroupHeading, Menu, Option, Placeholder, SelectContainer } from '../components';

const MultiValue = _MultiValue as any;

export type OptionType = {
  label: string;
  value: string;
};

type IsMulti = true;

export type MultiSelectProps<T = OptionType> = CreatableProps<T, IsMulti, GroupBase<T>> & {
  shadow?: boolean;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  operator?: string;
  'aria-labelledby'?: string;
  'aria-label'?: string;
  dismissAriaLabel?: string;
  variant?: 'primary' | 'secondary' | 'dark' | 'purple' | 'prAssistant';
};

const displayNone = () => ({ display: 'none' });
const valueSpacing: { margin: string; padding: string } = {
  margin: '0',
  padding: 'calc(0.75rem - 0.125rem - 1px) calc(1rem - 0.125rem - 1px)',
};

export const defaultStyles: StylesConfig<any, true> = {
  menuPortal: provided => ({ ...provided, zIndex: 4000 }),
  placeholder: (provided, state) => ({
    ...provided,
    ...valueSpacing,
    fontStyle: 'italic',
    color: state.isDisabled ? GREY_400 : GREY_1000,
  }),
  menuList: provided => ({ ...provided, padding: '0' }),
  valueContainer: provided => ({ ...provided, padding: '0.125rem' }),
  input: provided => ({ ...provided, ...valueSpacing }),
  dropdownIndicator: displayNone,
  indicatorSeparator: displayNone,
  noOptionsMessage: provided => ({
    ...provided,
    color: GREY_1000,
    textAlign: 'left',
    padding: '0.5rem 1rem',
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderRadius: '0.125rem',
  }),
  loadingMessage: provided => ({
    ...provided,
    color: GREY_1000,
    textAlign: 'left',
    padding: '0.5rem 1rem',
    lineHeight: '1.5rem',
    fontSize: '0.875rem',
    borderRadius: '0.125rem',
  }),
};

export const defaultComponents = {
  SelectContainer,
  Control,
  ValueContainer,
  MultiValue,
  Placeholder,
  Menu,
  Option,
  Group,
  GroupHeading,
};

const MultipleSelect = <T extends { value: unknown } = OptionType>({
  components,
  styles,
  menuPortalTarget = document.body,
  isValidNewOption = () => false, // disable creatable by default
  isSearchable = true,
  menuPlacement = 'auto',
  ...props
}: MultiSelectProps<T>) => (
  <Creatable
    menuPlacement={menuPlacement}
    isValidNewOption={isValidNewOption}
    menuPortalTarget={menuPortalTarget}
    isSearchable={isSearchable}
    isMulti
    isClearable={false}
    styles={{
      ...defaultStyles,
      ...styles,
    }}
    components={{
      ...defaultComponents,
      ...components,
    }}
    {...props}
  />
);

export default MultipleSelect;
