import React from 'react';
import styled from 'styled-components';
import at from './images/at@2x.png';
import au from './images/au@2x.png';
import be from './images/be@2x.png';
import bg from './images/bg@2x.png';
import br from './images/br@2x.png';
import ca from './images/ca@2x.png';
import ch from './images/ch@2x.png';
import cn from './images/cn@2x.png';
import com from './images/com@2x.png';
import cz from './images/cz@2x.png';
import de from './images/de@2x.png';
import dk from './images/dk@2x.png';
import es from './images/es@2x.png';
import eu from './images/eu@2x.png';
import fallback from './images/fallback@2x.png';
import fi from './images/fi@2x.png';
import frBe from './images/fr-be@2x.png';
import fr from './images/fr@2x.png';
import gr from './images/gr@2x.png';
import hr from './images/hr@2x.png';
import hu from './images/hu@2x.png';
import id from './images/id@2x.png';
import ie from './images/ie@2x.png';
// ”in” is a resvered keyword so we use ”ind” instead for India 👇
import ind from './images/in@2x.png';
import it from './images/it@2x.png';
import jo from './images/jo@2x.png';
import jp from './images/jp@2x.png';
import kr from './images/kr@2x.png';
import mt from './images/mt@2x.png';
import my from './images/my@2x.png';
import nlBe from './images/nl-be@2x.png';
import nl from './images/nl@2x.png';
import no from './images/no@2x.png';
import ph from './images/ph@2x.png';
import pl from './images/pl@2x.png';
import pt from './images/pt@2x.png';
import ro from './images/ro@2x.png';
import rs from './images/rs@2x.png';
import ru from './images/ru@2x.png';
import se from './images/se@2x.png';
import sg from './images/sg@2x.png';
import si from './images/si@2x.png';
import sk from './images/sk@2x.png';
import tr from './images/tr@2x.png';
import ua from './images/ua@2x.png';
import uk from './images/uk@2x.png';
import us from './images/us@2x.png';
import za from './images/za@2x.png';

export const NAME_MAP = {
  at: 'Austria',
  be: 'Belgium',
  bg: 'Bulgaria',
  ch: 'Switzerland',
  com: 'Global',
  cz: 'Czech Republic',
  de: 'Germany',
  dk: 'Denmark',
  es: 'Spain',
  eu: 'European Union',
  fi: 'Finland',
  'fr-be': 'French (Belgium)',
  fr_be: 'French (Belgium)',
  fr: 'France',
  gr: 'Greece',
  hu: 'Hungary',
  it: 'Italy',
  jp: 'Japan',
  'nl-be': 'Netherlands (Belgium)',
  nl_be: 'Netherlands (Belgium)',
  nl: 'Netherlands',
  no: 'Norway',
  pl: 'Poland',
  pt: 'Portugal',
  ro: 'Romania',
  ru: 'Russia',
  rs: 'Serbia',
  se: 'Sweden',
  sg: 'Singapore',
  tr: 'Turkey',
  uk: 'United Kingdom',
  us: 'United States',
  in: 'India',
  kr: 'Korea',
  mt: 'Malta',
  br: 'Brazil',
  ca: 'Canada',
  za: 'South Africa',
  si: 'Slovenia',
  ua: 'Ukraine',
  au: 'Australia',
  hr: 'Croatia',
  cn: 'China',
  sk: 'Slovakia',
  my: 'Malaysia',
  id: 'Indonesia',
  ie: 'Ireland',
  jo: 'Jordan',
  ph: 'Philippines',
} as const;

const IMAGE_MAP = {
  at,
  be,
  bg,
  ch,
  com,
  cz,
  de,
  dk,
  es,
  eu,
  fi,
  fr,
  'fr-be': frBe,
  frBe,
  fr_be: frBe,
  gr,
  hu,
  it,
  jp,
  nl,
  'nl-be': nlBe,
  nlBe,
  nl_be: nlBe,
  no,
  pl,
  pt,
  ro,
  ru,
  rs,
  se,
  sg,
  tr,
  uk,
  us,
  in: ind,
  kr,
  mt,
  br,
  ca,
  za,
  si,
  ua,
  au,
  hr,
  cn,
  sk,
  my,
  id,
  ie,
  jo,
  ph,
};

type FlagCode = keyof typeof NAME_MAP;

export const isFlagCode = (code: string): code is FlagCode => code in NAME_MAP;

type FlagProps = {
  code?: FlagCode;
  size?: string;
  className?: string;
  style?: React.CSSProperties;
};
const size = ({ size = '1rem' }) => size;

const Flag = styled(({ code, className, style }: FlagProps): React.ReactElement => {
  const src = code ? IMAGE_MAP[code] || fallback : fallback;
  const alt = code ? NAME_MAP[code] || code : code;
  return (
    <i className={className} style={style}>
      <img src={src as any} alt={alt} />
    </i>
  );
})`
  display: inline-block;
  vertical-align: middle;
  pointer-events: none;
  border-color: inherit;
  line-height: ${size};
  width: ${size};
  height: ${size};
  & > img {
    position: relative;
    border-radius: 50%;
    width: 100%;
  }
`;

export default Flag;
