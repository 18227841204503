import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { GREY_1000, GREY_300, GREY_400, NEUTRAL_WHITE, TEXT_FONT } from '../../theme';
import Icons from '../Icons';
import { P } from '../Typo';
import PaginationInput from './components/StyledInput';

type PaginationProps = {
  currentPage: number;
  totalPages: number;

  /* Will execute on page change with page as only param. */
  onChangePage: (page: number) => void;
};

const PaginationButton = styled.button`
  border: 0;
  padding: 0;
  background: none;
  border: 1px solid ${GREY_400};
  height: ${props => props.theme.sizingButtonHeight};
  width: ${props => props.theme.sizingButtonWidthIconOnly};
  color: ${props => props.theme.colorTextButtonTextTextButtonOnLightDefault};
  border-radius: ${props => props.theme.radiusButton};
  &:disabled {
    color: ${props => props.theme.colorTextButtonTextTextButtonOnLightDisabled};
  }
  &:hover:not(:disabled) {
    background-color: ${GREY_300};
  }
`;

const Pagination = styled(
  ({
    className,
    onChangePage,
    currentPage,
    totalPages,
  }: PaginationProps & { className?: string }) => {
    const disabled: boolean = totalPages === 1;
    const inputField: React.RefObject<HTMLInputElement> = React.useRef<HTMLInputElement>(
      document.createElement('input'),
    );
    const [t] = useTranslation();
    const [inputValue, setInputValue] = React.useState<string>('');
    const [inputVisible, setInputVisible] = React.useState<boolean>(false);

    const hideInput = React.useCallback(() => {
      setInputVisible(false);
    }, [setInputVisible]);

    const handleInputChange = React.useCallback(
      (event: any) => {
        setInputValue(parseInt(event.target.value, 10).toString());
      },
      [setInputValue],
    );

    const handleChange = React.useCallback(
      (value: any) => {
        const page = Math.max(1, Math.min(value, totalPages));
        if (!Number.isNaN(page) && page !== currentPage) {
          onChangePage(page);
        }
      },
      [currentPage, onChangePage, totalPages],
    );

    const handleBlur = React.useCallback(() => {
      hideInput();
    }, [hideInput]);

    const handleNext = React.useCallback(() => {
      handleChange(currentPage + 1);
    }, [handleChange, currentPage]);

    const handlePrevious = React.useCallback(() => {
      handleChange(currentPage - 1);
    }, [handleChange, currentPage]);

    const handleShowInput = React.useCallback(() => {
      !disabled && setInputVisible(true);
    }, [setInputVisible, disabled]);

    const handleKeyDown = React.useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        switch (event.key) {
          case 'Enter':
            hideInput();
            handleChange(inputValue);
            break;
          case 'Backspace':
            break;
          case 'Escape':
            hideInput();
            break;
          default:
            if (/^[^0-9]$/i.test(event.key)) {
              hideInput();
            }
        }
      },
      [hideInput, handleChange, inputValue],
    );

    // Keep input input value in sync with currentPage
    React.useEffect(() => {
      setInputValue(String(currentPage));
    }, [currentPage, setInputValue]);

    // Handle focus
    React.useEffect(() => {
      inputVisible && inputField?.current && inputField.current.focus();
    }, [inputField, inputVisible]);

    // Clear ui input field
    React.useEffect(() => {
      if (inputVisible && inputField?.current) {
        inputField.current.value = '';
      }
    }, [inputVisible, inputField]);

    return (
      <nav
        role="navigation"
        aria-label={t('components_pagination_aria-label')}
        className={className}
      >
        <PaginationButton
          disabled={disabled || currentPage === 1}
          aria-label={t('components_pagination_previous-page')}
          onClick={handlePrevious}
          onMouseDown={hideInput}
        >
          <Icons.ChevronLeft />
        </PaginationButton>
        <div onClick={handleShowInput}>
          <P hidden={inputVisible}>
            {t('components_pagination_label', { currentPage, totalPages })}
          </P>
          <PaginationInput
            ref={inputField}
            hidden={!inputVisible}
            type="number"
            pattern={'\\d*'}
            value={inputValue}
            placeholder={String(currentPage)}
            onBlur={handleBlur}
            onChange={handleInputChange}
            aria-label={t('components_pagination_quick-navigation_label')}
            onKeyDown={handleKeyDown}
          />
        </div>
        <PaginationButton
          disabled={disabled || currentPage === totalPages}
          aria-label={t('components_pagination_next-page')}
          onClick={handleNext}
          onMouseDown={hideInput}
        >
          <Icons.ChevronRight />
        </PaginationButton>
      </nav>
    );
  },
)<PaginationProps>`
  p,
  input {
    color: ${GREY_1000};
    font-family: ${TEXT_FONT};
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.25;
    text-transform: uppercase;
    margin: 0;
  }

  display: inline-flex;
  position: relative;
  height: 2.75rem;
  align-items: center;
  text-align: center;
  border-radius: ${props => props.theme.radiusButton};
  border: 0;
  background: ${NEUTRAL_WHITE};

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 7.438rem;
    border-top: 1px solid ${GREY_400};
    border-bottom: 1px solid ${GREY_400};
  }

  & > *:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  & > *:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export default Pagination;
