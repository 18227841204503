import { UntypedFunction } from '@mnd-frontend/ts';
import React from 'react';
import Screen from '../Screen';

type FaceHugggerProps = {
  show?: boolean;
  background?: string;
  lockedHeight?: boolean;
  onDismiss?: UntypedFunction;
  children?: React.ReactNode;
  lockFocus?: boolean;
};

const Facehugger: React.FC<FaceHugggerProps> = ({
  children,
  show,
  onDismiss,
  background,
  lockedHeight = false,
  lockFocus,
}) => (
  <Screen
    lockFocus={lockFocus}
    show={show}
    escapeToDismiss
    onDismiss={onDismiss}
    lockedHeight={lockedHeight}
    background={background}
  >
    {children}
  </Screen>
);

export default Facehugger;
