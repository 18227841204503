export const SPACING_0_5 = '0.25rem';
export const SPACING_1 = '0.5rem';
export const SPACING_1_5 = '0.75rem';
export const SPACING_2 = '1rem';
export const SPACING_3 = '1.5rem';
export const SPACING_4 = '2rem';
export const SPACING_5 = '2.5rem';
export const SPACING_6 = '3rem';
export const SPACING_7 = '3.5rem';
export const SPACING_8 = '4rem';
export const SPACING_9 = '4.5rem';
export const SPACING_10 = '5rem';
export const SPACING_11 = '5.5rem';
export const SPACING_12 = '6rem';
