import styled from 'styled-components';
import { GREY_100, GREY_400, NEUTRAL_WHITE } from '../../../../theme';

interface Props {
  $isFocused: boolean;
}

const OptionContainer = styled.div<Props>`
  cursor: pointer;
  > * {
    margin-left: 1rem;
    margin-top: 0;
    margin-bottom: 0;
    pointer-events: none;
  }
  > *:last-child {
    margin-right: 1rem;
  }
  background: ${({ $isFocused }) => ($isFocused ? GREY_100 : NEUTRAL_WHITE)};

  &:hover {
    background: ${GREY_100};
  }

  display: flex;
  flex-direction: row;
  padding: 1rem 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${GREY_400};
  }
`;

OptionContainer.displayName = 'ContactOptionContainer';
export default OptionContainer;
