import {
  ChangeEventHandler,
  FocusEventHandler,
  ForwardedRef,
  forwardRef,
  KeyboardEventHandler,
  ReactNode,
  useCallback,
  useId,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import { GREY_400, GREY_600, RADIUS_SM, RED_700 } from '../../theme';

const labelPaddingCalc = (
  withLeadingIcon: boolean,
  withTrailingIcon: boolean,
  ignoreTrailing = false,
) => {
  const leadingExtra = withLeadingIcon ? 2 : 0;
  const trailingExtra = withTrailingIcon && !ignoreTrailing ? 2 : 0;
  return `${1.75 + leadingExtra + trailingExtra}rem`;
};

type LabelProps = {
  $hasContent: boolean;
  $hasError: boolean;
  $withLeadingIcon: boolean;
  $withTrailingIcon: boolean;
  $defaultLabelTopPos: string;
  disabled?: boolean;
};

const moveTop = css<{
  $withLeadingIcon: boolean;
  $withTrailingIcon: boolean;
}>`
  top: 0;
  left: 0.625rem;
  z-index: 1;
  font-size: 0.75rem;
  line-height: 1.125rem;
  max-width: calc(
    100% - ${props => labelPaddingCalc(props.$withLeadingIcon, props.$withTrailingIcon, true)}
  );
`;

const LeadingIconWrapper = styled.span`
  color: ${props => props.theme.colorIconsDefault};
  position: absolute;
  left: 1rem;
  top: 50%;
  line-height: 0;
  transform: translateY(-50%);
  pointer-events: none;
`;

const TrailingIconWrapper = styled.span`
  color: ${props => props.theme.colorIconsDefault};
  position: absolute;
  right: 1rem;
  top: 50%;
  line-height: 0;
  transform: translateY(-50%);
  pointer-events: none;
`;
const InputLabel = styled.label<LabelProps>`
  user-select: none;
  font-size: 1rem;
  line-height: 1.25rem;
  border-radius: ${RADIUS_SM};
  color: ${props => {
    if (props.$hasContent) {
      if (props.$hasError) return props.theme.colorTextTextFieldLabelPopulatedEnabledError;
      if (props.disabled) return GREY_600;
      return props.theme.colorTextTextFieldLabelPopulatedEnabledDefault;
    }
    if (props.$hasError) return props.theme.colorTextTextFieldLabelEmptyEnabledError;
    if (props.disabled) return GREY_600;
    return props.theme.colorTextTextFieldLabelEmptyEnabledDefault;
  }};
  background: var(--current-background, white);
  padding: 0 0.25rem;
  transition: all 0.2s;
  position: absolute;
  font-weight: normal;
  left: ${props => (props.$withLeadingIcon ? '2.625rem' : '0.625rem')};
  max-width: calc(
    100% - ${props => labelPaddingCalc(props.$withLeadingIcon, props.$withTrailingIcon)}
  );
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  top: ${props => props.$defaultLabelTopPos};
  z-index: 0;
  transform: translateY(-50%);
  pointer-events: none;

  ${props => props.$hasContent && moveTop}
`;

const StyledInput = styled.input<{
  $withLeadingIcon: boolean;
  $withTrailingIcon: boolean;
}>`
  position: relative;
  z-index: 1;
  background: transparent;
  border-radius: ${props => props.theme.radiusInput};
  border: 0;
  width: 100%;
  font-weight: normal;
  font-size: 1rem;
  outline: 0;
  padding: 0.5rem 0.875rem;
  padding-left: ${props => props.$withLeadingIcon && props.theme.sizingIconMd};
  padding-right: ${props => props.$withTrailingIcon && props.theme.sizingIconMd};
  &[disabled] {
    color: ${GREY_600};
  }
`;

const InputWrapperStyles = styled.div<{
  $hasError: boolean;
  disabled?: boolean;
  $withLeadingIcon: boolean;
  $withTrailingIcon: boolean;
  $hasContent: boolean;
  $hackForDarkBackgroundUseAndBeFired?: boolean;
}>`
  position: relative;
  z-index: 2;
  width: 100%;
  line-height: 1.5rem;

  * {
    line-height: 1.5rem;
  }
  outline: 1px solid
    ${props => {
      if (props.$hasContent) {
        if (props.$hasError) return props.theme.colorBorderTextFieldPopulatedEnabledError;
        if (props.disabled) return GREY_400;
        return props.theme.colorBorderTextFieldPopulatedEnabledDefault;
      }
      if (props.$hasError) return props.theme.colorBorderTextFieldEmptyEnabledError;
      if (props.disabled) return GREY_400;
      return props.theme.colorBorderTextFieldEmptyEnabledDefault;
    }};
  color: ${props => props.theme.colorTextTextFieldInputPopulatedEnabled};
  outline-offset: -1px;
  border-radius: ${props => props.theme.radiusInput};
  border: 2px inset transparent;
  &:focus-within ${InputLabel} {
    ${moveTop}
  }

  ${LeadingIconWrapper},
  ${TrailingIconWrapper} {
    color: ${props => props.disabled && GREY_600};
  }
  &:active,
  &:hover {
    color: ${props => props.theme.colorTextTextFieldInputPopulatedHover};
    outline: 1px solid
      ${props => {
        if (props.$hasContent) {
          if (props.$hasError) return props.theme.colorBorderTextFieldPopulatedHoverError;
          if (props.disabled) return GREY_400;
          return props.theme.colorBorderTextFieldPopulatedHoverDefault;
        }
        if (props.$hasError) return props.theme.colorBorderTextFieldEmptyHoverError;
        if (props.disabled) return GREY_400;
        return props.theme.colorBorderTextFieldEmptyHoverDefault;
      }};
  }
  &:focus-within {
    color: ${props => props.theme.colorTextTextFieldInputPopulatedFocused};
    outline: 2px solid
      ${props => {
        if (props.$hasContent) {
          if (props.$hasError) return props.theme.colorBorderTextFieldPopulatedFocusedError;
          if (props.disabled) return GREY_400;
          return props.theme.colorBorderTextFieldPopulatedFocusedDefault;
        }
        if (props.$hasError) return props.theme.colorBorderTextFieldEmptyFocusedError;
        if (props.disabled) return GREY_400;
        return props.theme.colorBorderTextFieldEmptyFocusedDefault;
      }};
    outline-offset: -2px;
    + ${InputLabel} {
      color: ${props => {
        if (props.$hasContent) {
          if (props.$hasError) return props.theme.colorTextTextFieldLabelPopulatedFocusedError;
          if (props.disabled) return GREY_400;
          return props.theme.colorTextTextFieldLabelPopulatedFocusedDefault;
        }
        if (props.$hasError) return props.theme.colorTextTextFieldLabelEmptyFocusedError;
        if (props.disabled) return GREY_400;
        return props.theme.colorTextTextFieldLabelEmptyFocusedDefault;
      }};
    }
  }

  ${props =>
    props.$hackForDarkBackgroundUseAndBeFired &&
    css`
      && {
        border: 0;
        outline: 0;
      }
    `}
`;

const InputError = styled.div`
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: ${RED_700};
`;

const HelperText = styled.p`
  margin: 0;
  color: ${props => props.theme.colorTextTextFieldHelperTextDefault};
  font-size: 0.75rem;
  line-height: 1.125rem;
`;

const ComponentWrapper = styled.div<{ $skipMargin?: boolean }>`
  // Position absolute label might render above elements higher up on the page
  margin-top: ${props => !props.$skipMargin && '0.5rem'};
`;

const BottomRow = styled.div<{ $alignRight?: boolean }>`
  margin-top: 0.5rem;
  display: flex;
  gap: 0.5rem;
  justify-content: ${props => (props.$alignRight ? 'flex-end' : 'space-between')};
  align-items: center;
`;

export const InputWrapper = ({
  id,
  label,
  labelId,
  hasContent,
  error,
  helperText,
  leadingIcon,
  trailingIcon,
  children,
  skipMargin,
  disabled,
  characterCounterSlot,
  hackForDarkBackgroundUseAndBeFired,
  defaultLabelTopPos = '50%',
}: {
  label?: string;
  labelId?: string;
  hasContent: boolean;
  id?: string;
  error?: string | null;
  helperText?: string;
  leadingIcon?: ReactNode;
  trailingIcon?: ReactNode;
  children: ReactNode;
  skipMargin?: boolean;
  disabled?: boolean;
  characterCounterSlot?: ReactNode;
  hackForDarkBackgroundUseAndBeFired?: true;
  defaultLabelTopPos?: string;
}) => {
  return (
    <ComponentWrapper $skipMargin={skipMargin}>
      <InputWrapperStyles
        $hasError={!!error}
        disabled={disabled}
        $withLeadingIcon={!!leadingIcon}
        $withTrailingIcon={!!trailingIcon}
        $hasContent={hasContent}
        $hackForDarkBackgroundUseAndBeFired={hackForDarkBackgroundUseAndBeFired}
      >
        {leadingIcon && <LeadingIconWrapper>{leadingIcon}</LeadingIconWrapper>}
        {children}
        {label && (
          <InputLabel
            $defaultLabelTopPos={defaultLabelTopPos}
            $withLeadingIcon={!!leadingIcon}
            $withTrailingIcon={!!trailingIcon}
            $hasError={!!error}
            $hasContent={hasContent}
            disabled={disabled}
            htmlFor={id}
            id={labelId}
          >
            {label}
          </InputLabel>
        )}
        {trailingIcon && <TrailingIconWrapper>{trailingIcon}</TrailingIconWrapper>}
      </InputWrapperStyles>
      {error || helperText || characterCounterSlot ? (
        <BottomRow $alignRight={!error && !helperText}>
          {error ? (
            <InputError>{error}</InputError>
          ) : helperText ? (
            <HelperText>{helperText}</HelperText>
          ) : null}
          {characterCounterSlot}
        </BottomRow>
      ) : null}
    </ComponentWrapper>
  );
};

export interface InputProps {
  label?: string;
  type?: 'text' | 'password' | 'email' | 'tel' | 'number';
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  id?: string;
  error?: string | null;
  helperText?: string;
  leadingIcon?: ReactNode;
  trailingIcon?: ReactNode;
  required?: boolean;
  autoComplete?: string;
  disabled?: boolean;
  /** Using this means you must guarantee that the label will not overflow content above it  */
  skipMargin?: boolean;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  maxLength?: number;
  minLength?: number;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  autoFocus?: boolean;
  readOnly?: boolean;
  min?: number;
  max?: number;
}

const InputWithRef = (
  {
    id,
    type = 'text',
    label,
    value: propValue,
    error,
    helperText,
    leadingIcon,
    trailingIcon,
    disabled,
    skipMargin,
    onChange: propOnChange,
    ...props
  }: InputProps,
  forwardedRef: ForwardedRef<HTMLInputElement>,
) => {
  const generatedId = useId();
  id ||= generatedId;

  /** To be able to style the label based on value with an uncontrolled component, we still need a state */
  const [localValue, setLocalValue] = useState('');

  const value = propValue ?? localValue;
  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      propOnChange?.(e);
      setLocalValue(e.target.value);
    },
    [propOnChange],
  );

  return (
    <InputWrapper
      id={id}
      label={label}
      hasContent={!!value}
      error={error}
      helperText={helperText}
      leadingIcon={leadingIcon}
      trailingIcon={trailingIcon}
      skipMargin={skipMargin}
      disabled={disabled}
    >
      <StyledInput
        {...props}
        onChange={onChange}
        $withLeadingIcon={!!leadingIcon}
        $withTrailingIcon={!!trailingIcon}
        type={type}
        value={value}
        id={id}
        disabled={disabled}
        ref={forwardedRef}
      />
    </InputWrapper>
  );
};

export const Input = forwardRef(InputWithRef);
