import { useClickOutside } from '@mnd-frontend/hooks';
import { UntypedFunction } from '@mnd-frontend/ts';
import React, {
  FunctionComponent,
  MouseEvent,
  useCallback,
  useEffect,
  useId,
  useLayoutEffect,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import {
  FOCUS_SHADOW,
  GREY_100,
  GREY_500,
  GREY_600,
  NEUTRAL_WHITE,
  PURPLE_700,
  RED_900,
  SHADOW_1,
} from '../../theme';
import { Button } from '../Button';
import Icons from '../Icons';
import type { ToolTipOptions } from '../Tooltip';
import ToolTip from '../Tooltip';
import isOutOfViewport from '../utils/isOutOfViewport';

type Layout = 'right' | 'left' | 'stretch';
type Direction = 'up' | 'down';

const Menu = styled.ul<{
  $show: boolean;
  $layout?: Layout;
  $direction: Direction;
  $disabled?: boolean;
}>`
  display: ${({ $show }) => ($show ? 'block' : 'none')};
  box-shadow: ${SHADOW_1};
  background-color: ${NEUTRAL_WHITE};
  border: 1px solid ${GREY_600};
  border-radius: 0.25rem;
  width: 16rem;
  position: absolute;
  transform: ${({ $direction }) =>
    $direction === 'up' ? 'translate(0, calc(-100% - 3.75rem))' : undefined};
  padding: 0;
  margin: 0.5rem 0;
  z-index: 4;
  &:focus {
    outline: none;
    border-color: ${PURPLE_700};
    box-shadow: ${FOCUS_SHADOW};
  }

  ${({ $layout }) =>
    $layout === 'right'
      ? css`
          left: 0;
        `
      : $layout === 'stretch'
        ? css`
            left: 0;
            right: 0;
            width: 100%;
          `
        : css`
            right: 0;
          `}
`;

const MenuItem = styled.li<{ $variant?: string; $disabled?: boolean }>`
  list-style-type: none;
  border-radius: 0.125rem;
  text-align: left;
  cursor: pointer;
  margin: 0;
  padding: 0.5rem 1rem;
  line-height: 1.5rem;
  font-size: 0.875rem;
  width: 100%;
  border: none;
  &:hover {
    background-color: ${GREY_100};
  }
  &:focus {
    background-color: ${GREY_100};
    outline: none;
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      background-color: unset;
      opacity: 0.6;
      color: ${GREY_500};
      cursor: not-allowed;
      &:hover {
        background-color: unset;
      }
    `}

  ${({ $variant, $disabled }) =>
    $variant === 'primary' &&
    !$disabled &&
    css`
      color: ${RED_900};
    `}
`;

const MenuDivider = styled.li.attrs({
  role: 'presentation',
})`
  list-style-type: none;
  margin: 0;
  height: 1px;
  background: ${GREY_600};
`;

const MenuButtonWrapper = styled.div<{ $layout: Layout }>`
  ${({ $layout }) => $layout !== 'stretch' && 'position: relative;'}
  display: inline-block;
`;

type DividerOption = { divider: boolean };
type Option = {
  text: string;
  action: UntypedFunction;
  testid?: string;
  variant?: string;
  disabled?: boolean;
  tooltip?: ToolTipOptions;
};
type DropdownOption = DividerOption | Option;

export const isDivider = (value: DropdownOption): value is DividerOption => 'divider' in value;

type DropdownProps = {
  title: string;
  options?: DropdownOption[];
  hideTitle?: boolean;
  direction?: Direction;
  children?: React.ReactNode;
  disabled?: boolean;
  textDropdown?: boolean;
};

const Dropdown: FunctionComponent<DropdownProps> = ({
  options = [],
  title,
  hideTitle = false,
  children = undefined,
  direction = 'down',
  disabled = false,
  textDropdown = false,
  ...props
}) => {
  const buttonId = 'menubutton' + useId();
  const menuId = 'menubutton' + useId();
  const [open, setOpen] = useState(false);
  const [focusableIndex, setFocusableIndex] = useState<null | number>(null);
  const [layout, setLayout] = useState<Layout>('left');
  const controller = React.useRef<HTMLButtonElement & HTMLAnchorElement>(null);
  const menu = React.useRef<HTMLUListElement>(null);

  const focusableOptions = React.useMemo(
    () => options.filter(item => !isDivider(item) || (isDivider(item) && !item.divider)),
    [options],
  );
  const focusableCount = focusableOptions.length - 1;

  useLayoutEffect(() => {
    if (open && menu.current) {
      const is = isOutOfViewport(menu.current);
      if (!is.any) {
        setLayout('left');
      } else if (is.horizontalOutOfBounds) {
        setLayout('stretch');
      } else if (is.right) {
        setLayout('left');
      } else if (is.left) {
        setLayout('right');
      }
    } else {
      setLayout('left');
    }
  }, [open, menu, setLayout]);

  const handleSelectOption = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      const selectedIndex = e.currentTarget.getAttribute('data-index');
      if (selectedIndex !== null) {
        const option = focusableOptions[Number(selectedIndex)];
        if (!isDivider(option) && option.action) {
          option.action();
        }
      }
      setOpen(false);
    },
    [focusableOptions, setOpen],
  );

  const handleKeyDown: React.KeyboardEventHandler<HTMLUListElement> = useCallback(
    event => {
      if (event.altKey || event.ctrlKey || event.metaKey) {
        return;
      }
      switch (event.key) {
        case 'Tab':
          setOpen(false);
          break;
        case 'Escape':
          event.preventDefault();
          setOpen(false);
          controller?.current?.focus();
          break;
        case ' ':
        case 'Enter':
        case 'Space':
          event.preventDefault();
          setOpen(false);
          controller?.current?.focus();
          if (focusableIndex !== null) {
            const option = focusableOptions[focusableIndex];
            if (!isDivider(option) && option.action) {
              option.action();
            }
          }
          break;
        case 'Home':
          event.preventDefault();
          setFocusableIndex(0);
          break;
        case 'End':
          event.preventDefault();
          setFocusableIndex(focusableCount);
          break;
        case 'ArrowUp':
          event.preventDefault();
          if (focusableIndex === null) {
            setFocusableIndex(focusableCount);
          } else if (focusableIndex > 0) {
            setFocusableIndex(focusableIndex - 1);
          } else {
            setFocusableIndex(focusableCount);
          }
          break;
        case 'ArrowDown':
          event.preventDefault();
          if (focusableIndex === null) {
            setFocusableIndex(0);
          } else if (focusableIndex < focusableCount) {
            setFocusableIndex(focusableIndex + 1);
          } else {
            setFocusableIndex(0);
          }
          break;
        default:
      }
    },
    [focusableOptions, focusableIndex, focusableCount],
  );

  useEffect(() => {
    if (menu.current) {
      if (open && focusableIndex !== null) {
        const elm = menu.current.querySelector<HTMLElement>(`[data-index="${focusableIndex}"`);
        if (elm) {
          elm.focus();
        }
      } else if (open) {
        menu.current.focus();
      }
    }
  }, [menu, focusableIndex, open]);

  useClickOutside(menu, () => setOpen(false), open);

  return (
    <MenuButtonWrapper $layout={layout}>
      <Button
        id={buttonId}
        configuration={textDropdown ? 'text' : 'toned'}
        type="button"
        disabled={disabled}
        aria-controls={menuId}
        aria-expanded={open.toString()}
        aria-haspopup="menu"
        ref={controller}
        data-testid="toggle-dropdown"
        trailingIcon={textDropdown && <Icons.ChevronDown />}
        onClick={useCallback(() => {
          setFocusableIndex(null);
          setOpen(!open);
        }, [open, setOpen, setFocusableIndex])}
        {...props}
        {...(hideTitle
          ? { 'aria-label': title, icon: <Icons.MoreHorizontal /> }
          : { children: title })}
      />
      <Menu
        id={menuId}
        aria-labelledby={buttonId}
        $layout={layout}
        onKeyDown={handleKeyDown}
        ref={menu}
        role="menu"
        $show={open}
        tabIndex={-1}
        $direction={direction}
      >
        {children ||
          options.map((option, optionIndex) =>
            isDivider(option) && option.divider ? (
              <MenuDivider key={optionIndex} />
            ) : !isDivider(option) ? (
              <MenuItem
                key={optionIndex}
                role="menuitem"
                onClick={e => {
                  if (option.disabled) {
                    e.stopPropagation();
                    return;
                  }
                  handleSelectOption(e);
                }}
                data-testid={option.testid || 'dropdown-option'}
                data-index={focusableOptions.indexOf(option)}
                $disabled={option.disabled}
                $variant={option.variant}
                tabIndex={-1}
              >
                {option.tooltip ? (
                  <ToolTip {...option.tooltip}>{option.text}</ToolTip>
                ) : (
                  option.text
                )}
              </MenuItem>
            ) : undefined,
          )}
      </Menu>
    </MenuButtonWrapper>
  );
};

export default Dropdown;
