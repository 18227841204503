import styled, { css } from 'styled-components';
import withMediaQueries, { selectMedia } from '../../utils/withMediaQueries';
import { MediaProp } from './Flex';

type Props = {
  $order?: MediaProp<string | number>;
  $flexGrow?: MediaProp<string | number>;
  $flexShrink?: MediaProp<string | number>;
  $flexBasis?: MediaProp<string>;
  $minWidth?: MediaProp<string>;
  $alignSelf?: MediaProp<'normal' | 'auto' | 'flex-start' | 'flex-end' | 'center'>;
};
const Item = styled.div<Props>`
  ${withMediaQueries(
    sizeKey => css`
      order: ${selectMedia('$order', sizeKey)};
      flex-grow: ${selectMedia('$flexGrow', sizeKey)};
      flex-shrink: ${selectMedia('$flexShrink', sizeKey)};
      flex-basis: ${selectMedia('$flexBasis', sizeKey)};
      min-width: ${selectMedia('$minWidth', sizeKey)};
      align-self: ${selectMedia('$alignSelf', sizeKey)};
      justify-self: ${selectMedia('$justifySelf', sizeKey)};
    `,
  )}
`;

export default Item;
