import React from 'react';
import styled from 'styled-components';
import { Button } from '../Button';
import Symbols, { SymbolsType } from '../Symbols';
import { Text } from '../Text';
import { P } from '../Typo';

const EmptyStateWrapper = styled.div`
  padding: 3rem;
  text-align: center;

  > i {
    margin-bottom: 1.125rem;
  }
  ${P} {
    margin-top: 0.5rem;
  }
`;
const ButtonWrapper = styled.div`
  margin-top: 1.5rem;
`;

const EmptyState = ({
  headline,
  symbol: Symbol = Symbols.NoResults,
  description,
  action,
  onClick,
  testId,
  href,
  tonedButton,
  ...props
}: {
  headline?: string;
  symbol?: SymbolsType;
  description?: string | JSX.Element;
  action?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => void;
  testId?: string;
  tonedButton?: boolean;
  href?: string;
}) => (
  <EmptyStateWrapper data-testid={testId} {...props}>
    <Symbol size="large" />
    <Text as="h3" variant="headlineSmall">
      {headline}
    </Text>
    {description && <P>{description}</P>}
    {action &&
      (href ? (
        <ButtonWrapper>
          <Button configuration={tonedButton ? 'toned' : 'filled'} href={href} onClick={onClick}>
            {action}
          </Button>
        </ButtonWrapper>
      ) : onClick ? (
        <ButtonWrapper>
          <Button configuration={tonedButton ? 'toned' : 'filled'} type="button" onClick={onClick}>
            {action}
          </Button>
        </ButtonWrapper>
      ) : null)}
  </EmptyStateWrapper>
);

export default EmptyState;
