import { PreviewCountry } from '@mnd-frontend/graphql-schema';

export const getCountryByLanguage = (lang: string): PreviewCountry => {
  return (
    {
      'no-no': PreviewCountry.No,
      'se-sv': PreviewCountry.Se,
      'dk-da': PreviewCountry.Dk,
    }[lang] || PreviewCountry.Uk
  );
};

export const getMarketByLang = (lang: string): string => {
  switch (lang) {
    case 'en':
      return 'en';
    case 'fi':
      return 'fi';
    case 'da':
    case 'dk-da':
      return 'dk';
    case 'no':
    case 'no-no':
      return 'no';
    case 'de':
    case 'de-de':
      return 'de';
    case 'sv':
    case 'se-sv':
      return 'se';
    default:
      return '';
  }
};

export const getLocale = (lang: string) => {
  switch (lang) {
    case 'se':
      return 'se-sv';
    case 'de':
      return 'de-de';
    case 'dk':
      return 'dk-da';
    case 'no':
      return 'no-no';
    default:
      return 'en';
  }
};
export const shortLocaleToFullLocale = (lang: string) => {
  switch (lang) {
    case 'sv':
      return 'se-sv';
    case 'de':
      return 'de-de';
    case 'da':
      return 'dk-da';
    case 'no':
      return 'no-no';
    default:
      return 'en';
  }
};
