import styled from 'styled-components';

const DayPickerNavBar = styled.div`
  button:first-of-type {
    position: absolute;
    top: 1rem;
    left: 0;
  }

  button:last-of-type {
    position: absolute;
    top: 1rem;
    right: 0;
  }
`;

export default DayPickerNavBar;
