import { components, defaultTheme } from 'react-select';
import styled, { css } from 'styled-components';
import { GREY_100, GREY_1000, GREY_400, GREY_600, NEUTRAL_WHITE } from '../../../../theme';

type Props = Parameters<typeof components.Control>[number];

const Control = (props: Props) => <components.Control {...props} theme={defaultTheme} />;

const StyledControl: any = styled(Control)`
  && {
    background-color: ${NEUTRAL_WHITE};
    border: none;
    box-shadow: none;

    svg {
      /* for the indicators but here to */
      color: ${({ isDisabled }) => (isDisabled ? GREY_400 : GREY_600)};
    }

    ${({ isDisabled }) =>
      isDisabled
        ? css`
            cursor: not-allowed;
          `
        : css`
            &:hover {
              background-color: ${GREY_100};
              svg {
                color: ${GREY_1000};
              }
            }
            cursor: pointer;
          `}
  }
`;

export const PrAssistantControl: any = styled(StyledControl)`
  && {
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
  }
`;

export default Control;
