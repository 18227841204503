import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { GREY_100, GREY_400 } from '../../theme';
import { media } from '../utils/media';
import TableBody, { TableBodyProps } from './TableBody';
import TableHeader, { TableHeaderProps } from './TableHeader';
import { ContentType } from './types';

const StyledTable = styled.table<{ $lockFirstColumnOnMobile?: boolean }>`
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;

  & > thead > tr > th {
    border-bottom: 1px solid ${GREY_400};
  }

  & > tbody > tr {
    background-color: white;

    &:hover {
      background-color: ${GREY_100};
      transition: all 0.25s;
    }
  }
  & > tbody > tr > td,
  & > tbody > tr > th {
    border-bottom: 1px solid ${GREY_400};
  }

  & > tfoot > tr > td,
  & > tfoot > tr > th {
    border-top: 1px solid ${GREY_400};
  }
  ${({ $lockFirstColumnOnMobile }) =>
    $lockFirstColumnOnMobile &&
    css`
      ${media.lessThan('phablet')} {
        col:first-child {
          width: 12rem;
        }
        th:first-child,
        td:first-child {
          position: sticky;
          width: 12rem;
          min-width: 12rem;
          left: 0px;
          background-color: white;
        }
        &::after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 12rem;
          width: 0.6rem;
          background: linear-gradient(90deg, rgba(0, 0, 0, 0.12), transparent);
        }
      }
    `}
`;

const TableWrapper = styled.div`
  position: relative;
  overflow: auto;
`;

const TableScroller = styled.div`
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  ${media.greaterThan('tablet')} {
    padding-left: 0;
  }
`;

type Props = Omit<TableHeaderProps & TableBodyProps, 'contentTypes'> & {
  isLoading?: boolean;
  empty?: React.ReactNode[][];
  contentTypes?: ContentType[];
  lockFirstColumnOnMobile?: boolean;
  withOverflow?: boolean;
};

const Table = ({
  headings,
  rows,
  rowKeys,
  contentTypes,
  isLoading = false,
  empty = [],
  lockFirstColumnOnMobile = true,
  withOverflow,
  withWrap,
  ...props
}: Props) => {
  const isRowsNotEmpty = rows.length > 0 && rows[0].length > 0;

  const bodyRows = isLoading ? [['']] : isRowsNotEmpty ? rows : empty;

  const memodContentTypes = useMemo(
    () => contentTypes || Array(headings.length).fill('text'),
    [contentTypes, headings.length],
  );

  return (
    <TableWrapper>
      <TableScroller>
        <StyledTable $lockFirstColumnOnMobile={lockFirstColumnOnMobile && headings.length > 2}>
          <TableHeader headings={headings} contentTypes={memodContentTypes} {...props} />
          <TableBody
            rows={bodyRows}
            contentTypes={memodContentTypes}
            rowKeys={rowKeys}
            withOverflow={withOverflow}
            withWrap={withWrap}
          />
        </StyledTable>
      </TableScroller>
    </TableWrapper>
  );
};

const OuterTable = React.memo(Table);

/**
 * @deprecated Use DataTable instead
 */
export default OuterTable;
