import { UntypedFunction } from '@mnd-frontend/ts';
import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import { GREY_400 } from '../../theme';
import { Checkbox } from '../Checkbox';
import Attachments from './Attachments';
import ItemBody from './ItemBody';

export const ListItem = styled.li<{ $disabled?: boolean; $inline: boolean }>`
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  min-height: 5rem;
  margin: 0 !important;

  &:last-child {
    border-bottom: none;
  }

  > *:not(:last-child) {
    margin-right: 1rem;
  }

  ${({ $inline }) =>
    !$inline &&
    css`
      & {
        border-bottom: 1px solid ${GREY_400};
      }
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      & {
        opacity: 0.5;
      }
    `}
`;

const Static = styled.div`
  flex-shrink: 0;
  display: inherit;
  align-items: inherit;
  line-height: 0;
`;

const Actions = styled.div`
  margin-left: 2rem;
`;

const shallowEqual = (prev: any, props: any) =>
  Object.keys({ ...prev, ...props }).reduce((acc, curr) => acc && prev[curr] === props[curr], true);

const areEqual = (
  { item: prevItem, ...prevOther }: { item: any; prevOther: any },
  { item: currItem, ...currOther }: { item: any; currOther: any },
) => shallowEqual(prevItem, currItem) && shallowEqual(prevOther, currOther);

export type ResourceListItemType = {
  id?: string;
  avatar?: React.ReactElement;
  date?: React.ReactElement;
  title: React.ReactElement | string;
  description?: React.ReactElement | string;
  attachments?: React.ReactElement | string | number;
  actions?: React.ReactElement;
  disabled?: boolean;
  checkable?: boolean;
  checkId?: number | string;
  checked?: boolean;
  indeterminate?: boolean;
  postfix?: React.ReactElement;
};

export type ResourceListItemProps = {
  item: ResourceListItemType;
  listItemTestId?: string;
  onChangeChecked?: UntypedFunction;
  className?: string;
  inline?: boolean;
};

const ResourceListItem: React.FC<ResourceListItemProps> = ({
  item,
  onChangeChecked,
  listItemTestId,
  className,
  inline = false,
}) => (
  <>
    <ListItem
      data-testid={listItemTestId}
      className={className}
      $disabled={item.disabled}
      $inline={inline}
    >
      {item.checkable && (
        <Static>
          <Checkbox
            disabled={item.disabled}
            state={item.indeterminate ? 'indeterminate' : item.checked ? 'checked' : 'unchecked'}
            onChange={e => onChangeChecked?.(item.checkId, e)}
            id={`checkbox:${item.checkId}`}
          />
        </Static>
      )}
      {item.avatar && <Static>{item.avatar}</Static>}
      <ItemBody
        date={item.date}
        title={item.title}
        description={item.description}
        labelFor={
          item.checkable && item.checkId !== undefined ? `checkbox:${item.checkId}` : undefined
        }
      />
      {item.attachments && <Attachments>{item.attachments}</Attachments>}
      {item.actions && <Actions>{item.actions}</Actions>}
    </ListItem>
    {item.postfix}
  </>
);

ResourceListItem.displayName = 'ResourceListItem';

export default memo(ResourceListItem, areEqual as any);
