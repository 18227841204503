import { useBrowserEffect } from '@mnd-frontend/hooks';
import React, { FC, ReactNode, useCallback, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styled, { css, keyframes } from 'styled-components';
import { DESKTOP_TOOLTIP_HORIZONTAL_PADDING, DESKTOP_TOOLTIP_VERTICAL_PADDING } from '../../theme';
import { getPortalContainer } from '../utils/getPortalContainer';

const DEFAULT_STYLE = { top: '0px', left: '0px' };

const fadein = keyframes`
    0% { opacity: 0; }
    100% {opacity: 1; }
`;

export const TooltipContent = styled.span<{
  $disabled?: boolean;
}>`
  display: inline-block;
  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: not-allowed;
      > *[disabled] {
        pointer-events: none;
      }
    `}
`;

const TooltipLabel = styled.div<{
  $placement: 'left' | 'right' | 'bottom' | 'top';
}>`
  background-color: ${({ theme }) => theme.colorBackgroundTooltipDefault};
  color: ${({ theme }) => theme.colorTextTooltipDefault};
  border-radius: ${({ theme }) => theme.radiusTooltip};
  animation: ${fadein} 200ms cubic-bezier(0.25, 0.55, 0.3, 1);
  padding: ${DESKTOP_TOOLTIP_VERTICAL_PADDING} ${DESKTOP_TOOLTIP_HORIZONTAL_PADDING};
  position: absolute;
  pointer-events: none;
  z-index: 9000;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1rem;

  ${({ $placement }) => {
    switch ($placement) {
      case 'left':
        return css`
          margin-left: -0.75rem;
        `;
      case 'right':
        return css`
          margin-left: 1rem;
        `;
      case 'bottom':
        return css`
          margin-top: 0.5rem;
        `;
      default:
        return css`
          margin-top: -0.5rem;
        `;
    }
  }}
`;

const Portal = ({ children }: { children: React.ReactNode }) => {
  const el = getPortalContainer('tooltip', []);
  if (!el) return null;
  else return ReactDOM.createPortal(children, el);
};

type ToolTipProps = {
  children: ReactNode;
  content: ReactNode;
  hidden?: boolean;
  disabled?: boolean;
  id?: string;
  placement?: 'top' | 'right' | 'bottom' | 'left';
};

export type ToolTipOptions = Pick<
  ToolTipProps,
  'content' | 'disabled' | 'hidden' | 'id' | 'placement'
>;

const ToolTip: FC<ToolTipProps> = ({
  children,
  content,
  id,
  placement,
  hidden,
  disabled,
  ...props
}) => {
  const descriptionElement = useRef<HTMLDivElement>(null);
  const contentElement = useRef<HTMLSpanElement>(null);
  const [style, setStyle] = useState(DEFAULT_STYLE);
  const [hover, setHover] = useState(false);

  const handleEnter = useCallback(() => setHover(true), []);
  const handleLeave = useCallback(() => setHover(false), []);

  useBrowserEffect(() => {
    if (!hover || !contentElement.current) return;

    const { x, y, width, height } = contentElement.current.getBoundingClientRect();

    /**
     * onPointerLeave does not fire reliably on some position absolute elements.
     *
     * Since we need this to catch those cases we skip listening to onPointerLeave and always rely on this.
     */
    const evaluateHover = (mx: number, my: number) => {
      const [px, py, cx, cy, cwidth, cheight] = [mx, my, x, y, width, height].map(Math.round);
      if (px < cx || py < cy || px > cx + cwidth || py > cy + cheight) {
        setHover(false);
      }
    };
    let timer: ReturnType<typeof setTimeout>;
    const onPointerMove = (e: PointerEvent) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        evaluateHover(e.clientX, e.clientY);
      }, 150);
    };

    window.addEventListener('pointermove', onPointerMove);

    /**
     * onPointerLeave does not fire reliably on scroll.
     * We cannot listen to scroll events either, as it might be an Overlay element
     * that is being scrolled and not document/window.
     *
     * This will serve as a poor mans scroll listener.
     */
    const interval = setInterval(() => {
      if (contentElement.current?.getBoundingClientRect().y !== y) {
        setHover(false);
      }
    }, 200);

    return () => {
      clearInterval(interval);
      clearTimeout(timer);
      window.removeEventListener('pointermove', onPointerMove);
    };
  }, [hover]);

  useBrowserEffect(() => {
    if (!hover) {
      setStyle(DEFAULT_STYLE);
      return;
    }
    if (!contentElement.current || !descriptionElement.current) {
      return;
    }
    const content = contentElement.current.getBoundingClientRect();
    const description = descriptionElement.current.getBoundingClientRect();
    const xpos = window.scrollX || document.documentElement.scrollLeft;
    const ypos = window.scrollY || document.documentElement.scrollTop;
    let x: number;
    let y: number;
    let tx: number;
    let ty: number;

    if (placement === 'left') {
      x = Math.round(xpos + content.left);
    } else if (placement === 'right') {
      x = Math.round(xpos + content.right);
    } else {
      x = Math.round(xpos + content.left + content.width * 0.5);
    }

    if (placement === 'left' || placement === 'right') {
      y = Math.round(ypos + content.top + content.height * 0.5);
    } else if (placement === 'bottom') {
      y = Math.round(ypos + content.bottom);
    } else {
      y = Math.round(ypos + content.top);
    }

    if (placement === 'left') {
      tx = Math.round(x - description.width);
    } else if (placement === 'right') {
      tx = x;
    } else {
      tx = Math.round(x - description.width * 0.5);
    }

    if (placement === 'left' || placement === 'right') {
      ty = Math.round(y - description.height * 0.5);
    } else if (placement === 'bottom') {
      ty = y;
    } else {
      ty = Math.round(y - description.height);
    }
    setStyle({ top: `${Math.max(ty, 0)}px`, left: `${Math.max(tx, 0)}px` });
  }, [descriptionElement, contentElement, placement, hover]);

  if (hidden) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
  return (
    <>
      <TooltipContent
        ref={contentElement}
        onPointerEnter={handleEnter}
        onPointerLeave={handleLeave}
        $disabled={disabled}
        {...props}
      >
        {children}
      </TooltipContent>
      <Portal>
        {hover && (
          <TooltipLabel
            id={id}
            ref={descriptionElement}
            role="tooltip"
            $placement={placement ?? 'top'}
            style={style}
          >
            {content}
          </TooltipLabel>
        )}
      </Portal>
    </>
  );
};

export default ToolTip;
