import { MaterialType } from '@mnd-frontend/graphql-schema';

export const MATERIAL_TYPE_PRESS_RELEASES = 'pressreleases';
export const MATERIAL_TYPE_NEWS = 'news';
export const MATERIAL_TYPE_BLOG_POSTS = 'blog_posts';
export const MATERIAL_TYPE_EVENTS = 'events';
export const MATERIAL_TYPE_IMAGES = 'images';
export const MATERIAL_TYPE_VIDEOS = 'videos';
export const MATERIAL_TYPE_DOCUMENTS = 'documents';

export const MATERIAL_TYPES_ALL = [
  MATERIAL_TYPE_PRESS_RELEASES,
  MATERIAL_TYPE_NEWS,
  MATERIAL_TYPE_BLOG_POSTS,
  MATERIAL_TYPE_EVENTS,
  MATERIAL_TYPE_IMAGES,
  MATERIAL_TYPE_VIDEOS,
  MATERIAL_TYPE_DOCUMENTS,
] as const;

export const MATERIAL_TYPE_LABELS = {
  [MaterialType.BlogPost]: 'publish_pr-edit_related-material_filter-blog-posts',
  [MaterialType.Document]: 'publish_pr-edit_related-material_filter-documents',
  [MaterialType.Event]: 'publish_pr-edit_related-material_filter-events',
  [MaterialType.Image]: 'publish_pr-edit_related-material_filter-images',
  [MaterialType.News]: 'publish_pr-edit_related-material_filter-news',
  [MaterialType.Pressrelease]: 'publish_pr-edit_related-material_filter-pressreleases',
  [MaterialType.Video]: 'publish_pr-edit_related-material_filter-videos',
};

export const MATERIAL_TYPE_LABELS_SINGULAR = {
  [MaterialType.BlogPost]: 'common.label.blog_post',
  [MaterialType.Document]: 'common.label.document',
  [MaterialType.Event]: 'common.label.event',
  [MaterialType.Image]: 'common.label.image',
  [MaterialType.News]: 'common.label.news',
  [MaterialType.Pressrelease]: 'common.label.pressrelease',
  [MaterialType.Video]: 'common.label.video',
};

export const MAP_MATERIAL_TYPES = {
  Pressrelease: 'pressreleases',
  News: 'news',
  BlogPost: 'blog_posts',
  Event: 'events',
  Image: 'images',
  Video: 'videos',
  Document: 'documents',
} as const;
