import React from 'react';
import styled, { css } from 'styled-components';
import Fieldset from '../Fieldset';
import { Legend } from '../Typo';
import { media } from '../utils/media';

const Layout = styled.div`
  ${media.greaterThan('tablet')} {
    display: flex;
    flex-wrap: wrap;
    margin-right: -1.5rem;
    margin-top: -1.5rem;
  }
`;

const LayoutItem = styled.div`
  width: 100%;

  & + & {
    margin-top: 1.5rem;
  }

  ${media.greaterThan('tablet')} {
    margin-top: 1.5rem;
    max-width: calc(50% - 1.5rem);
    margin-right: 1.5rem;
  }
`;

type ComponentProps = {
  label: string;
  multiColumn?: boolean;
  children: React.ReactNode;
  className?: string;
};
const Component = ({ label, children, multiColumn, className }: ComponentProps) => (
  <Fieldset className={className}>
    {label && <Legend>{label}</Legend>}
    {multiColumn ? (
      <Layout>
        {React.Children.map(children, child => child && <LayoutItem>{child}</LayoutItem>)}
      </Layout>
    ) : (
      children
    )}
  </Fieldset>
);

type Props = {
  hideLabels?: boolean;
};

const FormFieldset = styled(Component)<Props>`
  padding: 0;
  border: none;

  ${({ multiColumn }) =>
    !multiColumn &&
    css`
      & > * + * {
        margin-top: 1.5rem;
      }

      & > legend + * {
        margin-top: 0;
      }
    `}

  ${({ hideLabels }) =>
    hideLabels &&
    css`
      & label {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
      }
    `}
`;

export default FormFieldset;
