import styled, { css } from 'styled-components';
import withMediaQueries, { selectMedia } from '../../utils/withMediaQueries';

export type MediaProp<T> = T | Partial<{ xs?: T; sm?: T; md?: T; lg?: T }>;

export type FlexType = {
  style?: React.CSSProperties;
  $flexDirection?: MediaProp<'row' | 'row-reverse' | 'column' | 'column-reverse'>;
  $flexWrap?: MediaProp<'nowrap' | 'wrap' | 'wrap-reverse'>;
  $flex?: number;
  $justifyContent?: MediaProp<
    | 'normal'
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch'
  >;
  $alignContent?: MediaProp<
    | 'normal'
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch'
    | 'baseline'
  >;
  $alignItems?: MediaProp<
    | 'normal'
    | 'stretch'
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'baseline'
    | 'first baseline'
    | 'last baseline'
  >;
};

const Flex = styled.div<FlexType>`
  display: flex;

  ${withMediaQueries(
    sizeKey => css`
      flex-direction: ${selectMedia('$flexDirection', sizeKey)};
      flex-wrap: ${selectMedia('$flexWrap', sizeKey)};
      justify-content: ${selectMedia('$justifyContent', sizeKey)};
      align-content: ${selectMedia('$alignContent', sizeKey)};
      align-items: ${selectMedia('$alignItems', sizeKey)};
    `,
  )}
`;

Flex.displayName = 'Flex';

export default Flex;
