import React from 'react';
import styled, { css } from 'styled-components';
import Pagination from '../../Pagination';
import { media } from '../../utils/media';
import Actions from './Actions';

const Layout = styled.div`
  ${media.greaterThan('tablet')} {
    display: flex;
    flex-wrap: wrap;
    margin-right: -1.5rem;
    margin-top: -1.5rem;
  }
`;

const LayoutItem = styled.div`
  width: 100%;

  & + & {
    margin-top: 1.5rem;
  }

  ${media.greaterThan('tablet')} {
    margin-top: 1.5rem;
    max-width: calc(50% - 1.5rem);
    margin-right: 1.5rem;
  }
`;

type ColumnProps = {
  /** Use multicolumn layout for large devices */
  multiColumn?: boolean;
  children: React.ReactNode;
  className?: string;
};

const Column = styled(({ multiColumn, children, className }: ColumnProps) => {
  const content = React.useMemo(
    () =>
      multiColumn ? (
        <Layout>
          {React.Children.map(children, child => child && <LayoutItem>{child}</LayoutItem>)}
        </Layout>
      ) : (
        children
      ),
    [children, multiColumn],
  );

  return <div className={className}>{content}</div>;
})`
  & + & {
    margin-top: 1.5rem;
  }
  ${({ multiColumn }) =>
    !multiColumn &&
    css`
      & > * + * {
        margin-top: 1.5rem;
      }
    `}
  & > ${Pagination}, & > ${Actions} {
    margin-top: 1rem;
  }
`;

export default Column;
