import { differenceInCalendarDays, format, isSameDay, parse } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

type DateInput = string | Date;

// Empty array will make Intl use system defaults.
let defaultLocale: string | [] = [];

const localeRewrites: { [key: string]: string } = {
  no: 'nb',
};

const rewriteLocale = (locale?: typeof defaultLocale) => {
  let newLocale: string | [] = defaultLocale;
  if (typeof locale === 'string') {
    newLocale = localeRewrites[locale] ?? locale.replace('_', '-');
  } else {
    newLocale = locale ?? [];
  }

  try {
    new Intl.DateTimeFormat(newLocale);
  } catch (e) {
    // Fallback to default locale if the locale is not supported
    newLocale = defaultLocale;
  }

  return newLocale;
};

export const yearlessShortDateString = (
  date: DateInput,
  timezone: string,
  locale: string,
): string =>
  new Intl.DateTimeFormat(rewriteLocale(locale), {
    timeZone: timezone,
    day: '2-digit',
    month: 'short',
  }).format(new Date(date));

export const shortDateString = (date: DateInput, timezone: string, locale: string): string =>
  new Intl.DateTimeFormat(rewriteLocale(locale), {
    timeZone: timezone,
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  }).format(new Date(date));

export const dateString = (date: DateInput, timezone: string, locale: string): string =>
  new Intl.DateTimeFormat(rewriteLocale(locale), {
    timeZone: timezone,
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  }).format(new Date(date));

export const relativeDayString = (
  date: DateInput,
  timezone: string,
  locale: string,
  compareDate = new Date(),
): string => {
  const daysDiff = differenceInCalendarDays(new Date(date), compareDate);
  if (Math.abs(daysDiff) <= 1) {
    return new Intl.RelativeTimeFormat(rewriteLocale(locale), {
      numeric: 'auto',
    }).format(daysDiff, 'day');
  }

  return new Intl.DateTimeFormat(rewriteLocale(locale), {
    timeZone: timezone,
    weekday: 'long',
  }).format(new Date(date));
};

export const relativeDateTimeString = (
  date: DateInput,
  timezone: string,
  locale: string,
  compareDate = new Date(),
): string => {
  const daysDiff = differenceInCalendarDays(new Date(date), compareDate);

  if (Math.abs(daysDiff) <= 1) {
    const relativeDay = new Intl.RelativeTimeFormat(rewriteLocale(locale), {
      numeric: 'auto',
    }).format(daysDiff, 'day');

    return `${relativeDay.charAt(0).toLocaleUpperCase()}${relativeDay.substring(
      1,
    )} ${new Intl.DateTimeFormat(rewriteLocale(locale), {
      timeZone: timezone,
      hour: '2-digit',
      minute: '2-digit',
    }).format(new Date(date))}`;
  }

  return new Intl.DateTimeFormat(rewriteLocale(locale), {
    timeZone: timezone,
    day: '2-digit',
    month: 'long',
    hour: '2-digit',
    minute: '2-digit',
  }).format(new Date(date));
};

export const shortDateTimeStringWithTz = (
  date: DateInput,
  timezone: string,
  locale: string,
): string =>
  new Intl.DateTimeFormat(rewriteLocale(locale), {
    timeZone: timezone,
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
  }).format(new Date(date));

export const dateTimeStringWithTz = (date: DateInput, timezone: string, locale: string): string =>
  new Intl.DateTimeFormat(rewriteLocale(locale), {
    timeZone: timezone,
    day: '2-digit',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
  }).format(new Date(date));

export const shortDateTimeString = (date: DateInput, timezone: string, locale: string): string =>
  date
    ? new Intl.DateTimeFormat(rewriteLocale(locale), {
        timeZone: timezone,
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }).format(new Date(date))
    : '';

export const dateTimeString = (date: DateInput, timezone: string, locale: string): string =>
  date
    ? new Intl.DateTimeFormat(rewriteLocale(locale), {
        timeZone: timezone,
        day: '2-digit',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }).format(new Date(date))
    : '';

export const weekdayLong = (date: DateInput, locale: string): string =>
  date
    ? new Intl.DateTimeFormat(rewriteLocale(locale), {
        weekday: 'long',
      }).format(new Date(date))
    : '';

export const dateStringWithDay = (date: DateInput, locale: string): string =>
  date
    ? new Intl.DateTimeFormat(rewriteLocale(locale), {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        weekday: 'long',
      }).format(new Date(date))
    : '';

export const dateStringWithYearAndMonth = (
  date: DateInput,
  timezone: string,
  locale: string,
): string =>
  date
    ? new Intl.DateTimeFormat(rewriteLocale(locale), {
        timeZone: timezone,
        month: '2-digit',
        year: 'numeric',
      }).format(new Date(date))
    : '';

export const timeFromDate = (date: DateInput, timezone: string, locale: string): string =>
  date
    ? new Intl.DateTimeFormat(rewriteLocale(locale), {
        timeZone: timezone,
        hour: '2-digit',
        minute: '2-digit',
      }).format(new Date(date))
    : '';

export const convertDateToBrowserTimezone = (date: DateInput, timezone: string): Date => {
  try {
    const parts = new Intl.DateTimeFormat(defaultLocale, {
      timeZone: timezone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    }).formatToParts(new Date(date));
    const yearPart = Number(parts.find(p => p.type === 'year')?.value);
    const monthPart = Number(parts.find(p => p.type === 'month')?.value) - 1;
    const dayPart = Number(parts.find(p => p.type === 'day')?.value);
    const hourPart = Number(parts.find(p => p.type === 'hour')?.value);
    const minutePart = Number(parts.find(p => p.type === 'minute')?.value);
    const secondPart = Number(parts.find(p => p.type === 'second')?.value);

    const returnDate = new Date(date);
    returnDate.setFullYear(yearPart);
    returnDate.setMonth(monthPart);
    returnDate.setDate(dayPart);
    returnDate.setHours(hourPart);
    returnDate.setMinutes(minutePart);
    returnDate.setSeconds(secondPart);

    return returnDate;
  } catch (e) {
    console.error(e);
    return new Date(date);
  }
};

export const isoDate = (date: DateInput, { timezone }: { timezone?: string } = {}): string => {
  // Use Intl to get TZ handling
  const dateObj = new Date(date);
  const year = new Intl.DateTimeFormat(defaultLocale, {
    timeZone: timezone,
    year: 'numeric',
  })
    .format(dateObj)
    .replace(/\D/g, '');
  const month = new Intl.DateTimeFormat(defaultLocale, {
    timeZone: timezone,
    month: '2-digit',
  })
    .format(dateObj)
    .replace(/\D/g, '');
  const day = new Intl.DateTimeFormat(defaultLocale, {
    timeZone: timezone,
    day: '2-digit',
  })
    .format(dateObj)
    .replace(/\D/g, '');

  return `${year}-${month}-${day}`;
};

export const isoDateString = (date: DateInput): string => new Date(date).toISOString();

const YEAR = 31536000000;
const MONTH = 2592000000;
const DAY = 86400000;
const HOUR = 3600000;
const MINUTE = 60000;
const SECOND = 1000;

export const timeFromNow = (date: DateInput): string => {
  try {
    const now = new Date();
    const input = new Date(date);
    const formatter = new Intl.RelativeTimeFormat(defaultLocale);

    const diff = input.valueOf() - now.valueOf();

    const yearDiff = diff / YEAR;
    if (Math.abs(yearDiff) >= 1) return formatter.format(Math.round(yearDiff), 'year');
    const monthDiff = diff / MONTH;
    if (Math.abs(monthDiff) >= 1) return formatter.format(Math.round(monthDiff), 'month');
    const dateDiff = diff / DAY;
    if (Math.abs(dateDiff) >= 1) return formatter.format(Math.round(dateDiff), 'day');
    const hourDiff = diff / HOUR;
    if (Math.abs(hourDiff) >= 1) return formatter.format(Math.round(hourDiff), 'hour');
    const minuteDiff = diff / MINUTE;
    if (Math.abs(minuteDiff) >= 1) return formatter.format(Math.round(minuteDiff), 'minute');
    const secondDiff = diff / SECOND;
    if (Math.abs(secondDiff) >= 1) return formatter.format(Math.round(secondDiff), 'second');

    return new Intl.RelativeTimeFormat(defaultLocale, { numeric: 'auto' }).format(0, 'second');
  } catch (e) {
    console.error(e);
    return isoDateString(date);
  }
};

interface Weekday {
  day: string;
  index: number;
}

const base = '2021-01-';
const sunday = 10;

export const ISO_WEEKDAY_NAMES = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
];

/**
 * Intl API does not have support for this feature.
 * So we are just being naive and use monday for everyone except english locales.
 */
export const firstDayOfWeek = (locale = defaultLocale): number =>
  new Intl.DateTimeFormat(rewriteLocale(locale), { weekday: 'long' }).format(
    new Date(`${base}${sunday}`),
  ) === 'Sunday'
    ? 0
    : 1;

export const weekdays = (params?: { locale?: typeof defaultLocale; long?: boolean }): Weekday[] => {
  const defaultParams = { locale: defaultLocale, long: false };
  const { locale, long } = params ?? defaultParams;

  const start = firstDayOfWeek() + sunday;
  const formatter = new Intl.DateTimeFormat(locale, {
    weekday: long ? 'long' : 'short',
  });
  return Array.from({ length: 7 }).map((_, i) => ({
    day: formatter.format(new Date(`${base}${start + i}`)),
    index: (i + start - sunday) % 7,
  }));
};

export const localizeTime = (time: DateInput): string => {
  const date = typeof time === 'string' ? parse(time, 'HH:mmxxx', new Date()) : time;

  return new Intl.DateTimeFormat(defaultLocale, {
    hour: '2-digit',
    minute: '2-digit',
  }).format(date);
};

const pad = (num: number): string => (num < 10 ? `0${num}` : String(num));

export const localizeTimeForMonitor = (time: DateInput): string => {
  const date = typeof time === 'string' ? parse(time, 'HH:mmxxx', new Date()) : time;

  return `${pad(date.getHours())}:${pad(date.getMinutes())}`;
};

export const unlocalizeTime = (time: DateInput): string => {
  const date = typeof time === 'string' ? parse(time, 'HH:mm', new Date()) : time;

  return new Intl.DateTimeFormat(defaultLocale, {
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
  }).format(date);
};

export const unlocalizeTimeForMonitor = (time: DateInput): string => {
  const date = typeof time === 'string' ? parse(time, 'HH:mm', new Date()) : time;

  return format(date, 'HH:mmxxx');
};

export const orderDays = (days: number[]): string[] => {
  const daysObj: { [key: string]: number } = {};
  days.forEach(d => (daysObj[d] = d));

  const sortedDays = Object.values(daysObj).sort();
  const formatter = new Intl.DateTimeFormat(defaultLocale, {
    weekday: 'long',
  });

  return sortedDays.map(d => formatter.format(new Date(`${base}${sunday + d}`)));
};

export const getUtcOffset = (timezone: string, atTime = new Date()) =>
  (toZonedTime(atTime, timezone).valueOf() - toZonedTime(atTime, 'UTC').valueOf()) / 60000;

export const areDatesEqual = (date1: DateInput, date2: DateInput, allDay: boolean) => {
  if (allDay) {
    return isSameDay(new Date(date1), new Date(date2));
  }
  return date1 === date2;
};

export const getUserTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const setDefaultDateFormattingLocale = (locale: string | undefined) => {
  defaultLocale = rewriteLocale(locale);
};

export const getDateWithTimezoneFromString = (
  date: string,
  timezone: string,
  { timeOfDay }: { timeOfDay: 'end' | 'start' },
) => {
  const dateObj = new Date(date);
  const offset = getUtcOffset(timezone, dateObj) * 60000;

  const tzTimeAsUtc = new Date(dateObj.valueOf() + offset);

  // Remove any local timestamp by using the fact that new Date(0) is 00:00 UTC.
  // Which means that if we remove any rest from division by day we will get 00:00 UTC
  const startOfDay = tzTimeAsUtc.valueOf() - (tzTimeAsUtc.valueOf() % DAY);
  const endOfDay = startOfDay + DAY - 1;

  let dateValue = tzTimeAsUtc.valueOf();

  if (timeOfDay === 'start') {
    dateValue = startOfDay;
  } else if (timeOfDay === 'end') {
    dateValue = endOfDay;
  }
  return new Date(dateValue - offset);
};

export const convertDateFromBrowserTimezone = (date: DateInput, timezone: string) => {
  const dateObj = new Date(date);
  const offset =
    getUtcOffset(getUserTimezone(), dateObj) * 60000 - getUtcOffset(timezone, dateObj) * 60000;
  return new Date(dateObj.valueOf() + offset);
};

export const monthNameLong = (date: DateInput, locale = 'en') =>
  new Intl.DateTimeFormat(rewriteLocale(locale), {
    month: 'long',
    year: 'numeric',
  }).format(new Date(date));
export const monthNameShort = (date: DateInput, locale = 'en') =>
  new Intl.DateTimeFormat(rewriteLocale(locale), {
    month: 'short',
  }).format(new Date(date));

export const DayPickerLocaleUtils = {
  formatDay: (day: DateInput, locale = 'en') =>
    new Intl.DateTimeFormat(rewriteLocale(locale), {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      weekday: 'short',
    }).format(new Date(day)),
  formatMonthTitle: monthNameLong,
  formatWeekdayShort: (day: number, locale = 'en') =>
    new Intl.DateTimeFormat(rewriteLocale(locale), {
      weekday: 'short',
    }).format(new Date(`${base}${sunday + day}`)),
  formatWeekdayLong: (day: number, locale = 'en') =>
    new Intl.DateTimeFormat(rewriteLocale(locale), {
      weekday: 'long',
    }).format(new Date(`${base}${sunday + day}`)),
  getFirstDayOfWeek: (locale = 'en') => firstDayOfWeek(locale),
};
