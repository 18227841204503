import { ChangeEventHandler } from 'react';
import styled from 'styled-components';
import { RADIUS_2XL } from '../../theme';

const StyledSwitch = styled.div`
  z-index: 0;
  box-sizing: border-box;
  position: relative;
  height: 1rem;
  width: 2rem;
  pointer-events: none;
  background: ${props => props.theme.colorBackgroundToggleSwitchFalseEnabled};
  border: 1px solid ${props => props.theme.colorBorderToggleSwitchFalseEnabled};
  border-radius: ${RADIUS_2XL};
  transition: all 200ms ease;

  &:before {
    content: '';
    transition: all 200ms ease;
    display: block;
    position: absolute;
    top: 1px;
    left: 1px;
    border-radius: 50%;
    height: 0.75rem;
    width: 0.75rem;
    background: white;
    transform: translateX(0);
  }
`;

const LabelText = styled.span`
  font-size: 1rem;
  line-height: 1.5rem;
`;

const Label = styled.label<{ $disabled?: boolean }>`
  align-items: center;
  display: flex;
  position: relative;
  gap: 0.5rem;
  cursor: ${props => (props.$disabled ? 'auto' : 'pointer')};
  ${LabelText} {
    color: ${props =>
      props.$disabled ? props.theme.colorTextBodyDisabled : props.theme.colorTextBodyPrimary};
  }

  > input[type='checkbox'] {
    opacity: 0;
    z-index: -1;
    position: absolute;

    &:hover + ${StyledSwitch}, &:focus + ${StyledSwitch} {
      border-color: ${props => props.theme.colorBorderToggleSwitchFalseHover};
    }
    &:hover:enabled:checked + ${StyledSwitch}, &:focus:enabled:checked + ${StyledSwitch} {
      border-color: ${props => props.theme.colorBackgroundToggleSwitchTrueHover};
      background: ${props => props.theme.colorBackgroundToggleSwitchTrueHover};
    }

    &:checked + ${StyledSwitch}:before {
      transform: translateX(1rem);
    }

    &:disabled + ${StyledSwitch} {
      background: ${props => props.theme.colorBackgroundToggleSwitchFalseDisabled};
      border-color: ${props => props.theme.colorBorderToggleSwitchFalseDisabled};
    }
    &:checked:enabled + ${StyledSwitch} {
      background: ${props => props.theme.colorBackgroundToggleSwitchTrueEnabled};
      border-color: ${props => props.theme.colorBackgroundToggleSwitchTrueEnabled};
    }
    &:checked:disabled + ${StyledSwitch} {
      background: ${props => props.theme.colorBackgroundToggleSwitchTrueDisabled};
      border-color: ${props => props.theme.colorBackgroundToggleSwitchTrueDisabled};
    }
  }
`;

export interface ToggleSwitchProps {
  checked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  label?: string;
  disabled?: boolean;
  id?: string;
  'data-testid'?: string;
}

export const ToggleSwitch = ({
  checked,
  onChange,
  label,
  disabled,
  id,
  'data-testid': testId,
}: ToggleSwitchProps) => {
  return (
    <Label $disabled={disabled}>
      <input
        id={id}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        role="switch"
        aria-checked={checked}
        disabled={disabled}
        data-testid={testId}
      />
      <StyledSwitch />
      <LabelText>{label}</LabelText>
    </Label>
  );
};
