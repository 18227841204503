import { createGlobalStyle } from 'styled-components';
import { TEXT_FONT } from '../../theme';

export default createGlobalStyle`
  [data-whatintent='touch'] *:focus,
  [data-whatintent='mouse'] *:focus,
  *:active:focus {
    &[class*=btn] {
      outline: none;
    }
    outline: none;
  }

   * {
    box-sizing: border-box;
   }

   body,
   .redactor_box .redactor_editor div, .redactor_box .redactor_editor p, 
   .redactor_box .redactor_editor ul, .redactor_box .redactor_editor ol, 
   .redactor_box .redactor_editor table, .redactor_box .redactor_editor dl, 
   .redactor_box .redactor_editor blockquote, .redactor_box .redactor_editor pre,
   .redactor_toolbar,
   .redactor_dropdown{
    font-family: ${TEXT_FONT};
  }

  body.block-scrolling {
    height: 100%;
    overflow: hidden;
  }

`;
