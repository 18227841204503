import styled from 'styled-components';

export const ScreenWrapper = styled.div<{ $lockedHeight?: boolean; $background?: string }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: ${props => (props.$lockedHeight ? 'hidden' : 'auto')};
  z-index: 2500;
  --current-background: ${props => props.$background ?? 'white'};
  background: ${props => props.$background ?? 'white'};

  &.fade-enter {
    opacity: 0.01;
  }

  &.fade-enter-active {
    opacity: 1;
    transition: opacity 150ms cubic-bezier(0.25, 0.55, 0.3, 1);
  }

  &.fade-exit {
    opacity: 1;
  }

  &.fade-exit-active {
    opacity: 0.01;
    transition: opacity 150ms cubic-bezier(0.25, 0.55, 0.3, 1);
  }

  &.fade-enter > *:first-child {
    transform: translate3d(0, -2rem, 0);
  }

  &.fade-enter-active > *:first-child {
    transform: translate3d(0, 0, 0);
    transition: transform 200ms cubic-bezier(0.25, 0.55, 0.3, 1);
  }

  &.fade-exit > *:first-child {
    transform: translate3d(0, 0, 0);
  }

  &.fade-exit-active > *:first-child {
    transform: translate3d(0, -2rem, 0);
    transition: transform 200ms cubic-bezier(0.25, 0.55, 0.3, 1);
  }
`;
