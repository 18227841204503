import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import {
  FOCUS_SHADOW,
  GREY_100,
  GREY_1000,
  GREY_200,
  GREY_300,
  GREY_400,
  GREY_700,
  GREY_800,
  GREY_900,
  NEUTRAL_WHITE,
  PURPLE_100,
  PURPLE_200,
  PURPLE_300,
  PURPLE_400,
  PURPLE_500,
  PURPLE_700,
  RED_200,
  RED_500,
  RED_600,
  RED_700,
  SHADOW_1,
  TEAL_100,
  TEAL_200,
  TEAL_700,
} from '../../../theme';
import Icons from '../../Icons';
import Highlighter from '../Highlighter';

const color10: Record<string, string> = {
  primary: RED_200,
  secondary: PURPLE_100,
  dark: GREY_1000,
  purple: PURPLE_200,
  prAssistant: TEAL_100,
  fixed: GREY_300,
};

export const color20: Record<string, string> = {
  primary: RED_500,
  secondary: PURPLE_400,
  dark: GREY_900,
  purple: PURPLE_200,
  prAssistant: TEAL_100,
  fixed: GREY_300,
};

const color30: Record<string, string> = {
  primary: RED_200, // TODO: For now keep the same as color10 to not break old components, should update all tags to look like in figma
  secondary: PURPLE_100, // TODO: For now keep the same as color10 to not break old components, should update all tags to look like in figma
  dark: GREY_1000, // TODO: For now keep the same as color10 to not break old components, should update all tags to look like in figma
  purple: PURPLE_300,
  prAssistant: TEAL_200,
  fixed: GREY_300, // TODO: For now keep the same as color10, since this should be reflect disabled state. This will be refactored once we have it in design system in figma
};

const color60: Record<string, string> = {
  primary: RED_600,
  secondary: PURPLE_500,
  dark: GREY_900,
  purple: GREY_900,
  prAssistant: TEAL_700,
  fixed: GREY_300,
};

export const color100: Record<string, string> = {
  primary: RED_700,
  secondary: PURPLE_700,
  dark: 'rgba(255, 255, 255, 0.7)',
  purple: GREY_900,
  prAssistant: TEAL_700,
  fixed: GREY_700,
};

const tagListItemStyles = (props: {
  $shadow?: boolean;
  $highlighted: boolean;
  $itemStyle?: ItemStyle;
  $variant?: string;
}) => css`
  filter: ${props.$shadow ? `drop-shadow(${SHADOW_1})` : 'none'};
  position: relative;
  flex-grow: ${props.$itemStyle === 'stretched' && 1};
  flex-basis: ${props.$itemStyle === 'stacked' && '100%'};
  list-style: none;
  text-overflow: ellipsis;
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: ${props.$highlighted && 600};
`;

const TagListItemDismissible = styled.li<{
  disabled?: boolean;
  $shadow?: boolean;
  $highlighted: boolean;
  $itemStyle?: ItemStyle;
  $variant?: string;
}>`
  cursor: default;
  ${props => tagListItemStyles(props)};
  color: ${props => (props.$variant && color100[props.$variant]) || GREY_1000};
  background: ${props => (props.$variant && color10[props.$variant]) || NEUTRAL_WHITE};
  border: 1px solid ${props => (props.$variant && color20[props.$variant]) || GREY_400};
  border-radius: 0.5rem;
  padding: ${props =>
    props.$variant && props.$variant === 'fixed'
      ? '0.5rem 0.75rem 0.5rem 0.75rem'
      : '0.5rem 1.875rem 0.5rem 0.75rem'}; // TODO: Hack for now. Should be refactored once we have Tag/Chip in design system in figma
  ${({ disabled, $variant }) =>
    disabled &&
    css`
      background-color: ${($variant && color20[$variant]) || GREY_200};
      border-color: ${($variant && color20[$variant]) || GREY_200};
      cursor: not-allowed;
      color: ${($variant && color100[$variant]) || GREY_1000};
    `};

  &:hover {
    background-color: ${props => (props.$variant && color30[props.$variant]) || NEUTRAL_WHITE};
    text-decoration: none;
    color: ${props => (props.$variant && color100[props.$variant]) || GREY_1000};
    transition: all 0.3s ease;
  }
`;

const TagListItemNone = styled.li<{
  $shadow?: boolean;
  $highlighted: boolean;
  $itemStyle?: ItemStyle;
  $variant?: string;
}>`
  cursor: default;
  ${props => tagListItemStyles(props)};
  color: ${props => (props.$variant && color100[props.$variant]) || GREY_1000};
  background: ${props => (props.$variant && color10[props.$variant]) || NEUTRAL_WHITE};
  border: 1px solid ${props => (props.$variant && color20[props.$variant]) || GREY_400};
  border-radius: 0.5rem;
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
`;

const TagListItemClickable = styled.li<{
  $shadow?: boolean;
  $highlighted: boolean;
  $itemStyle?: ItemStyle;
  $variant?: string;
}>`
  ${props => tagListItemStyles(props)};
`;

const TagButton = styled.button<{
  $variant?: string;
}>`
  text-align: left;
  width: 100%;
  color: ${props => (props.$variant && color100[props.$variant]) || GREY_1000};
  background: ${props => (props.$variant && color10[props.$variant]) || NEUTRAL_WHITE};
  border: 1px solid ${props => (props.$variant && color20[props.$variant]) || GREY_400};
  border-radius: 0.5rem;
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  cursor: pointer;
  > * {
    pointer-events: none;
    user-select: none;
  }
  &:hover {
    background-color: ${props => (props.$variant && color10[props.$variant]) || GREY_100};
    border-color: ${props => (props.$variant && color60[props.$variant]) || GREY_400};
    text-decoration: none;
    color: ${props => (props.$variant && color100[props.$variant]) || GREY_1000};
    transition: all 0.3s ease;
  }
  &:disabled {
    background-color: ${props => (props.$variant && color20[props.$variant]) || GREY_200};
    border-color: ${props => (props.$variant && color20[props.$variant]) || GREY_400};
    cursor: not-allowed;
    color: ${props => (props.$variant && color100[props.$variant]) || GREY_1000};
  }
  &:focus {
    z-index: 1;
    outline: none;
    border-color: ${PURPLE_700};
    box-shadow: ${FOCUS_SHADOW};
  }
`;

const IconWrapper = styled.span`
  display: flex;
  gap: 8px;
  align-items: center;
  > i {
    height: ${({ theme }) => theme.sizingIconXxs};
  }
`;

const TagRemove = styled.button<{ $variant?: string }>`
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  background: none;
  border: none;
  box-shadow: none;
  padding: 0;

  > i {
    line-height: 0;
    display: block;

    path {
      vector-effect: non-scaling-stroke;
    }
  }
  svg {
    color: ${props => (props.$variant && color60[props.$variant]) || GREY_800};
  }
  &:hover {
    color: ${props => (props.$variant && color100[props.$variant]) || GREY_1000};
    transition: all 0.3s ease;
    svg {
      color: ${props => (props.$variant && color100[props.$variant]) || GREY_1000};
    }
  }
  &:disabled {
    cursor: not-allowed;
    color: ${props => (props.$variant && color100[props.$variant]) || GREY_1000};
    svg {
      color: ${props => (props.$variant && color100[props.$variant]) || GREY_1000};
    }
  }
  &:focus {
    z-index: 1;
    outline: none;
    border-color: ${PURPLE_700};
    box-shadow: ${FOCUS_SHADOW};
  }
`;

type CommonTagProps = {
  data: TagData;
  itemStyle?: ItemStyle;
  variant?: 'primary' | 'secondary' | 'dark' | 'purple' | 'prAssistant';
  shadow?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  disabled?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  onTouchStart?: (event: React.TouchEvent<HTMLButtonElement>) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  onTouchEnd?: (event: React.TouchEvent<HTMLButtonElement>) => void;
};

const TagDismissible: React.FC<
  CommonTagProps & {
    onDismiss: (data: TagData, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    dismissAriaLabel?: string;
    data: TagData & { badge?: React.ReactNode };
  }
> = ({
  data,
  itemStyle = 'default',
  onDismiss,
  variant: variantProp,
  shadow = false,
  dismissAriaLabel,
  disabled: parentDisabled,
  onTouchStart,
  onTouchEnd,
}) => {
  const disabled = data.disabled || parentDisabled;
  const [t] = useTranslation();
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    onDismiss(data, e);
  };
  const variant = data.variant ?? variantProp;
  return (
    <TagListItemDismissible
      $highlighted={data.highlighted ?? false}
      disabled={disabled}
      $shadow={shadow}
      data-testid={data.testId}
      $itemStyle={itemStyle}
      $variant={data.isFixed ? 'fixed' : variant} // TODO: Should be refactored once we have Tag/Chip in design system in figma
    >
      <IconWrapper>
        {data.icon}
        {data.name}
      </IconWrapper>
      {!data.isFixed && (
        <TagRemove
          type="button"
          onMouseDown={e => e.stopPropagation()}
          onTouchStart={onTouchStart}
          onTouchEnd={onTouchEnd}
          disabled={disabled}
          onClick={handleClick}
          $variant={variant}
          aria-label={dismissAriaLabel || t('components_tag_remove-label', { name: data.name })}
        >
          <Icons.Close $size="xxs" />
        </TagRemove>
      )}
      {data.badge}
    </TagListItemDismissible>
  );
};

const TagNone: React.FC<CommonTagProps> = ({
  data,
  itemStyle = 'default',
  variant,
  shadow = false,
}) => (
  <TagListItemNone
    $highlighted={data.highlighted ?? false}
    $shadow={shadow}
    data-testid={data.testId}
    $itemStyle={itemStyle}
    $variant={data.variant ?? variant}
  >
    {data.name}
  </TagListItemNone>
);

const TagClickable: React.FC<
  CommonTagProps & {
    onClick: (data: TagData, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  }
> = ({
  data,
  itemStyle = 'default',
  onClick,
  variant,
  shadow = false,
  disabled: parentDisabled,
  onTouchStart,
  onTouchEnd,
}) => {
  const disabled = data.disabled || parentDisabled;
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    onClick(data, e);
  };
  return (
    <TagListItemClickable
      $highlighted={data.highlighted ?? false}
      $shadow={shadow}
      data-testid={data.testId}
      $itemStyle={itemStyle}
      $variant={data.variant ?? variant}
    >
      <TagButton
        type="button"
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        disabled={disabled}
        onClick={handleClick}
        $variant={data.variant ?? variant}
      >
        <IconWrapper>
          {data.selected && <Icons.Check $size="xxs" />}
          <span>
            <Highlighter highlight={data.highlight}>{data.name}</Highlighter>
          </span>
        </IconWrapper>
      </TagButton>
    </TagListItemClickable>
  );
};

export type TagData = {
  id: string;
  highlighted?: boolean;
  icon?: React.ReactNode;
  testId?: string;
  name: string;
  disabled?: boolean;
  variant?: 'primary' | 'secondary' | 'dark' | 'purple' | 'prAssistant';
  selected?: boolean;
  isFixed?: boolean;
  highlight?: string;
};
export type ItemStyle = 'stretched' | 'stacked' | 'default';

type CommonProps = {
  disabled?: boolean;
  itemStyle?: ItemStyle;
  onInteraction: (data: TagData) => void;
  onTouchStart?: (event: React.TouchEvent<HTMLButtonElement>) => void;
  onTouchEnd?: (event: React.TouchEvent<HTMLButtonElement>) => void;
  data: TagData;
  shadow?: boolean;
  variant?: 'primary' | 'secondary' | 'dark' | 'purple' | 'prAssistant';
};

type DismissProps = CommonProps & {
  interactionType?: 'dismiss';
  dismissAriaLabel?: string;
};

type ClickProps = CommonProps & {
  interactionType: 'click';
  dismissAriaLabel?: undefined;
};

type NoneProps = CommonProps & {
  interactionType: 'none';
};

type TagProps = DismissProps | ClickProps | NoneProps;

const Tag: React.FC<TagProps> = props => {
  switch (props.interactionType) {
    case 'click':
      return <TagClickable onClick={props.onInteraction} {...props} />;
    case 'none':
      return <TagNone {...props} />;
  }
  return <TagDismissible onDismiss={props.onInteraction} {...props} />;
};

export default Tag;
