import { getCookie } from '@mnd-frontend/utils';
import { useEffect, useState } from 'react';

const getFeatureCookieValue = (name: string): boolean => {
  let featureCookieValue: { [key: string]: string } = {};
  try {
    featureCookieValue = JSON.parse(getCookie('feature_toggles') ?? '{}');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  return !!featureCookieValue[name];
};

export const useFeatureCookie = (name: string): boolean => {
  const [enabled, setEnabled] = useState(getFeatureCookieValue(name));

  useEffect(() => {
    setEnabled(getFeatureCookieValue(name));
  }, [name]);

  return enabled;
};
