import styled from 'styled-components';
import { GREY_1000 } from '../../theme';

const Link = styled.a`
  text-decoration: none;
  color: ${GREY_1000};
  cursor: pointer;

  &:hover {
    color: ${GREY_1000};
  }
`;

export default Link;
