import { UntypedFunction } from '@mnd-frontend/ts';
import React from 'react';
import styled, { css } from 'styled-components';
import { GREY_1000, GREY_400, TEXT_FONT } from '../../theme';
import { panelLoadingCss } from '../Panel';
import Item, { ResourceListItemType } from './Item';

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ResourceListWrapper = styled.div<{ $hasMinHeight: boolean; $loading?: boolean }>`
  font-family: ${TEXT_FONT};
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.25;
  position: relative;
  ${({ $loading }) => $loading && panelLoadingCss};
  ${({ $hasMinHeight }) =>
    $hasMinHeight &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 18.125rem;
    `};

  color: ${GREY_1000};

  & + & > ${List} {
    border-top: 1px solid ${GREY_400};
  }
`;

const ResourceList = <T extends ResourceListItemType>({
  items,
  isLoading = false,
  empty,
  renderItem,
  listItemTestId,
  getCheckedState,
  isDisabled,
  ...props
}: {
  items?: T[];
  isLoading?: boolean;
  empty?: React.ReactNode;
  renderItem?: React.FC<any>;
  listItemTestId?: string;
  onChangeChecked?: UntypedFunction;
  variant?: string;
  isDisabled?: (item: T) => boolean;
  getCheckedState?: (item: T) => undefined | 'checked' | 'mixed' | 'unchecked';
  inline?: boolean;
}) => {
  const isEmpty = !(items && items.length > 0);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ItemComponent = (renderItem || Item) as React.FC<any>;
  return (
    <ResourceListWrapper
      $hasMinHeight={isEmpty}
      $loading={isLoading}
      aria-label={isLoading ? 'Loading...' : undefined}
    >
      {isEmpty && !isLoading ? (
        empty
      ) : (
        <List>
          {items?.map((item, index) => (
            <ItemComponent
              key={item.id || index}
              listItemTestId={listItemTestId}
              item={item}
              disabled={isDisabled?.(item)}
              checkedState={getCheckedState?.(item)}
              {...props}
            />
          ))}
        </List>
      )}
    </ResourceListWrapper>
  );
};

ResourceList.Item = Item;

export default ResourceList;
