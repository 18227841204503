import { components, defaultTheme } from 'react-select';
import styled, { css } from 'styled-components';
import { GREY_1000, GREY_200 } from '../../../../theme';

type Props = Parameters<typeof components.Option>[number];

const Option = (props: Props) => <components.Option {...props} theme={defaultTheme} />;

const StyledOption: any = styled(Option)`
  && {
    color: ${GREY_1000};
    cursor: pointer;
    padding: 0.5rem 1rem;
    line-height: 1.5rem;
    font-size: 0.875rem;
    border-radius: 0.25rem;
    background-color: ${props => (props.isFocused ? GREY_200 : 'transparent')};
    font-weight: inherit;

    ${props =>
      props.isDisabled &&
      css`
        cursor: not-allowed;
        opacity: 0.8;
      `}

    &:active {
      background-color: ${GREY_200};
    }
  }
`;

export default StyledOption;
