import styled from 'styled-components';

const Content = styled.section`
  margin: 4rem auto 5rem;
  max-width: var(--page-width);
  width: calc(100vw - 2rem);
  height: 100%;
`;

export default Content;
