import { useCallback } from 'react';
import { parsePath } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useNavigate, useTranslation } from '../../contexts/UIContext';
import { FOCUS_SHADOW, GREY_100, GREY_400, PURPLE_700 } from '../../theme';
import { Spinner } from '../Spinner';

const ContentListItemWrapper = styled.li<{ $loading?: boolean; $disabled: boolean }>`
  ${({ $loading }) =>
    $loading &&
    css`
      pointer-events: none;
    `}
  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
      opacity: 0.6;
    `}
  display: flex;
  padding: 1.5rem;
  align-items: center;
  cursor: pointer;
  margin: 0;
  &:hover {
    background: ${GREY_100};
  }

  [data-whatintent='keyboard'] & a:focus {
    text-decoration: underline;
  }

  &:focus,
  &:focus-within {
    outline: none;
    z-index: 1;
    position: relative;
    border-color: ${PURPLE_700};
    box-shadow: ${FOCUS_SHADOW};
  }
  & + & {
    border-top: 1px solid ${GREY_400};
  }
`;

const ContentListItem = <T extends IContentListItem>({
  item,
  disabled,
  loading,
  url,
  onItemClick,
  children,
}: {
  item: T;
  disabled: boolean;
  loading?: boolean;
  url: string;
  onItemClick?: (item: T) => void;
  children: React.ReactNode;
}) => {
  const pathname = url;
  const navigate = useNavigate();
  const href = pathname;
  const handleLink = useCallback(
    (event: any) => {
      const { target, type, key } = event;
      if (['INPUT', 'BUTTON'].includes(target.tagName) || target.role === 'menuitem') {
        return;
      }
      if (type === 'click' || key === 'Enter') {
        event.preventDefault();
        onItemClick?.(item);
        const location = parsePath(pathname);
        if (/^https?:\/\//.test(pathname)) {
          window.open(pathname, '_blank');
        } else if (navigate) {
          navigate(location);
        } else {
          // eslint-disable-next-line no-restricted-syntax
          for (const [key, val] of Object.entries(location)) {
            if (val) {
              (window as any).location[key] = val;
            }
          }
        }
      }
    },
    [navigate, pathname],
  );
  return (
    <ContentListItemWrapper
      onClick={handleLink}
      onKeyDown={handleLink}
      data-href={href}
      $disabled={disabled}
      $loading={loading}
      title={item.header ?? undefined}
      tabIndex={0}
    >
      {children}
    </ContentListItemWrapper>
  );
};

const ContentListCollection = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ContentListWrapper = styled.div<{ $empty?: boolean; $loading?: boolean }>`
  position: relative;
  transition: opacity 250ms cubic-bezier(0.25, 0.55, 0.3, 1) 0;
  transition-delay: 400ms;
  ${({ $empty, $loading }) =>
    $empty &&
    !$loading &&
    css`
      min-height: 15.125rem;
      display: flex;
      align-items: center;
      justify-content: center;
    `}
  ${({ $empty, $loading }) =>
    !$empty &&
    $loading &&
    css`
      opacity: 0.5;
    `}
  ${({ $empty, $loading }) =>
    $empty &&
    $loading &&
    css`
      min-height: 15.125rem;
      opacity: 0.5;
      display: flex;
      align-items: center;
      justify-content: center;
    `}
`;

interface IContentListItem {
  header?: string | null;
  id: string;
}

const Untitled = () => {
  const { t } = useTranslation();
  return <>{t('common_untitled')}</>;
};

interface ContentListProps<T> {
  /** Function to render each list item */
  children?: (item: T, url: string) => JSX.Element | string;
  empty?: JSX.Element;
  generateItemUrl?: (item: T) => string;
  disabled?: boolean;
  loading?: boolean;
  items: T[];
  onItemClick?: (item: T) => void;
}

const ContentList = <T extends IContentListItem>({
  children = (item: IContentListItem) => item.header || <Untitled />,
  empty,
  generateItemUrl = (item: IContentListItem) => `/${item.id}`,
  onItemClick,
  disabled = false,
  loading,
  items,
  ...props
}: ContentListProps<T>) => {
  const hasNoItems = !items.length;
  return (
    <ContentListWrapper $loading={loading} $empty={hasNoItems} {...props}>
      {!hasNoItems && (
        <ContentListCollection>
          {items.map(item => {
            const url = generateItemUrl(item);
            return (
              <ContentListItem
                key={item.id}
                item={item}
                disabled={disabled}
                loading={loading}
                url={url}
                onItemClick={onItemClick}
              >
                {children(item, url)}
              </ContentListItem>
            );
          })}
        </ContentListCollection>
      )}
      {hasNoItems && !loading && empty}
      {hasNoItems && loading && <Spinner size="xxl" />}
    </ContentListWrapper>
  );
};

export default ContentList;
