import React, { ReactNode, createRef, forwardRef, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { useTranslation } from '../../contexts/UIContext';
import {
  DESKTOP_TOAST_HORIZONTAL_GAP,
  DESKTOP_TOAST_HORIZONTAL_PADDING,
  DESKTOP_TOAST_VERTICAL_PADDING,
  OVERLAY_SHADOW,
} from '../../theme';
import { Button } from '../Button';
import Icons from '../Icons';
import { Spinner } from '../Spinner';
import { Text } from '../Text';
import { getPortalContainer } from '../utils/getPortalContainer';
import media from '../utils/media';

type Type = 'SUCCESS' | 'ERROR' | 'WARNING' | 'LOADING';

const Action = styled.span`
  max-width: 50%;
`;

const IconWrapper = styled.span`
  padding: ${DESKTOP_TOAST_VERTICAL_PADDING} 0;

  flex-shrink: 0;
  display: flex;
  align-items: center;
`;

const StyledToaster = styled.div<{ $type: Type }>`
  display: flex;
  gap: ${DESKTOP_TOAST_HORIZONTAL_GAP};
  padding: 0 ${DESKTOP_TOAST_HORIZONTAL_PADDING};
  align-items: center;
  background-color: ${props => {
    switch (props.$type) {
      case 'SUCCESS':
        return props.theme.colorBackgroundToastSuccess;
      case 'ERROR':
        return props.theme.colorBackgroundToastError;
      case 'WARNING':
        return props.theme.colorBackgroundToastWarning;
      case 'LOADING':
        return props.theme.colorBackgroundToastLoading;
    }
  }};

  > p {
    padding: ${DESKTOP_TOAST_VERTICAL_PADDING} 0;
    flex: 1;
    color: ${props => {
      switch (props.$type) {
        case 'SUCCESS':
          return props.theme.colorTextToastTextSuccess;
        case 'ERROR':
          return props.theme.colorTextToastTextError;
        case 'WARNING':
          return props.theme.colorTextToastTextWarning;
        case 'LOADING':
          return props.theme.colorTextToastTextLoading;
      }
    }};
  }
  border-radius: ${({ theme }) => theme.radiusToast};
  box-shadow: ${OVERLAY_SHADOW};

  min-width: ${({ theme }) => theme.sizingToastWidth};
  ${media.lessThan('mobile')} {
    max-width: 90vw;
    min-width: auto;
  }
  overflow: hidden;
  ${IconWrapper} {
    color: ${props => {
      switch (props.$type) {
        case 'SUCCESS':
          return props.theme.colorIconsSuccess;
        case 'WARNING':
          return props.theme.colorIconsWarning;
        case 'ERROR':
          return props.theme.colorIconsDanger;
      }
      return undefined;
    }};
  }
`;

const StyledToasts = styled(TransitionGroup)`
  position: fixed;
  left: 1rem;
  bottom: 1rem;
  z-index: 9999;

  > * {
    margin-top: 1rem;
  }

  .enter {
    opacity: 0.01;
    margin-bottom: -2.5rem;
  }

  .enter-active {
    opacity: 1;
    margin-bottom: 0;
    transition: all 250ms cubic-bezier(0.25, 0.55, 0.3, 1);
  }

  .exit {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  .exit-active {
    opacity: 0.01;
    transform: translate3d(-436px, 0, 0);
    transition: all 500ms cubic-bezier(0.25, 0.55, 0.3, 1);
  }
`;

export type Toast = {
  id: string;
  type: Type;
  message: string;
  icon?: ReactNode;
  action?: {
    text: string;
    callback: () => void;
  };
  onClose: () => void;
};

export const ToasterItem = forwardRef<HTMLDivElement, Omit<Toast, 'id'>>(
  ({ type, message, icon, action, onClose }, ref) => {
    const { t } = useTranslation();

    return (
      <StyledToaster $type={type} ref={ref}>
        {icon ?? (
          <IconWrapper>
            {type === 'LOADING' ? (
              <Spinner size="sm" />
            ) : type === 'SUCCESS' ? (
              <Icons.CircleCheck />
            ) : type === 'WARNING' ? (
              <Icons.TriangleWarning />
            ) : (
              <Icons.CircleError />
            )}
          </IconWrapper>
        )}
        <Text as="p" variant="body">
          {message}
        </Text>
        {action ? (
          <Action>
            <Button configuration="text" onClick={action.callback} type="button">
              {action.text}
            </Button>
          </Action>
        ) : type !== 'LOADING' ? (
          <Button
            configuration="text"
            type="button"
            icon={<Icons.Close />}
            inline
            aria-label={t('common_close')}
            onClick={onClose}
          />
        ) : null}
      </StyledToaster>
    );
  },
);

const clearTimeouts = (timers: { [id: string]: number }) => {
  Object.values(timers).forEach(timer => window.clearTimeout(timer));
};

const Toaster: React.FC<{
  toasts: Toast[];
  onRemove: ({ id }: { id: string }) => void;
  timeout?: number;
}> = ({ toasts, onRemove, timeout = 10000 }) => {
  const timersSetRef = useRef<{ [id: string]: number }>({});

  const removeToastRef = useRef(onRemove);
  useEffect(() => {
    removeToastRef.current = onRemove;
  });

  useEffect(() => {
    const timers = timersSetRef.current;

    const updateTimers = (toast: Toast) => {
      if (timers[toast.id] || toast.type === 'LOADING') {
        return;
      }

      timers[toast.id] = window.setTimeout(() => {
        removeToastRef.current(toast);
        delete timers[toast.id];
      }, timeout);
    };
    toasts.forEach(updateTimers);
  }, [toasts, timeout]);

  useEffect(
    () => () => {
      clearTimeouts(timersSetRef.current);
    },
    [],
  );

  const el = getPortalContainer('toaster', []);
  if (!el) return <StyledToasts appear />;

  return ReactDOM.createPortal(
    <StyledToasts appear>
      {toasts.map(toast => {
        const toastRef = createRef<HTMLDivElement>();
        return (
          <CSSTransition key={toast.id} timeout={250} nodeRef={toastRef}>
            <ToasterItem
              type={toast.type}
              icon={toast.icon}
              message={toast.message}
              action={toast.action}
              onClose={() => removeToastRef.current(toast)}
              ref={toastRef}
            />
          </CSSTransition>
        );
      })}
    </StyledToasts>,
    el,
  );
};

export default Toaster;
