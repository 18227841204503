import styled, { css, keyframes } from 'styled-components';
import { GREY_400, NEUTRAL_WHITE, SHADOW_2 } from '../../theme';

const slide = keyframes`
  0% {
    /* ui components will be  constrained the 960grid so 1440px will be safe*/
    background-position: -10rem 0;
  }
  85% {
    background-position: 100rem 0;
  }
  100% {
    background-position: 110rem 0;
  }
`;

const fadein = keyframes`
    0% { opacity: 0; }
    100% {opacity: 1; }
`;

export const panelLoadingCss = css`
  user-select: none;
  &:after {
    animation:
      ${slide} 3s linear infinite,
      ${fadein} 0.5s cubic-bezier(0.25, 0.55, 0.3, 1);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.75),
      rgba(255, 255, 255, 0)
    );

    background-color: rgba(255, 255, 255, 0.65);
    background-repeat: no-repeat;
    background-size: 10rem 100%;
    background-position: -10rem 0;
    background-position: 50px 0;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 5;
  }
`;

export type PanelProps = {
  $loading?: boolean;
  disabled?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const Panel = styled.div.attrs<PanelProps>(props => ({
  'aria-busy': props.$loading ? 'true' : props.$loading === false ? 'false' : undefined,
}))<PanelProps>`
  position: relative;
  background-color: ${NEUTRAL_WHITE};
  --current-background: ${NEUTRAL_WHITE};
  box-shadow: ${SHADOW_2};

  @media print {
    box-shadow: none;
    border: 1px solid ${GREY_400};
  }
  border-radius: 0.25rem;

  ${({ disabled }) =>
    disabled &&
    css`
      user-select: none;
      pointer-events: none;
    `}
  ${({ $loading }) => $loading && panelLoadingCss}
`;

export default Panel;
