import React from 'react';
import styled, { css } from 'styled-components';
import { Alert } from '../..';
import { GREY_400 } from '../../../theme';
import { H3, P } from '../../Typo';

type HeaderProps = {
  /** Title of the card * */
  title?: string | React.ReactNode;

  /** Description of the card content * */
  description?: string | React.ReactNode;

  /** Experimental * */
  action?: React.ReactNode;

  className?: string;

  disabledInfo?: string | React.ReactNode;
};

const Layout = styled.div`
  display: flex;
  align-items: center;
`;

const ActionWrapper = styled.div`
  margin-left: auto;

  & > i {
    width: 45px;
    height: auto;
  }
`;

const HeaderComponent = ({ title, description, action, className, disabledInfo }: HeaderProps) => {
  return (
    <div className={className}>
      <Layout>
        {title && <H3>{title}</H3>}
        {action && <ActionWrapper>{action}</ActionWrapper>}
      </Layout>
      {description && (
        <P $subdued as={typeof description === 'string' ? undefined : 'div'}>
          {description}
        </P>
      )}

      {disabledInfo && <Alert type="info">{disabledInfo}</Alert>}
    </div>
  );
};

const Header = styled(HeaderComponent)<{ $tableCard?: boolean }>`
  padding: 1rem 1.5rem;
  ${({ $tableCard }) =>
    !$tableCard &&
    css`
      &:not(:last-child) {
        border-bottom: 1px solid ${GREY_400};
      }
    `}

  & > * + * {
    margin-top: 0.5rem;
  }
`;

export default Header;
