import styled from 'styled-components';
import { GREY_1000, GREY_800, RED_700 } from '../../theme';

type TabItemProps = {
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  selected: boolean;
};

const InnerTabItem = ({ children, onClick, selected, ...props }: TabItemProps) => (
  <button
    role="tab"
    type="button"
    onClick={onClick}
    {...props}
    aria-current={selected || undefined}
  >
    {children}
  </button>
);

export const TabItem = styled(InnerTabItem)`
  color: ${GREY_800};
  padding: 1rem 0rem;
  position: relative;
  border: none;
  background: none;
  text-decoration: none;
  width: 100%;

  &:hover {
    color: ${GREY_1000};
  }

  &[aria-current] {
    color: ${RED_700};
  }

  &[aria-current]:after {
    border-bottom: 4px solid ${RED_700};
    content: '';
    display: block;
    height: 100%;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;
