export default function isOutOfViewport(element: HTMLElement) {
  const bounding = element.getBoundingClientRect();
  const windowWidth = window.innerWidth || document.documentElement.clientWidth;
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;

  const top = bounding.top < 0;
  const left = bounding.left < 0;
  const bottom = bounding.bottom > windowHeight;
  const right = bounding.right > windowWidth;

  return {
    top,
    left,
    bottom,
    right,
    any: top || left || bottom || right,
    all: top && left && bottom && right,
    horizontalOutOfBounds:
      bounding.width > windowWidth ||
      (right && bounding.left - bounding.width < 0) ||
      (left && bounding.right + bounding.width > windowWidth),
    verticalOutOfBounds:
      bounding.height > windowHeight ||
      (top && bounding.top - bounding.width < 0) ||
      (bottom && bounding.bottom + bounding.bottom > windowHeight),
  };
}
