import React, { MouseEvent, ReactElement } from 'react';
import styled from 'styled-components';
import { useNavigate } from '../../../contexts/UIContext';
import { Button } from '../../Button';
import Icons from '../../Icons';
import { Text } from '../../Text';
import { media } from '../../utils/media';

const HeaderStyles = styled.header<{ $withAdditionalMargin?: boolean }>`
  margin-bottom: ${props => props.$withAdditionalMargin && '3rem'};
`;

const Body = styled.div`
  margin-top: 1rem;
  ${media.lessThan('mobile')} {
    & > * {
      width: 100%;
    }
  }
`;

const Description = styled.div`
  margin-top: 1rem;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;

  max-width: 100%;
  ${media.greaterThan('tablet')} {
    max-width: 75%;
  }
`;

interface HeaderCommonProps {
  titleElement?: ReactElement;
  className?: string;
  children?: React.ReactNode;
  title?: React.ReactNode;
  subtitle?: string;
  description?: string | ReactElement;
  withAdditionalMargin?: boolean;
  breadcrumb?: string;
}

interface BreadcrumbLink {
  breadcrumbTo?: string;
  breadcrumbIgnoreRouter?: boolean;
}

interface BreadcrumbButton {
  breadcrumbOnClick: (e: MouseEvent<HTMLButtonElement>) => void;
}

type HeaderProps = HeaderCommonProps & (BreadcrumbButton | BreadcrumbLink);

const Header = styled(
  React.forwardRef<HTMLDivElement, HeaderProps>((props: HeaderProps, ref) => {
    const {
      title,
      titleElement,
      subtitle,
      description,
      children,
      className,
      withAdditionalMargin,
    } = props;
    const navigate = useNavigate();
    return (
      <HeaderStyles className={className} ref={ref} $withAdditionalMargin={withAdditionalMargin}>
        {props.breadcrumb &&
          ('breadcrumbTo' in props && props.breadcrumbTo ? (
            <Button
              href={props.breadcrumbTo}
              configuration="text"
              onClick={event => {
                if (props.breadcrumbTo && !props.breadcrumbIgnoreRouter) {
                  event.preventDefault();
                  navigate(props.breadcrumbTo);
                }
              }}
              leadingIcon={<Icons.ChevronLeft />}
            >
              {props.breadcrumb}
            </Button>
          ) : 'breadcrumbOnClick' in props ? (
            <Button
              type="button"
              configuration="text"
              onClick={props.breadcrumbOnClick}
              leadingIcon={<Icons.ChevronLeft />}
            >
              {props.breadcrumb}
            </Button>
          ) : null)}

        {titleElement ??
          (title ? (
            <Text as="h1" variant="headlineLarge" marginTop="SPACING_3">
              {title}
            </Text>
          ) : null)}
        {subtitle && (
          <Text as="p" variant="subheading" marginTop="SPACING_3">
            {subtitle}
          </Text>
        )}
        {description && (
          <Description>
            {typeof description === 'string' && description.trim() && <p>{description}</p>}
            {typeof description !== 'string' && description}
          </Description>
        )}
        {children && <Body>{children}</Body>}
      </HeaderStyles>
    );
  }),
)``;

export default Header;
