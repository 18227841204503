/**
 * This module is needed because of how Apollo is implemented in certain versions.
 *
 * The concrete bug that appears without this is in contact lists. What happens is the following:
 *
 * 1. User adds a contact.
 * 2. We try to clear all references to contact lists, as it is very hard to know where to append it
 *  due to filtering, searching, etc.
 * 3. Apollo notices missing data in a currently rendered query, and uses a read function in the cache to
 *  try to get the missing data.
 * 4. The read function fetches the normalized entry, ruining step 2.
 *
 * We want to keep read functions as they can provide great ux and isolated components, but we also want to
 * be able to clear stuff.
 *
 * I will buy a beer to the smart developer who can remove this module without sacrificing the functionality.
 */

type BlockableFunctions = 'Pressroom.networkContactLists';

const currentBlockingFunctions = new Set<BlockableFunctions>();

export const blockApolloReadFunction = (fn: BlockableFunctions | BlockableFunctions[]) => {
  const fns = Array.isArray(fn) ? fn : [fn];
  fns.forEach(f => currentBlockingFunctions.add(f));
};

export const isApolloReadFunctionBlocked = (fn: BlockableFunctions) => {
  return currentBlockingFunctions.has(fn);
};

export const unblockApolloReadFunction = (fn: BlockableFunctions | BlockableFunctions[]) => {
  const fns = Array.isArray(fn) ? fn : [fn];
  fns.forEach(f => currentBlockingFunctions.delete(f));
};
