import styled, { css } from 'styled-components';
import { SIZING_3, SPACING_1, SPACING_2 } from '../../theme';
import {
  GREY_300,
  GREY_400,
  GREY_800,
  GREY_900,
  TEAL_200,
  TEAL_300,
  TEAL_700,
} from '../../theme/colors';
import Icons from '../Icons';

const CHECKICON_SIZE = SIZING_3;
const GAP = SPACING_1;

const Wrapper = styled.div<{ $active?: boolean; $copilot?: boolean; $readOnly?: boolean }>`
  display: inline-flex;
  align-items: center;
  background: ${({ $copilot }) => ($copilot ? TEAL_200 : GREY_300)};
  border-radius: 22.5rem;
  line-height: ${SIZING_3};
  color: ${({ $copilot }) => ($copilot ? TEAL_700 : GREY_900)};
  font-weight: 500;
  gap: ${GAP};
  padding: ${SPACING_1} ${SPACING_2};
  outline: none;
  cursor: default;
  ${({ $readOnly, $copilot }) =>
    !$readOnly &&
    css`
      &:hover {
        cursor: pointer;
        background: ${$copilot ? TEAL_300 : GREY_400};
      }
    `}
  ${({ $active }) =>
    $active &&
    css`
      outline: 2px solid ${GREY_800};
    `};
`;

const IconWrapper = styled.div<{ $visible?: boolean }>`
  display: grid;
  transition:
    width 0.2s ease-in-out,
    margin-left 0.2s ease-in-out;
  overflow: hidden;
  width: ${({ $visible }) => ($visible ? CHECKICON_SIZE : 0)};
  margin-left: ${({ $visible }) => ($visible ? 0 : `-${GAP}`)};
`;

const CloseButton = styled.button`
  display: grid;
  background: none;
  border: none;
  padding: 0;
`;

type Filter = {
  type: 'filter';
  onClick: () => void;
  active: boolean;
};

type Selection = {
  type: 'selection';
  onClose: (e?: React.MouseEvent<HTMLDivElement>) => void;
};

type Suggestion = {
  type: 'suggestion';
  onClick?: () => void;
};

type ReadOnly = {
  type: 'readOnly';
};

type ChipProps = {
  children: React.ReactNode;
  copilot?: boolean;
} & (Filter | Selection | Suggestion | ReadOnly);

const Chip: React.FC<ChipProps> = ({ children, type, copilot, ...props }) => {
  const { onClick, onClose, active } = props as Filter & Selection & Suggestion;

  return (
    <Wrapper
      onClick={type === 'filter' || type === 'suggestion' ? onClick : undefined}
      $active={type === 'filter' && active}
      $copilot={copilot}
      $readOnly={type === 'readOnly'}
    >
      <span>{children}</span>
      {type === 'selection' && onClose && (
        <CloseButton type="button" onClick={onClose}>
          <Icons.Close $size="xxs" />
        </CloseButton>
      )}
      {type === 'filter' && (
        <IconWrapper $visible={active}>
          <Icons.Check $size="xs" />
        </IconWrapper>
      )}
    </Wrapper>
  );
};

export default Chip;
