const numberOfDigits = (number: number | bigint | string): number =>
  typeof number === 'string'
    ? number.length
    : typeof number === 'bigint'
      ? String(number).length
      : Math.floor(Math.log10(number)) + 1;

function bigIntDivision(numerator: bigint, denominator: bigint): bigint {
  const remainder = numerator % denominator;
  if (remainder === BigInt(0)) {
    return numerator / denominator;
  }
  const halfDenominator = denominator / BigInt(2);
  const quotient = numerator / denominator;
  const res = remainder < halfDenominator ? quotient : quotient + BigInt(1);
  return res;
}

function abbreviate(number: number | bigint): [number | bigint, string] {
  const digits = numberOfDigits(number);
  const useMillions = digits > 7;
  const useThousands = digits > 4;

  const divisor = useMillions ? 1000000 : useThousands ? 1000 : 1;
  return [
    typeof number === 'bigint' ? bigIntDivision(number, BigInt(divisor)) : number / divisor,
    useMillions ? 'M' : useThousands ? 'k' : '',
  ];
}

export const localizeValue = (
  value: number | bigint | string,
  options: {
    abbrev?: boolean;
    percentage?: boolean;
    precision?: BigIntToLocaleStringOptions['maximumFractionDigits'];
    minPrecision?: BigIntToLocaleStringOptions['minimumFractionDigits'];
    autoAbbrev?: boolean;
    locale?: Intl.LocalesArgument;
  } = {},
): string | number => {
  if (options.autoAbbrev && numberOfDigits(value) > 7) {
    options.abbrev = true;
  }
  let number = typeof value === 'bigint' ? value : Number(value);

  if (typeof value !== 'bigint' && typeof number !== 'bigint' && !Number.isFinite(number)) {
    return value;
  }

  const formatOptions: BigIntToLocaleStringOptions = {};
  let suffix = '';

  if (options.percentage === true) {
    number = Number(number) / 100;
    formatOptions.style = 'percent';
    formatOptions.minimumFractionDigits = 0;
    formatOptions.maximumFractionDigits = 20;
  }

  if (options.abbrev === true) {
    [number, suffix] = abbreviate(number);
    formatOptions.minimumFractionDigits = 0;
    formatOptions.maximumFractionDigits = 0;
  }

  if (options.precision !== undefined) {
    formatOptions.minimumFractionDigits = options.minPrecision ?? options.precision;
    formatOptions.maximumFractionDigits = options.precision;
  }
  return typeof number === 'bigint'
    ? number.toLocaleString(options.locale, formatOptions) + suffix
    : number.toLocaleString(options.locale, formatOptions as Intl.NumberFormatOptions) + suffix;
};
