import { getCookie, removeCookie, setCookie } from '@mnd-frontend/utils';
import { parse } from 'qs';
import { useEffect } from 'react';

export const useStoreFeatureCookie = ({ queryString }: { queryString: string }) => {
  useEffect(() => {
    const { FEATURE_TOGGLE, ft } = parse(queryString, { ignoreQueryPrefix: true });
    const overrides = FEATURE_TOGGLE ?? ft;

    if (overrides === undefined || typeof overrides !== 'string') {
      return;
    }

    if (overrides === '' || overrides === 'false') {
      removeCookie('feature_toggles');
    } else {
      const newOverrides: { [key: string]: boolean } = {};
      overrides.split(',').forEach(override => {
        const [key, value] = override.split(':');
        newOverrides[key] = value !== 'false';
      });

      let currentOverrides = {};
      try {
        currentOverrides = JSON.parse(getCookie('feature_toggles') ?? '{}');
      } catch (error) {
        console.error(error);
      }

      setCookie('feature_toggles', { ...currentOverrides, ...newOverrides });
    }
  }, [queryString]);

  return null;
};
