import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from '../../contexts/UIContext';
import { RADIUS_LG, SPACING_2, SPACING_3 } from '../../theme';
import { Badge } from '../Badge';
import { Button } from '../Button';
import Icons from '../Icons';
import { Text } from '../Text';
import media from '../utils/media';

const BannerWrapper = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colorBackgroundContainerDark};
  border-radius: ${RADIUS_LG};
  flex-direction: column-reverse;
  overflow: hidden;
  position: relative;
  ${media.greaterThan('desktop')} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Content = styled.div`
  display: grid;
  align-content: start;
  color: ${({ theme }) => theme.colorTextBodyOnDark};
  gap: ${SPACING_3};
  padding: ${SPACING_3};
  flex: 2;
`;

const BadgeWrapper = styled.div`
  margin-bottom: ${SPACING_2};
`;

const CloseButton = styled.div`
  position: absolute;
  top: ${SPACING_3};
  right: ${SPACING_3};
  z-index: 1;
  > button {
    background: rgba(79, 78, 101, 0.5);
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  gap: ${SPACING_2};
`;

const ImgWrapper = styled.div`
  overflow: hidden;
  flex: 1;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 16/9;
  }
  ${media.greaterThan('desktop')} {
    transform: skewX(-10deg);
    transform-origin: bottom right;
    img {
      transform: skewX(10deg);
      transform-origin: bottom right;
    }
  }
`;

type InfoBannerProps = {
  title: string | React.ReactNode;
  subtitle: string | React.ReactNode;
  cta?: {
    text: string;
    loading?: boolean;
    disabled?: boolean;
    onClick: () => void;
  };
  secondaryCta?: {
    text: string;
    loading?: boolean;
    disabled?: boolean;
    onClick: () => void;
  };
  image?: string;
  badgeText: string;
  closeBanner?: () => void;
};

const InfoBanner = ({
  title,
  subtitle,
  cta,
  secondaryCta,
  image,
  badgeText,
  closeBanner,
}: InfoBannerProps) => {
  const [closed, setClosed] = useState(false);
  const { t } = useTranslation();

  if (closed) return null;

  return (
    <BannerWrapper>
      <Content>
        <CloseButton>
          <Button
            type="button"
            icon={<Icons.Close />}
            configuration="toned"
            onClick={() => {
              setClosed(true);
              closeBanner?.();
            }}
            aria-label={t('common_close')}
            onDark
          />
        </CloseButton>
        <div>
          <BadgeWrapper>
            <Badge variant="informational" text={badgeText} onDark />
          </BadgeWrapper>
          <Text as="h2" variant="headlineSmall" marginBottom="SPACING_1">
            {title}
          </Text>
          <Text as="p" variant="bodySmall">
            {subtitle}
          </Text>
        </div>
        {(cta || secondaryCta) && (
          <ActionWrapper>
            {cta && (
              <Button
                type="button"
                configuration="filled"
                onClick={cta.onClick}
                loading={!!cta.loading}
                disabled={!!cta.disabled}
                onDark
              >
                {cta.text}
              </Button>
            )}
            {secondaryCta && (
              <Button
                type="button"
                configuration="text"
                onClick={secondaryCta.onClick}
                loading={!!secondaryCta.loading}
                disabled={!!secondaryCta.disabled}
                onDark
              >
                {secondaryCta.text}
              </Button>
            )}
          </ActionWrapper>
        )}
      </Content>
      {image && (
        <ImgWrapper>
          <img src={image} alt="Banner" />
        </ImgWrapper>
      )}
    </BannerWrapper>
  );
};

export default InfoBanner;
