import { useEffect, useState } from 'react';

export const useIsInViewport = (
  ref: React.RefObject<HTMLElement>,
  margin?: string,
  stayTrue?: boolean,
) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    if (!ref.current) return;

    const observer = new IntersectionObserver(
      ([entry]) => setIsIntersecting(i => (stayTrue && i ? i : entry.isIntersecting)),
      margin ? { rootMargin: margin } : undefined,
    );
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, margin, stayTrue]);

  return isIntersecting;
};
