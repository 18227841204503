import { ChannelType, MaterialType } from '@mnd-frontend/graphql-schema';
import { memo } from 'react';

export const toBoolean = <T>(value?: T | null | false): value is T => Boolean(value);

export const toMaterialType = (
  material:
    | 'pressreleases'
    | 'news'
    | 'blog_posts'
    | 'events'
    | 'documents'
    | 'videos'
    | 'images'
    | 'contact_people',
): MaterialType => {
  switch (material) {
    case 'blog_posts': {
      return MaterialType.BlogPost;
    }
    case 'documents': {
      return MaterialType.Document;
    }
    case 'events': {
      return MaterialType.Event;
    }
    case 'images': {
      return MaterialType.Image;
    }
    case 'news': {
      return MaterialType.News;
    }
    case 'pressreleases': {
      return MaterialType.Pressrelease;
    }
    case 'videos': {
      return MaterialType.Video;
    }
    case 'contact_people':
      throw new Error('Tried to classify contact people as material.');
  }
};

export const toChannelType = (
  typename: 'FacebookChannel' | 'LinkedinChannel' | 'YoutubeChannel' | 'TwitterChannel',
): ChannelType => {
  switch (typename) {
    case 'FacebookChannel':
      return ChannelType.Facebook;
    case 'LinkedinChannel':
      return ChannelType.Linkedin;
    case 'YoutubeChannel':
      return ChannelType.YouTube;
    case 'TwitterChannel':
      return ChannelType.Twitter;
  }
};

export const genericMemo: <T>(component: T) => T = memo;

export const assertNever = (x: never): never => {
  throw new Error('Unexpected object: ' + x);
};
export const isValidEnumValue = <T>(
  enumObj: T & Record<string, unknown>,
  value: unknown,
): value is T[keyof T] => {
  return Object.values(enumObj).includes(value);
};
