import styled from 'styled-components';
import { GREY_800 } from '../../theme';

const Attachments = styled.div`
  text-align: right;
  color: ${GREY_800};
  flex-grow: 100;
  flex-basis: 0;
  flex-shrink: 0;
`;

export default Attachments;
