import { components, defaultTheme } from 'react-select';
import styled from 'styled-components';

type Props = Parameters<typeof components.Control>[number];

const Control = (props: Props) => <components.Control {...props} theme={defaultTheme} />;

const StyledControl: any = styled(Control)`
  && {
    background-color: transparent;
    border: none;
    box-shadow: none;
    cursor: pointer;
    border-radius: 0.5rem;

    &:hover {
      cursor: ${({ isDisabled }) => isDisabled && 'not-allowed'};
    }
  }
`;

export default StyledControl;
