import { components } from 'react-select';
import { Strong } from '../../../Typo';
import OptionContainer from './OptionContainer';

const NoOptionsMessage = (props: any) => (
  <OptionContainer $isFocused>
    <components.NoOptionsMessage {...props}>
      <Strong>{props.children}</Strong>
    </components.NoOptionsMessage>
  </OptionContainer>
);

export default NoOptionsMessage;
