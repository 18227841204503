import { useId } from 'react';
import Icons from '../Icons';
import { InputWrapper } from '../Input';
import { Spinner } from '../Spinner';
import { MultiProps, MultiSelect } from './MultiSelect';
import { SingleProps, SingleSelect } from './SingleSelect';

interface CommonSelectProps {
  id?: string;
  label?: string;
  disabled?: boolean;
  skipMargin?: boolean;
  error?: string | null;
  helperText?: string;
  loading?: boolean;
  'data-testid'?: string;
}

export type SelectProps<T> = CommonSelectProps & (MultiProps<T> | SingleProps<T>);

export const Select = <T,>(props: SelectProps<T>) => {
  const labelId = useId();

  const hasContent = props.isMulti
    ? props.value.length > 0
    : props.options.some(option =>
        'options' in option
          ? option.options.some(o => o.value === props.value)
          : option.value === props.value,
      );
  const disabled = props.disabled || props.loading;

  return (
    <InputWrapper
      disabled={disabled}
      label={props.label}
      skipMargin={props.skipMargin}
      hasContent={hasContent}
      helperText={props.helperText}
      error={props.error}
      trailingIcon={props.loading ? <Spinner size="xs" /> : <Icons.ChevronDown />}
      labelId={labelId}
    >
      {props.isMulti ? (
        <MultiSelect {...props} disabled={disabled} hasContent={hasContent} labelId={labelId} />
      ) : (
        <SingleSelect {...props} disabled={disabled} hasContent={hasContent} labelId={labelId} />
      )}
    </InputWrapper>
  );
};
