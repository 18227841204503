import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from '../../contexts/UIContext';
import { AssistanceTheme, SPACING_1 } from '../../theme';
import { Button } from '../Button';
import Icons from '../Icons';
import { Text } from '../Text';

export interface MiniProps {
  onClose?: () => void;
  text: string;
  variant: 'mini';
}

const Wrapper = styled.aside`
  display: grid;
  grid-template-columns: auto 1fr 1.5rem;
  gap: ${SPACING_1};
  background: ${props => props.theme.colorBackgroundAssistanceBannerDefault};
  border-radius: ${props => props.theme.radiusGuideBannerMini};
  align-items: center;
  padding: ${SPACING_1};
  color: ${props => props.theme.colorTextAssistanceBannerBodyDefault};

  p {
    font-size: 0.875rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const IconWrapper = styled.div`
  color: ${props => props.theme.colorIconsAssistanceBannerDefault};
`;

export const Mini = (props: MiniProps) => {
  const [closed, setClosed] = useState(false);
  const { t } = useTranslation();

  if (closed) return null;

  return (
    <AssistanceTheme>
      <Wrapper>
        <IconWrapper>
          <Icons.PrAssistant $size="xs" />
        </IconWrapper>
        <Text as="p" variant="body">
          {props.text}
        </Text>
        <Button
          configuration="text"
          inline
          type="button"
          onClick={() => {
            setClosed(true);
            props.onClose?.();
          }}
          icon={<Icons.Close />}
          aria-label={t('common_close')}
        />
      </Wrapper>
    </AssistanceTheme>
  );
};
