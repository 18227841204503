import { ReactNode } from 'react';
import styled from 'styled-components';
import { SIZING_2, SPACING_1, SPACING_5 } from '../../theme';
import { Button } from '../Button';
import Icons from '../Icons';
import { Text } from '../Text';

const Wrapper = styled.aside`
  background-color: ${props => props.theme.colorBackgroundSystemInfoDefault};
  display: flex;
  gap: ${SPACING_5};
  justify-content: center;
  min-height: ${props => props.theme.sizingSystemInfoHeight};
  position: relative;
  z-index: 2;
`;

const TextPart = styled.div`
  display: flex;
  gap: ${SPACING_1};
  align-items: center;
  color: ${props => props.theme.colorTextBodyOnDark};
  > p {
    padding: ${SIZING_2} 0;
  }
`;

const Body = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.375rem 0 0.75rem; //Match with menu
  width: var(--page-width, '100%');
  max-width: 100%;
  gap: ${SPACING_5};
`;

export const SystemInfo = ({
  children,
  action,
}: {
  children: ReactNode;
  action?: {
    text: string;
    onClick: () => void;
    loading?: boolean;
  };
}) => {
  return (
    <Wrapper>
      <Body>
        <TextPart>
          <Icons.CircleInfo $size="xs" $color="colorIconsOnDark" />
          <Text as="p" variant="body">
            {children}
          </Text>
        </TextPart>
        {action && (
          <Button
            onDark
            type="button"
            configuration="text"
            onClick={action.onClick}
            loading={action.loading}
          >
            {action.text}
          </Button>
        )}
      </Body>
    </Wrapper>
  );
};
