import { toBoolean } from '@mnd-frontend/ts';
import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { GREY_400 } from '../../theme';

const InnerTabNavigation = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  if (React.Children.count(children) === 0) {
    return null;
  }

  return (
    <nav className={className}>
      <ul>
        {React.Children.toArray(children)
          .filter(toBoolean)
          .map((child, index) => (
            <li key={index}>{child}</li>
          ))}
      </ul>
    </nav>
  );
};

export const TabNavigation = styled(InnerTabNavigation)<{ $noBorder?: boolean }>`
  & > ul {
    position: relative;
    margin: 0;
    margin-left: -0.5rem;
    padding: 0;
  }

  ${({ $noBorder }) =>
    !$noBorder &&
    css`
      & > ul:after {
        display: block;
        position: absolute;
        content: '';
        width: calc(100% - 0.5rem);
        height: 1px;
        bottom: 0;
        left: 0.5rem;
        box-shadow: inset 0px -1px 0px 0px ${GREY_400};
      }
    `}

  & > ul > li {
    display: inline-block;
    list-style-type: none;
  }
`;

export const TabNavigationTabs = styled(InnerTabNavigation)<{ $noBorder?: boolean }>`
  width: 100%;
  & > ul {
    position: relative;
    margin: 0;
    padding: 0;
    display: flex;
  }

  ${({ $noBorder }) =>
    !$noBorder &&
    css`
      & > ul:after {
        display: block;
        position: absolute;
        content: '';
        height: 1px;
        bottom: 0;
        box-shadow: inset 0px -1px 0px 0px ${GREY_400};
        width: 100%;
        left: 0;
      }
    `}
  & > ul > li {
    list-style-type: none;
    width: 100%;
  }
`;
