import styled from 'styled-components';
import FlexItem from '../Flex/FlexItem';
import { marginLeft } from './helper';

const percent = (number: number | string) =>
  typeof number === 'number' && number > 0 && `${Math.floor(10000 * Number(number)) / 100}%`;

const parseRelative = (width: string) => {
  const [, a, b] = /^(\d+)\/(\d+)$/.exec(width) || [];
  const [, percent] = /^(\d*\.?\d+)%$/.exec(width) || [];

  return b > '0' && a > '0'
    ? parseInt(a, 10) / parseInt(b, 10)
    : percent
      ? parseInt(percent, 10) / 100
      : width <= '1'
        ? width
        : undefined;
};

const calculateWidth = (gap: number | number[] | undefined, width: string) => {
  const relative = parseRelative(width);
  return relative
    ? gap
      ? `calc(${percent(relative)} - ${marginLeft(gap)}rem)`
      : percent(relative)
    : width;
};

const Item = styled(FlexItem)<{
  $minWidth?: string;
  $basis?: string;
  $grow?: boolean;
  $gap?: number | number[];
}>`
  flex-grow: ${props => (props.$grow ? 1 : undefined)};
  flex-basis: ${props => props.$basis && calculateWidth(props.$gap, props.$basis)};
  height: ${props => !props.children && 0};
  margin-top: ${props => !props.children && 0};
  min-width: ${props => props.$minWidth && calculateWidth(props.$gap, props.$minWidth)};
`;

export default Item;
