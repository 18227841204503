import styled from 'styled-components';
import { AssistanceTheme, SPACING_1, SPACING_2 } from '../../theme';
import { RADIUS_MD } from '../../theme/borderRadiuses';
import { Button } from '../Button';
import Icons from '../Icons';
import { Text } from '../Text';

export interface BoxProps {
  action: {
    onClick: () => void;
    text: string;
    disabled?: boolean;
  };
  text: string;
  variant: 'box';
}

export const Wrapper = styled.aside`
  background: ${props => props.theme.colorBackgroundAssistanceBannerDefault};
  padding: ${SPACING_2};
  border-radius: ${RADIUS_MD};
  display: flex;
  flex-direction: column;
  gap: ${SPACING_2};
  color: ${props => props.theme.colorTextAssistanceBannerBodyDefault};
  max-width: 30rem;
  p {
    font-size: 0.875rem;
  }
`;

const ActionRow = styled.div`
  display: flex;
  gap: ${SPACING_1};
  justify-content: space-between;
  align-items: center;
  color: ${props => props.theme.colorIconsInfo};
`;

export const Box = (props: BoxProps) => {
  return (
    <AssistanceTheme>
      <Wrapper>
        <Text as="p" variant="body">
          {props.text}
        </Text>
        <ActionRow>
          <Button
            configuration="text"
            disabled={props.action.disabled}
            type="button"
            onClick={() => props.action.onClick()}
          >
            {props.action.text}
          </Button>
          <Icons.PrAssistant $size="sm" />
        </ActionRow>
      </Wrapper>
    </AssistanceTheme>
  );
};
