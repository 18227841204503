import { ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import { assistanceTheme } from './assistanceTheme';
import { defaultTheme } from './defaultTheme';

export const AssistanceTheme = ({ children }: { children: ReactNode }) => {
  return <ThemeProvider theme={assistanceTheme}>{children}</ThemeProvider>;
};

export const DefaultTheme = ({ children }: { children: ReactNode }) => {
  return <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>;
};
