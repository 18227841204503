import React from 'react';
import styled from 'styled-components';

const getSize = (props: { $small?: boolean }) => 1.25 + (props.$small ? 1 : 1.5);

const Container = styled.div<{
  $nowrap: boolean;
  $justifyContent: string;
  $grow: boolean;
  $small: boolean;
}>`
  display: flex;
  min-height: calc(${getSize}rem + 2px + 0.75rem);
  margin-top: -0.75rem;
  margin-left: -1rem;
  align-items: center;
  flex-wrap: ${props => (props.$nowrap ? 'nowrap' : 'wrap')};
  justify-content: ${props => props.$justifyContent};

  > * {
    flex-grow: ${props => (props.$grow ? 1 : 0)};
    margin-top: 0.75rem;
    margin-left: 1rem;
  }
`;

const Wrapper = styled.div`
  display: inline-block;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }
`;

type Props = {
  $nowrap?: boolean;
  $justifyContent?: string;
  $grow?: boolean;
  $small?: boolean;
  children?: React.ReactNode;
};
const InputRow: React.FC<Props> = ({
  children,
  $grow = false,
  $nowrap = false,
  $small = false,
  $justifyContent = 'space-between',
  ...props
}) => (
  <Wrapper>
    <Container
      $grow={$grow}
      $nowrap={$nowrap}
      $small={$small}
      $justifyContent={$justifyContent}
      {...props}
    >
      {children}
    </Container>
  </Wrapper>
);

export default InputRow;
