import { GroupBase } from 'react-select';
import Creatable, { CreatableProps } from 'react-select/creatable';
import { BadgeConfigType } from '../../../hooks/useContactsBadgeConfig';
import { defaultComponents, defaultStyles } from '../GenericMultiSelect';
import Control from './Control';
import MultiValue from './MultiValue';
import NoOptionsMessage from './NoOptionsMessage';
import Option from './Option';

const filterOptions = (
  candidate: { label: string; value: string; data: ContactOption },
  input: string,
) => {
  if (input && candidate.label) {
    return (
      candidate.label.toLowerCase().includes(input.toLowerCase()) ||
      Boolean(
        candidate.data.extraData &&
          'email' in candidate.data.extraData &&
          candidate.data.extraData?.email?.toLowerCase()?.includes(input.toLowerCase()),
      )
    );
  }
  return true;
};

const removeStyling = () => ({});

export type ContactOption = {
  label: string;
  value?: any;
  extraData?:
    | {
        type: 'list';
        contactsCount: number;
      }
    | {
        type: 'contact';
        email: string;
        avatar: string;
        contactBadge: BadgeConfigType;
      }
    | Record<string, never>;
};

type Props = CreatableProps<ContactOption, true, GroupBase<ContactOption>> & {
  dismissAriaLabel?: string;
  'aria-label'?: string;
  'aria-labelledby'?: string;
  shadow?: boolean;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  maxLength?: number;
  formatListOptionLabel?: (c: number) => string;
  variant?: 'primary' | 'secondary' | 'dark' | 'purple' | 'prAssistant';
};

const ContactSelect = ({
  filterOption,
  isSearchable,
  menuPortalTarget,
  isValidNewOption,
  ...props
}: Props) => (
  <Creatable<ContactOption, true>
    isMulti
    isValidNewOption={isValidNewOption ?? (() => false)}
    filterOption={filterOption ?? filterOptions}
    menuPortalTarget={menuPortalTarget ?? document.body}
    isSearchable={isSearchable ?? true}
    isClearable={false}
    styles={{
      ...defaultStyles,
      option: removeStyling,
    }}
    components={{
      ...defaultComponents,
      Control,
      MultiValue,
      Option,
      NoOptionsMessage,
    }}
    {...props}
  />
);

export default ContactSelect;
