import styled from 'styled-components';
import { AssistanceTheme, SPACING_1, SPACING_2, SPACING_3 } from '../../theme';
import { Button } from '../Button';
import Icons from '../Icons';
import { panelLoadingCss } from '../Panel';
import { Text, TextProps } from '../Text';

export interface MidiProps {
  action: {
    onClick: () => void;
    disabled?: boolean;
    text: string;
  };
  title: {
    text: string;
    as: TextProps['as'];
  };
  loading?: boolean;
  text: string;
  variant: 'midi';
}

const Wrapper = styled.aside<{ $loading?: boolean }>`
  position: relative;

  > div {
    display: grid;
    grid-template-columns: 3rem 1fr auto;
    gap: ${SPACING_2};
    background: ${props => props.theme.colorBackgroundAssistanceBannerDefault};
    border-radius: ${props => props.theme.radiusGuideBannerMidi};
    align-items: center;
    padding: ${SPACING_1} ${SPACING_3} ${SPACING_1} ${SPACING_1};
    ${({ $loading }) => ($loading ? panelLoadingCss : '')}

    > * {
      min-width: 0;
    }
    > i {
      color: ${props => props.theme.colorIconsAssistanceBannerDefault};
      height: ${props => props.theme.sizingIconMd};
      width: ${props => props.theme.sizingIconMd};
    }
  }
`;

const TextColumn = styled.div`
  white-space: nowrap;
  min-width: 10rem;
  > *:first-child {
    font-weight: 700;
    color: ${props => props.theme.colorTextAssistanceBannerHeadingDefault};
  }
  > p:last-child {
    font-size: 0.875rem;
    color: ${props => props.theme.colorTextAssistanceBannerBodyDefault};
  }
  > * {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const IconWrapper = styled.div`
  color: ${props => props.theme.colorIconsAssistanceBannerDefault};
`;

export const Midi = (props: MidiProps) => {
  return (
    <AssistanceTheme>
      <Wrapper $loading={props.loading}>
        <div>
          <IconWrapper>
            <Icons.PrAssistant $size="md" />
          </IconWrapper>
          <TextColumn>
            <Text as={props.title.as} variant="body">
              {props.title.text}
            </Text>
            <Text as="p" variant="body">
              {props.text}
            </Text>
          </TextColumn>
          <Button
            type="button"
            configuration="text"
            disabled={props.action.disabled}
            onClick={() => props.action.onClick()}
          >
            {props.action.text}
          </Button>
        </div>
      </Wrapper>
    </AssistanceTheme>
  );
};
