import { components } from 'react-select';
import styled from 'styled-components';
import { ContactOption } from '.';
import { NEUTRAL_WHITE, RED_700, SIZING_2 } from '../../../../theme';
import Avatar from '../../../Avatar';
import Icons from '../../../Icons';
import Tooltip from '../../../Tooltip';
import { Light, Strong } from '../../../Typo';
import OptionContainer from './OptionContainer';

const ListName = styled.span`
  align-items: center;
  display: inline-flex;
  gap: 4px;
  ${Icons.CrownFancy} {
    color: ${RED_700};
    margin-right: 4px;
  }
`;

export const EnablePointerEvents = styled.span`
  pointer-events: all;
`;

export const AvatarWrapper = styled.div`
  position: relative;
  // This is a hack to make sure the initials don't inherit styles from DataTable ._.
  > div > span {
    font-size: 1.25rem;!important;
    font-weight: 700;!important
  }
`;

export const AvatarBadge = styled.i<{ $color: string }>`
  background-color: ${({ $color }) => $color};
  border-radius: 100%;
  border: 2px solid ${NEUTRAL_WHITE};
  height: ${SIZING_2};
  width: ${SIZING_2};
  position: absolute;
  right: -2px;
  top: -2px;
  z-index: 1;
`;

const OptionCreateNew = ({ data }: { data: ContactOption }) => (
  <div>
    <Strong>{data.label}</Strong>
  </div>
);

const OptionList = ({ data, selectProps }: { data: ContactOption; selectProps: any }) => {
  if (data.extraData?.type === 'list') {
    return (
      <div>
        <ListName>
          <Strong>{data.label}</Strong>
        </ListName>
        <br />
        <Light>{selectProps.formatListOptionLabel(data.extraData.contactsCount)}</Light>
      </div>
    );
  }
  return null;
};

const OptionContact = ({ data }: { data: ContactOption }) => {
  if (data.extraData?.type === 'contact') {
    return (
      <>
        <Tooltip
          hidden={!data.extraData?.contactBadge}
          content={data.extraData?.contactBadge?.text}
          placement="top"
        >
          <EnablePointerEvents>
            <AvatarWrapper>
              {data.extraData.contactBadge ? (
                <AvatarBadge $color={data.extraData.contactBadge.color} />
              ) : null}
              <Avatar type="contact" name={data.extraData.avatar} />
            </AvatarWrapper>
          </EnablePointerEvents>
        </Tooltip>
        <div>
          <Strong>{data.label}</Strong>
          <br />
          <Light>{data.extraData.email}</Light>
        </div>
      </>
    );
  }
  return null;
};

const OptionEmpty = () => <div />;

const Option = (props: {
  data: ContactOption & { __isNew__?: boolean };
  isFocused: boolean;
  selectProps: any;
}) => {
  const { data } = props;
  const { __isNew__, extraData } = data || {};

  const OptionComponent =
    (__isNew__ && OptionCreateNew) ||
    (extraData?.type === 'list' && OptionList) ||
    (extraData?.type === 'contact' && OptionContact) ||
    OptionEmpty;

  return (
    <components.Option {...(props as any)}>
      <OptionContainer $isFocused={props.isFocused}>
        <OptionComponent {...props} />
      </OptionContainer>
    </components.Option>
  );
};

Option.displayName = 'ContactOption';

export default Option;
