import styled from 'styled-components';

const Article = styled.article`
  margin-bottom: 1.5rem;
  text-align: left;

  &:last-child {
    margin-bottom: 0;
  }
`;

export default Article;
