import { forwardRef, ReactNode, useLayoutEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from '../../contexts/UIContext';
import { GREY_300, SPACING_0_5, SPACING_2, SPACING_3 } from '../../theme';
import * as possibleColors from '../../theme/colors';
import { Badge } from '../Badge';
import { Button } from '../Button';
import Icons from '../Icons';
import { Stepper, StepperProps } from '../Stepper';
import { Text } from '../Text';
import { UiLibCloseButton } from '../UiLibCloseButton';

const Arrow = styled.div<{ $direction: 'up' | 'down' | 'left' | 'right'; $overflowing: boolean }>`
  position: absolute;
  line-height: 0;
  color: ${props => props.theme.colorBackgroundContainerDark};
  ${props => {
    switch (props.$direction) {
      case 'up':
        return css`
          top: 1px;
          left: ${!props.$overflowing && '0.5rem'};
          right: ${props.$overflowing && '0.5rem'};
          transform: translateY(-100%)
            ${props.$overflowing ? 'translateX(-50%)' : 'translateX(50%)'};
        `;
      case 'down':
        return css`
          bottom: 1px;
          left: 0.5rem;
          transform: translateY(100%) translateX(50%);
        `;
      case 'right':
        return css`
          top: 0.5rem;
          right: 1px;
          transform: translateX(100%) translateY(50%);
        `;
      case 'left':
        return css`
          top: 0.5rem;
          left: 1px;
          transform: translateX(-100%) translateY(50%);
        `;
    }
  }}
`;

const Wrapper = styled.div<{
  $direction?: 'up' | 'down' | 'left' | 'right';
  $overflowing: boolean;
}>`
  position: ${props => (props.$direction ? 'absolute' : 'relative')};
  min-width: 25rem;
  max-width: min(70vw, 30rem);
  background-color: ${props => props.theme.colorBackgroundContainerDark};
  color: ${props => props.theme.colorTextBodyOnDark};
  border-radius: ${props => props.theme.radiusGuideBannerMaxi};
  display: flex;
  flex-direction: column;
  z-index: 1;
  gap: ${SPACING_2};
  ${props =>
    props.$direction === 'up'
      ? css`
          left: ${!props.$overflowing && '-2.5rem'};
          right: ${props.$overflowing && '-2.5rem'};
          top: 1rem;
        `
      : props.$direction === 'down'
        ? css`
            left: -2.5rem;
            bottom: 2rem;
          `
        : props.$direction === 'left'
          ? css`
              top: -2.5rem;
              left: 2rem;
            `
          : props.$direction === 'right'
            ? css`
                top: -2.5rem;
                right: 2rem;
              `
            : false}
`;
const PositionRelative = styled.div<{ $zeroSize: boolean }>`
  position: relative;
  ${props =>
    props.$zeroSize &&
    css`
      line-height: 0;
      display: inline;
    `}
`;
const BadgeRow = styled.div`
  padding: ${SPACING_3} ${SPACING_3} 0 ${SPACING_3};
  display: flex;
  justify-content: space-between;
`;

const Body = styled.div<{ $hasActionRow: boolean }>`
  padding: ${props =>
    props.$hasActionRow ? `0 ${SPACING_3}` : `0 ${SPACING_3} ${SPACING_3} ${SPACING_3}`};
  display: flex;
  flex-direction: column;
  gap: ${SPACING_0_5};
  > *:first-child {
    color: ${props => props.theme.colorTextHeadlineOnDark};
  }
  * {
    margin: 0;
  }
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: ${SPACING_3};
  right: ${SPACING_3};
`;

const ActionRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${SPACING_2};
  justify-content: space-between;
  padding: 0 ${SPACING_3} ${SPACING_3} ${SPACING_3};
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  gap: ${SPACING_3};
`;

const MediaWrapper = styled.div`
  aspect-ratio: 16 / 9;
  background-color: ${GREY_300};
  position: relative;
  overflow: hidden;
  border-radius: ${props => props.theme.radiusGuideBannerMaxi}
    ${props => props.theme.radiusGuideBannerMaxi} 0 0;
  > * {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const SingleActionRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 ${SPACING_3} ${SPACING_3} ${SPACING_3};
`;

interface NeedsColor {
  arrowColor: keyof typeof possibleColors;
  media: ReactNode;
  direction: 'up' | 'left' | 'right';
}
interface DoesNotNeedColor {
  arrowColor?: undefined;
  media?: undefined;
  direction?: 'up' | 'down' | 'left' | 'right';
}
interface DoesNotNeedColor2 {
  arrowColor?: undefined;
  media: ReactNode;
  direction?: 'down';
}

interface LinkAction {
  onClick?: () => void;
  href: string;
  newTab?: boolean;
}
interface ButtonAction {
  onClick: () => void;
}

type ConfirmAction = {
  text?: string;
  icon?: ReactNode;
} & (LinkAction | ButtonAction);
type Props = {
  children: ReactNode;
  badgeText: string;
  confirmAction?: ConfirmAction;
  title?: ReactNode;
  onDismiss: () => void;
  media?: ReactNode;
  arrowColor?: keyof typeof possibleColors;
  direction: 'up' | 'down' | 'left' | 'right';
} & (NeedsColor | DoesNotNeedColor | DoesNotNeedColor2) &
  (Pick<StepperProps, 'activeIndex' | 'steps'> | Record<never, never>);

export const Popover = forwardRef<HTMLDivElement, Props>(
  ({ children, badgeText, title, onDismiss, media, arrowColor, direction, ...props }, ref) => {
    const { t } = useTranslation();

    const confirmButton = props.confirmAction ? (
      <Button
        configuration={'steps' in props ? 'filled' : 'text'}
        onDark
        leadingIcon={props.confirmAction.icon}
        {...('href' in props.confirmAction
          ? {
              href: props.confirmAction.href,
              newTab: props.confirmAction.newTab,
              onClick: props.confirmAction.onClick,
            }
          : { onClick: props.confirmAction.onClick, type: 'button' })}
      >
        {props.confirmAction.text || t('common_accept')}
      </Button>
    ) : null;

    const popoverRef = useRef<HTMLDivElement | null>(null);
    const [overflowing, setOverflowing] = useState(false);

    useLayoutEffect(() => {
      if (popoverRef.current) {
        const { right } = popoverRef.current.getBoundingClientRect();
        setOverflowing(direction === 'up' && right > window.innerWidth);
      }
    }, [direction]);

    return (
      <PositionRelative $zeroSize={!!direction}>
        <Wrapper
          $direction={direction}
          ref={el => {
            popoverRef.current = el;
            if (ref) {
              if ('current' in ref) {
                ref.current = el;
              } else {
                ref(el);
              }
            }
          }}
          $overflowing={overflowing}
        >
          {direction && (
            <Arrow
              $overflowing={overflowing}
              $direction={direction}
              style={arrowColor ? { color: possibleColors[arrowColor] } : undefined}
            >
              <svg
                width={['up', 'down'].includes(direction) ? '32' : '11'}
                height={['up', 'down'].includes(direction) ? '11' : '32'}
                viewBox={['up', 'down'].includes(direction) ? '0 0 32 11' : '0 0 11 32'}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d={
                    direction === 'up'
                      ? 'M13.6 0.799999L0 11L32 11L18.4 0.8C16.9778 -0.266666 15.0222 -0.266667 13.6 0.799999Z'
                      : direction === 'down'
                        ? 'M18.4 10.2L32 0L0 0L13.6 10.2C15.0222 11.2667 16.9778 11.2667 18.4 10.2Z'
                        : direction === 'left'
                          ? 'M0.799999 18.4L11 32L11 0L0.8 13.6C-0.266666 15.0222 -0.266667 16.9778 0.799999 18.4Z'
                          : 'M10.2 13.6L0 0L0 32L10.2 18.4C11.2667 16.9778 11.2667 15.0222 10.2 13.6Z' //right
                  }
                  fill="currentColor"
                />
              </svg>
            </Arrow>
          )}

          {media && <MediaWrapper>{media}</MediaWrapper>}
          {
            <CloseButtonWrapper>
              {media ? (
                <UiLibCloseButton onClick={onDismiss} />
              ) : (
                <Button
                  aria-label={t('common_close')}
                  icon={<Icons.Close />}
                  configuration="text"
                  type="button"
                  onClick={onDismiss}
                  inline
                  onDark
                />
              )}
            </CloseButtonWrapper>
          }
          <BadgeRow>
            <Badge variant="informational" text={badgeText} onDark />
          </BadgeRow>
          <Body $hasActionRow={!!props.confirmAction || 'steps' in props}>
            {title && (
              <Text as="div" variant="headlineSmall">
                {title}
              </Text>
            )}
            <Text as="div" variant="bodySmall">
              {children}
            </Text>
          </Body>
          {'steps' in props ? (
            <ActionRow>
              <Stepper
                type="mini"
                steps={props.steps}
                activeIndex={props.activeIndex}
                onDark
                avoidWrap
              />
              <ButtonWrapper>
                {props.activeIndex < props.steps.length - 1 && (
                  <Button configuration="text" type="button" onClick={onDismiss} onDark>
                    {t('common_skip')}
                  </Button>
                )}
                {props.activeIndex >= props.steps.length - 1 ? (
                  confirmButton
                ) : (
                  <Button
                    configuration="filled"
                    type="button"
                    onDark
                    onClick={() => {
                      props.steps[props.activeIndex + 1]?.onClick?.();
                    }}
                  >
                    {t('common_next')}
                  </Button>
                )}
              </ButtonWrapper>
            </ActionRow>
          ) : props.confirmAction ? (
            <SingleActionRow>{confirmButton}</SingleActionRow>
          ) : null}
        </Wrapper>
      </PositionRelative>
    );
  },
);
