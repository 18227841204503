import {
  ChangeEventHandler,
  FocusEventHandler,
  ForwardedRef,
  forwardRef,
  KeyboardEventHandler,
  useEffect,
  useId,
  useMemo,
  useRef,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from '../../contexts/UIContext';
import { GREY_600, RADIUS_SM } from '../../theme';
import { InputWrapper } from '../Input';

const StyledTextarea = styled.textarea<{
  $allowResize?: boolean;
  $hackForDarkBackgroundUseAndBeFired?: true;
}>`
  position: relative;
  z-index: 1;
  background: ${props => (props.$hackForDarkBackgroundUseAndBeFired ? 'white' : 'transparent')};
  border-radius: ${RADIUS_SM};
  border: 0;
  width: 100%;
  outline: 0;
  padding: 0.5rem 0.875rem;
  display: block;
  resize: ${props => (props.$allowResize ? 'vertical' : 'none')};
  &[disabled] {
    color: ${GREY_600};
  }
`;

const CharacterCounterStyles = styled.span<{ $hasError?: boolean }>`
  color: ${props =>
    props.$hasError
      ? props.theme.colorTextTextFieldErrorMessageDefault
      : props.theme.colorTextTextFieldHelperTextDefault};
  font-size: 0.75rem;
  line-height: 1.125rem;
`;

const CharacterCounter = ({
  count,
  maxLength,
  hasError,
}: {
  count: number;
  maxLength: number;
  hasError?: boolean;
}) => {
  const { t } = useTranslation();

  const charactersLeft = maxLength - count;
  const isOverLimit = charactersLeft < 0;
  const charactersOver = isOverLimit ? Math.abs(charactersLeft) : 0;

  return (
    <CharacterCounterStyles $hasError={hasError}>
      {isOverLimit
        ? t('publish_character-counter_too-many-characters', {
            count: charactersOver,
          })
        : t('publish_character-counter_remaining-characters', {
            count: charactersLeft,
          })}
    </CharacterCounterStyles>
  );
};

const TextareaWithRef = (
  {
    id,
    label,
    rows = 3,
    value,
    error,
    helperText,
    disabled,
    skipMargin,
    autoHeight,
    allowResize,
    hackForDarkBackgroundUseAndBeFired,
    allowExceededMaxLength,
    maxLength,
    ...props
  }: {
    label?: string;
    value: string;
    /** Minimum amount of rows is 3 (determined by design) */
    rows?: number;
    onChange?: ChangeEventHandler<HTMLTextAreaElement>;
    id?: string;
    error?: string | null;
    helperText?: string;
    required?: boolean;
    disabled?: boolean;
    autoHeight?: boolean;
    allowResize?: boolean;
    /** Using this means you must guarantee that the label will not overflow content above it  */
    skipMargin?: boolean;
    onKeyDown?: KeyboardEventHandler<HTMLTextAreaElement>;
    /** Inputs does not have proper support to be rendered on a dark background.
     * The proper fix is to stop using dark backgrounds, or in worst case add a variant. */
    hackForDarkBackgroundUseAndBeFired?: true;
    onBlur?: FocusEventHandler<HTMLTextAreaElement>;
    onFocus?: FocusEventHandler<HTMLTextAreaElement>;
  } & (
    | {
        maxLength: number;
        allowExceededMaxLength?: boolean;
      }
    | {
        maxLength?: undefined;
        allowExceededMaxLength?: undefined;
      }
  ),
  forwardedRef: ForwardedRef<HTMLTextAreaElement>,
) => {
  const innerRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (innerRef.current && autoHeight) {
      innerRef.current.style.height = 'auto';
      innerRef.current.style.height = `${innerRef.current.scrollHeight}px`;
    }
  }, [value, autoHeight]);

  const { t } = useTranslation();
  const generatedId = useId();
  id ||= generatedId;

  const maxLengthLimitReached = useMemo(() => {
    if (!allowExceededMaxLength || !maxLength) return;
    return value.length > maxLength;
  }, [allowExceededMaxLength, maxLength, value]);

  return (
    <InputWrapper
      id={id}
      label={label}
      hasContent={!!value}
      error={maxLengthLimitReached ? t('common_too-many-characters') : error}
      helperText={helperText}
      skipMargin={skipMargin}
      disabled={disabled}
      defaultLabelTopPos="1.3rem"
      hackForDarkBackgroundUseAndBeFired={hackForDarkBackgroundUseAndBeFired}
      characterCounterSlot={
        maxLength ? (
          <CharacterCounter
            maxLength={maxLength}
            count={value.length}
            hasError={maxLengthLimitReached}
          />
        ) : null
      }
    >
      <StyledTextarea
        {...props}
        rows={Math.max(3, rows)}
        value={value}
        id={id}
        disabled={disabled}
        ref={el => {
          innerRef.current = el;
          if (forwardedRef) {
            if ('current' in forwardedRef) {
              forwardedRef.current = el;
            } else {
              forwardedRef(el);
            }
          }
        }}
        $allowResize={allowResize}
        $hackForDarkBackgroundUseAndBeFired={hackForDarkBackgroundUseAndBeFired}
        maxLength={allowExceededMaxLength ? undefined : maxLength}
      />
    </InputWrapper>
  );
};

export const Textarea = forwardRef(TextareaWithRef);
