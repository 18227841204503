import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { DESKTOP_STEPPER_ITEM_HORIZONTAL_GAP, SIZING_1, SPACING_1 } from '../../theme';
import queries from './container-queries.module.css';

const Container = styled.div`
  container-type: inline-size;
  width: 100%;
`;
const MiniContainer = styled.div<{ $avoidWrap?: boolean }>`
  line-height: 0;
  display: inline-flex;
  gap: ${SPACING_1};
  flex-wrap: ${props => (props.$avoidWrap ? 'nowrap' : 'wrap')};
  justify-content: center;
`;

const BigIndicatorCircle = styled.div`
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
`;

const BigItem = styled.div<{ $hasOnClick: boolean; $active: boolean }>`
  border: 0;
  background: none;
  padding: 0;
  display: flex;
  gap: ${DESKTOP_STEPPER_ITEM_HORIZONTAL_GAP};
  align-items: center;
  ${props =>
    props.$active
      ? css`
          ${BigIndicatorCircle} {
            background-color: ${props => props.theme.colorBackgroundStepperItemComponentActive};
            color: ${props => props.theme.colorTextStepperItemComponentActive};
          }
          > span {
            color: ${props => props.theme.colorTextStepperItemComponentStepperItemLabelActive};
          }
        `
      : css`
          ${BigIndicatorCircle} {
            background-color: ${props => props.theme.colorBackgroundStepperItemComponentInactive};
            color: ${props => props.theme.colorTextStepperItemComponentInactive};
          }
          > span {
            color: ${props => props.theme.colorTextStepperItemComponentStepperItemLabelInactive};
          }

          ${props.$hasOnClick &&
          css`
            &:hover {
              ${BigIndicatorCircle} {
                background-color: ${props => props.theme.colorBackgroundStepperItemComponentHover};
                color: ${props => props.theme.colorTextStepperItemComponentHover};
              }
              > span {
                color: ${props => props.theme.colorTextStepperItemComponentStepperItemLabelHover};
              }
            }
          `}
        `}
`;

const MiniItem = styled.div<{ $active: boolean; $hasOnClick: boolean; $onDark?: boolean }>`
  border: 0;
  background: none;
  padding: 0;
  border-radius: 50%;
  flex-shrink: 0;
  width: ${SIZING_1};
  height: ${SIZING_1};
  cursor: ${props => (props.$hasOnClick ? 'pointer' : 'default')};
  background: ${props => {
    if (props.$onDark) {
      return props.$active
        ? props.theme.colorBackgroundStepperMiniItemOnDarkActive
        : props.theme.colorBackgroundStepperMiniItemOnDarkDefault;
    }
    return props.$active
      ? props.theme.colorBackgroundStepperMiniItemOnLightActive
      : props.theme.colorBackgroundStepperMiniItemOnLightDefault;
  }};
  ${props =>
    props.$hasOnClick &&
    !props.$active &&
    css`
      &:hover {
        background: ${props.$onDark
          ? props.theme.colorBackgroundStepperMiniItemOnDarkHover
          : props.theme.colorBackgroundStepperMiniItemOnLightHover};
      }
    `}
`;

interface MiniProps {
  type: 'mini';
  onDark?: boolean;
  avoidWrap?: boolean;
}
interface BigProps {
  type?: 'big';
  onDark?: undefined;
  avoidWrap?: undefined;
}

export type StepperProps = {
  steps: { text: string | number; onClick?: () => void; href?: string }[];
  activeIndex: number;
} & (MiniProps | BigProps);

export const Stepper = ({ steps, type, activeIndex, onDark, avoidWrap }: StepperProps) => {
  return type === 'mini' ? (
    <MiniContainer $avoidWrap={avoidWrap}>
      {steps.map(({ href, text, onClick }, i) => (
        <MiniItem
          key={i}
          $onDark={onDark}
          as={href ? Link : onClick ? 'button' : 'div'}
          to={href ?? undefined}
          type={!href && onClick ? 'button' : undefined}
          onClick={onClick ? () => onClick() : undefined}
          $active={activeIndex === i}
          $hasOnClick={!!(onClick || href)}
          aria-label={text.toString()}
        />
      ))}
    </MiniContainer>
  ) : (
    <Container>
      <div className={queries.wrapper}>
        {steps.map(({ href, text, onClick }, i) => (
          <BigItem
            key={i}
            as={href ? Link : onClick ? 'button' : 'div'}
            to={href ?? undefined}
            type={!href && onClick ? 'button' : undefined}
            onClick={onClick ? () => onClick() : undefined}
            $active={activeIndex === i}
            $hasOnClick={!!(onClick || href)}
          >
            <BigIndicatorCircle>{i + 1}</BigIndicatorCircle>
            <span>{text}</span>
          </BigItem>
        ))}
      </div>
    </Container>
  );
};
