declare global {
  interface Date {
    oldToLocaleString: typeof Date.prototype.toLocaleString;
  }

  interface Number {
    oldToLocaleString: typeof Number.prototype.toLocaleString;
  }

  interface String {
    oldLocaleCompare: typeof String.prototype.localeCompare;
  }

  interface BigInt {
    oldToLocaleString: typeof BigInt.prototype.toLocaleString;
  }
}

const localeMap: { [key: string]: string } = {
  no: 'nb',
};

export const setDefaultLocaleOnPrototypes = (locale: string) => {
  const mappedLocale = localeMap[locale] || locale;

  if (!Number.prototype.oldToLocaleString)
    Number.prototype.oldToLocaleString = Number.prototype.toLocaleString;
  if (!BigInt.prototype.oldToLocaleString)
    BigInt.prototype.oldToLocaleString = BigInt.prototype.toLocaleString;
  if (!String.prototype.oldLocaleCompare)
    String.prototype.oldLocaleCompare = String.prototype.localeCompare;
  if (!Date.prototype.oldToLocaleString)
    Date.prototype.oldToLocaleString = Date.prototype.toLocaleString;

  Number.prototype.toLocaleString = function (
    localeOverride?: string,
    options?: Intl.NumberFormatOptions,
  ) {
    return (this as number).oldToLocaleString(localeOverride || mappedLocale, options || {});
  };

  BigInt.prototype.toLocaleString = function (
    localeOverride?: string,
    options?: BigIntToLocaleStringOptions,
  ) {
    return (this as bigint).oldToLocaleString(localeOverride || mappedLocale, options || {});
  };

  Date.prototype.toLocaleString = function (
    localeOverride?: string,
    options?: Intl.DateTimeFormatOptions,
  ) {
    return (this as Date).oldToLocaleString(localeOverride || mappedLocale, options || {});
  };

  String.prototype.localeCompare = function (
    that: string,
    locales?: string | string[],
    options?: Intl.CollatorOptions,
  ) {
    return (this as string).oldLocaleCompare(that, locales || mappedLocale, options || {});
  };
};
