import styled, { css } from 'styled-components';
import { GREY_400, GREY_900, NEUTRAL_WHITE, SHADOW_2_WITHOUT_BOTTOM_SPREAD } from '../../theme';
import { P } from '../Typo';

const ListItem = styled.li`
  list-style-type: none;
  position: relative;
`;

const ShadowRemover = styled.div<{ $selected: boolean }>`
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 3px;
  background-color: ${NEUTRAL_WHITE};
  z-index: 1;

  ${({ $selected }) =>
    !$selected &&
    css`
      display: none;
    `}
`;

const StyledButton = styled.button<{ $selected: boolean }>`
  background-color: ${GREY_400};
  padding: 0.825rem 1.25rem;
  border: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  border-bottom-color: transparent;
  position: inherit;

  strong {
    color: ${GREY_900};
  }

  ${({ $selected }) =>
    $selected
      ? css`
          background-color: ${NEUTRAL_WHITE};
          box-shadow: ${SHADOW_2_WITHOUT_BOTTOM_SPREAD};
          padding: 0.925rem 1.25rem;

          &:hover {
            background-color: ${NEUTRAL_WHITE};
          }
        `
      : css`
          background-color: #e5e8ed;

          &:hover {
            background-color: #dee2ea;
          }
        `}

  [data-whatintent='keyboard'] &:focus {
    ${ShadowRemover} {
      display: none;
    }
  }
`;

type Props = {
  title: string;
  selected: boolean;
  onSetSelected: () => void;
  'data-testid'?: string;
};

const Tab: React.FC<Props> = ({ title, selected, onSetSelected, ...props }) => (
  <ListItem>
    <StyledButton data-testid={props['data-testid']} onClick={onSetSelected} $selected={selected}>
      <P>
        <strong>{title}</strong>
      </P>
      <ShadowRemover $selected={selected} />
    </StyledButton>
  </ListItem>
);

export default Tab;
