import React from 'react';
import { GroupBase, MultiValueProps } from 'react-select';
import styled, { keyframes } from 'styled-components';
import { Tag } from '../../../atoms';
import { TagData } from '../../../atoms/Tag';

type Props = MultiValueProps<OptionType, true, GroupBase<OptionType>> & {
  data: {
    label: string;
    value: any;
    variant?: 'primary' | 'secondary' | 'dark' | 'prAssistant';
  };
  selectProps: { dismissAriaLabel: string; operator: string; variant: 'primary' | 'secondary' };
  removeProps: {
    onClick: (data: TagData) => void;
    onTouchEnd?: (event: React.TouchEvent<HTMLButtonElement>) => void;
    onTouchStart?: (event: React.TouchEvent<HTMLButtonElement>) => void;
  };
  isDisabled: boolean;
};

const showMultiValue = keyframes`
  0% {
    opacity: 0.001;
    transform: scale(0.85);
  }
  90% {
    transform: scale(1);
  }
  100% {
    opacity: 1;
  }
`;

const TagContainer = styled.div`
  margin: 0.125rem;
  max-width: calc(100% - 0.25rem);
  animation: ${showMultiValue} 0.3s cubic-bezier(0.25, 0.55, 0.3, 1) forwards;
`;

const Operator = styled.span`
  margin: 0 0.5rem;
  &:first-child {
    display: none;
  }
`;
type OptionType = {
  label: string;
  value: any;
  icon?: React.ReactNode;
  isFixed?: boolean;
  testId?: string;
};

const MultiValue: React.FC<Props> = ({
  data,
  selectProps: { dismissAriaLabel, operator, variant },
  removeProps: { onClick, onTouchEnd, onTouchStart },
  isDisabled,
}) => (
  <>
    {operator && <Operator>{operator}</Operator>}
    <TagContainer>
      <Tag
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        variant={data.variant ?? variant ?? 'secondary'}
        disabled={isDisabled}
        interactionType="dismiss"
        dismissAriaLabel={dismissAriaLabel}
        onInteraction={onClick}
        data={{
          name: data.label,
          id: data.value,
          icon: data.icon,
          isFixed: data.isFixed,
          testId: data.testId,
        }}
      />
    </TagContainer>
  </>
);
export default MultiValue;
