import { isoDate } from '@mnd-frontend/dates';
import qs from 'qs';

export const formatTimeFilterParams = (params: qs.ParsedQs, asIsoDate = true) => {
  const { startAt, endAt } = params;
  return {
    startAt:
      typeof startAt === 'string' ? (asIsoDate ? isoDate(new Date(startAt)) : startAt) : null,
    endAt: typeof endAt === 'string' ? (asIsoDate ? isoDate(new Date(endAt)) : endAt) : null,
  };
};
