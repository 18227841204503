import { useClickOutside } from '@mnd-frontend/hooks';
import { useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { Icons, InputChip, Text, useTranslation } from '../..';
import {
  GREY_300,
  GREY_400,
  NEUTRAL_WHITE,
  RADIUS_MD,
  SIZING_10,
  SIZING_3,
  SPACING_0_5,
  SPACING_2,
  SPACING_3,
} from '../../theme';

const DropdownWrapper = styled.div`
  position: relative;
`;

const Dropdown = styled.ul<{ $open: boolean }>`
  display: ${({ $open }) => ($open ? 'grid' : 'none')};
  border-radius: ${RADIUS_MD};
  background-color: ${NEUTRAL_WHITE};
  max-height: 30rem;
  margin-top: ${SPACING_0_5};
  overflow-y: scroll;
  padding: 0;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 3;
  box-shadow:
      //shadow from design
    0px 0px 2px 0px rgba(49, 73, 164, 0.14),
    0px 0px 3px 0px rgba(49, 73, 164, 0.1),
    0px 12px 16px 0px rgba(49, 73, 164, 0.08);
`;

const DropdownItem = styled.li<{ $active?: boolean }>`
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr ${SIZING_3};
  align-items: center;
  padding: ${SPACING_2} ${SPACING_3};
  background-color: ${({ $active }) => ($active ? GREY_400 : NEUTRAL_WHITE)};
  min-height: ${SIZING_10};
  &:hover {
    background-color: ${GREY_300};
  }
`;

const DropdownText = styled.div`
  display: grid;
  gap: ${SPACING_0_5};
`;

type DropdownItem = {
  value: string;
  label: string;
  subLabel?: string;
};

type Props<T extends DropdownItem> = {
  items: T[];
  onRemove: (value: string) => void;
  onSelect: (items: T[]) => void;
  selectedItems: T[];
  label: string;
  type: 'list' | 'search';
  hintMessage?: string;
} & (
  | { searchQuery?: string; onSearchChange?: (query: string) => void }
  | { searchQuery?: undefined; onSearchChange?: undefined }
);

const SelectChipDropdown = <T extends DropdownItem>({
  items,
  onRemove,
  onSelect,
  selectedItems,
  label,
  type,
  hintMessage,
  searchQuery: externalSearchQuery,
  onSearchChange: externalOnSearchChange,
}: Props<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [internalSearchQuery, setInternalSearchQuery] = useState('');
  const searchQuery = externalSearchQuery || internalSearchQuery;
  const setSearchQuery = externalOnSearchChange || setInternalSearchQuery;

  const dropdownRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const isSelected = useCallback(
    (item: T) => selectedItems?.some(selectedItem => selectedItem.value === item.value),
    [selectedItems],
  );

  const handleSelect = useCallback(
    (item: T) => {
      const isAlreadySelected = selectedItems.find(
        existingItem => existingItem.value === item.value,
      );
      if (isAlreadySelected) {
        onSelect(selectedItems.filter(existingItem => existingItem.value !== item.value));
      } else {
        onSelect([...selectedItems, item]);
      }
    },
    [selectedItems, onSelect],
  );

  const filteredSearchItems = useMemo(
    () =>
      searchQuery.length >= 2
        ? items.filter(item => item.label.toLowerCase().includes(searchQuery.toLowerCase()))
        : [],
    [items, searchQuery],
  );

  const showSearchHintMessage =
    searchQuery.length < 2 && filteredSearchItems.length === 0 && selectedItems.length === 0;
  const emptyResult = searchQuery.length >= 2 && filteredSearchItems.length === 0;

  useClickOutside(
    dropdownRef,
    () => {
      setIsOpen(false);
      if (searchQuery) setSearchQuery('');
    },
    isOpen,
  );

  return (
    <DropdownWrapper ref={dropdownRef}>
      {type === 'list' && (
        <>
          <InputChip
            type="dropdownList"
            selectedItems={selectedItems}
            label={label}
            onClick={() => setIsOpen(!isOpen)}
            onRemove={onRemove}
            isOpen={isOpen}
          />
          <Dropdown $open={isOpen}>
            {items.map(item => (
              <DropdownItem
                key={item.value}
                $active={isSelected(item)}
                onClick={() => handleSelect(item)}
              >
                <DropdownText>
                  <Text
                    as="p"
                    variant="labelMedium"
                    color={isSelected(item) ? 'GREY_700' : 'GREY_1000'}
                  >
                    {item.label}
                  </Text>
                  {item?.subLabel && (
                    <Text as="p" variant="bodySmall" color="GREY_700">
                      {item.subLabel}
                    </Text>
                  )}
                </DropdownText>
                {isSelected(item) ? (
                  <Icons.CircleCross $size="xs" />
                ) : (
                  <Icons.CirclePlus $size="xs" />
                )}
              </DropdownItem>
            ))}
          </Dropdown>
        </>
      )}
      {type === 'search' && (
        <>
          <InputChip
            type="search"
            label={label}
            selectedItems={selectedItems}
            onClick={() => setIsOpen(true)}
            onRemove={onRemove}
            searchQuery={searchQuery}
            onSearchChange={setSearchQuery}
          />
          <Dropdown $open={isOpen}>
            {showSearchHintMessage ? (
              <DropdownItem>
                <DropdownText>
                  <Text as="p" variant="labelMedium" marginTop="SPACING_1">
                    {hintMessage}
                  </Text>
                  <Text as="p" variant="bodySmall" color="GREY_700" marginBottom="SPACING_1">
                    {t('common_min-character-search', { count: 2 })}
                  </Text>
                </DropdownText>
              </DropdownItem>
            ) : emptyResult ? (
              <DropdownItem>
                <DropdownText>
                  <Text as="p" variant="labelMedium" marginTop="SPACING_1">
                    {t('common_no_matching_results')}
                  </Text>
                  <Text as="p" variant="bodySmall" color="GREY_700" marginBottom="SPACING_1">
                    {t('common_try_another_search')}
                  </Text>
                </DropdownText>
              </DropdownItem>
            ) : (
              filteredSearchItems.map(item => (
                <DropdownItem
                  key={item.value}
                  $active={isSelected(item)}
                  onClick={() => handleSelect(item)}
                >
                  <DropdownText>
                    <Text
                      as="p"
                      variant="labelMedium"
                      color={isSelected(item) ? 'GREY_700' : 'GREY_1000'}
                    >
                      {item.label}
                    </Text>
                    {item?.subLabel && (
                      <Text as="p" variant="bodySmall" color="GREY_700">
                        {item.subLabel}
                      </Text>
                    )}
                  </DropdownText>
                  {isSelected(item) ? (
                    <Icons.CircleCross $size="xs" />
                  ) : (
                    <Icons.CirclePlus $size="xs" />
                  )}
                </DropdownItem>
              ))
            )}
          </Dropdown>
        </>
      )}
    </DropdownWrapper>
  );
};

export default SelectChipDropdown;
