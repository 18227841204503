import MultiValue from '../MultipleSelect/MultiValue';

type ContactOption = {
  label: string;
  value?: any;
  icon?: any;
  variant?: 'primary' | 'secondary' | 'dark' | 'purple' | 'prAssistant';
  extraData?:
    | {
        type: 'list';
        contactsCount: number;
      }
    | {
        type: 'contact';
        email: string;
        avatar: string;
      }
    | Record<string, never>;
};

const ContactMultiValue = (props: { data: ContactOption }) => {
  const { data } = props;
  if (!data.extraData) return <div />;
  const name =
    data.extraData.type === 'list' ? `${data.label} (${data.extraData.contactsCount})` : data.label;
  return (
    <MultiValue
      {...(props as any)}
      data={{ label: name, value: data.value, icon: data.icon, variant: data.variant }}
    />
  );
};

ContactMultiValue.displayName = 'ContactMultiValue';

export default ContactMultiValue;
