import { MouseEventHandler } from 'react';
import styled from 'styled-components';
import { useTranslation } from '../../contexts/UIContext';
import Icons from '../Icons';

/**
 * This component is not to be exported, but only used internally inside the UI library.
 */
const UiLibCloseButtonStyles = styled.button`
  border: none;
  border-radius: ${props => props.theme.radiusButton};
  width: ${props => props.theme.sizingButtonWidthIconOnly};
  height: ${props => props.theme.sizingButtonHeight};
  background: ${props => props.theme.colorBackgroundButtonTonedOnDarkDefault}80; // 50% opacity
  color: ${props => props.theme.colorTextButtonTextTonedOnDarkDefault};
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  [data-whatintent='keyboard'] &:focus,
  &:hover {
    color: ${props => props.theme.colorTextButtonTextTonedOnDarkHover};
    background: ${props => props.theme.colorBackgroundButtonTonedOnDarkHover}80;
  }
  &:active {
    color: ${props => props.theme.colorTextButtonTextTonedOnDarkFocused};
    background: ${props => props.theme.colorBackgroundButtonTonedOnDarkFocused}80;
  }
`;

export const UiLibCloseButton = ({
  onClick,
}: {
  onClick: MouseEventHandler<HTMLButtonElement>;
}) => {
  const { t } = useTranslation();

  return (
    <UiLibCloseButtonStyles aria-label={t('common_close')} onClick={onClick} type="button">
      <Icons.Close $size="xs" />
    </UiLibCloseButtonStyles>
  );
};
