import { components, defaultTheme } from 'react-select';
import styled from 'styled-components';

type Props = Parameters<typeof components.ValueContainer>[number];

const ValueContainer = (props: Props) => (
  <components.ValueContainer {...props} theme={defaultTheme} />
);

const StyledValueContainer: any = styled(ValueContainer)`
  && {
    border: 1px solid transparent;
    pointer-events: ${props => (props.isDisabled ? 'none' : 'auto')};
  }
`;

export default StyledValueContainer;
