import { forwardRef, ReactNode, useCallback, useId, useRef } from 'react';
import { Dialog as AriaDialog } from 'react-aria-components';
import styled from 'styled-components';
import { useTranslation } from '../../contexts/UIContext';
import {
  DESKTOP_DIALOG_HORIZONTAL_PADDING,
  DESKTOP_DIALOG_VERTICAL_GAP,
  DESKTOP_DIALOG_VERTICAL_PADDING,
  MOBILE_DIALOG_HORIZONTAL_PADDING,
  MOBILE_DIALOG_VERTICAL_GAP,
  MOBILE_DIALOG_VERTICAL_PADDING,
  SHADOW_3,
  SPACING_0_5,
  SPACING_1,
  SPACING_1_5,
  SPACING_2,
  SPACING_3,
} from '../../theme';
import { UiLibModal } from '../../UiLibModal';
import { Button } from '../Button';
import { panelLoadingCss } from '../Panel';
import { Tabs } from '../Tabs';
import { Text } from '../Text';
import media from '../utils/media';

const DialogStyles = styled(AriaDialog)<{ $loading?: boolean; size?: 'sm' | 'md' }>`
  background: ${props => props.theme.colorBackgroundDialogDefault};
  --current-background: ${props => props.theme.colorBackgroundDialogDefault};
  form {
    background: inherit;
    position: relative;
    max-height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${DESKTOP_DIALOG_VERTICAL_GAP};
    height: 100%;
    width: 100%;
    overflow: hidden;
    ${props => props.$loading && panelLoadingCss}
  }
  width: ${props => (props.size === 'md' ? '50rem' : '35rem')};
  max-width: 95vw;
  border: 0;
  padding: ${DESKTOP_DIALOG_VERTICAL_PADDING} 0;
  ${media.lessThan('phablet')} {
    padding: ${MOBILE_DIALOG_VERTICAL_PADDING} 0;
    form {
      gap: ${MOBILE_DIALOG_VERTICAL_GAP};
    }
  }
  border-radius: ${props => props.theme.radiusDialog};
`;

const TabsPadding = styled.div`
  padding: 0 ${DESKTOP_DIALOG_HORIZONTAL_PADDING};
  flex: 1;
  background: inherit;
  overflow-y: auto;
  scrollbar-width: thin;
  border-bottom: 1px solid ${props => props.theme.colorBorderDividerDefault};
`;

const Container = styled.div<{ $hasTabs?: boolean; $hasFilter?: boolean }>`
  padding: ${SPACING_3} ${props => (props.$hasTabs ? 0 : DESKTOP_DIALOG_HORIZONTAL_PADDING)};
  padding-top: ${props => props.$hasFilter && 0};
  ${media.lessThan('phablet')} {
    padding: ${SPACING_1_5} ${props => (props.$hasTabs ? 0 : MOBILE_DIALOG_HORIZONTAL_PADDING)};
    padding-top: ${props => props.$hasFilter && 0};
  }
  background: inherit;
  border-top: ${props =>
    !props.$hasFilter && !props.$hasTabs && `1px solid ${props.theme.colorBorderDividerDefault}`};
  border-bottom: ${props =>
    !props.$hasTabs && `1px solid ${props.theme.colorBorderDividerDefault}`};
  flex: 1;
  overflow-y: auto;
  position: relative;
  scrollbar-width: thin;
`;

const ButtonRow = styled.footer<{ $hasContainerContent: boolean; $hasTertiaryAction: boolean }>`
  position: relative;
  z-index: 6; // Above loading overlay
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${SPACING_3};
  padding: 0 ${DESKTOP_DIALOG_HORIZONTAL_PADDING};
  justify-content: ${props =>
    props.$hasTertiaryAction
      ? 'space-between'
      : props.$hasContainerContent
        ? 'flex-end'
        : 'center'};
  ${media.lessThan('phablet')} {
    padding: 0 ${MOBILE_DIALOG_HORIZONTAL_PADDING};
    flex-direction: column;
  }
`;

const PrimaryButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${SPACING_3};
`;

const Header = styled.header`
  padding: 0 ${DESKTOP_DIALOG_HORIZONTAL_PADDING};
  gap: ${SPACING_2};
  ${media.lessThan('phablet')} {
    gap: ${SPACING_1};
    padding: 0 ${MOBILE_DIALOG_HORIZONTAL_PADDING};
  }
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Texts = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: ${SPACING_1};
  ${media.lessThan('phablet')} {
    gap: ${SPACING_0_5};
  }
`;

const Filters = styled.div`
  position: sticky;
  top: 0;
  z-index: 3;
  background: inherit;
  /* box-shadow: ${SHADOW_3}; */
  padding: ${SPACING_2} 0;
  ${media.lessThan('phablet')} {
    padding: ${SPACING_1} 0;
  }
`;

type ContainerProps =
  | {
      children?: ReactNode | undefined;
      filters?: ReactNode;
      tabs?: undefined;
    }
  | {
      children?: undefined;
      filters?: undefined;
      tabs: {
        title: string;
        dataTestId?: string;
        renderFilter?: () => ReactNode;
        renderContent: () => ReactNode;
      }[];
    };

type DataFields = Record<`data-${string}`, string | boolean>;

type TextButtonAction =
  | {
      onClose: () => void;
      secondaryAction?: undefined;
    }
  | {
      onClose?: undefined;
      secondaryAction: {
        text: string;
        loading?: boolean;
        disabled?: boolean;
        onClick: () => void;
      } & DataFields;
    };

interface CommonDialogProps {
  /**
   * Prefer using string for headline, but ReactNode is allowed for `<Trans />`
   */
  headline?: ReactNode;
  /**
   * Prefer using string for description, but ReactNode is allowed for `<Trans />` or things like `<bold>` tags
   */
  description: ReactNode;
  icon?: ReactNode;
  size?: 'sm' | 'md';
  open: boolean;
  loading?: boolean;
  dataTestId?: string;
  confirmAction: {
    text?: string;
    disabled?: boolean;
    onClick: () => void;
    loading?: boolean;
  } & DataFields;
  tertiaryAction?: {
    text: string;
    disabled?: boolean;
    onClick: () => void;
    loading?: boolean;
  } & DataFields;
}

export type DialogProps = CommonDialogProps & ContainerProps & TextButtonAction;

const extractSpread = (
  args:
    | CommonDialogProps['confirmAction']
    | CommonDialogProps['tertiaryAction']
    | TextButtonAction['secondaryAction'],
) => {
  const { onClick: _1, loading: _2, disabled: _3, text: _4, ...spread } = args || {};

  return spread as DataFields;
};

export const Dialog = forwardRef<HTMLDivElement, DialogProps>(
  (
    {
      icon,
      dataTestId,
      loading,
      headline,
      tertiaryAction,
      confirmAction,
      description,
      open,
      onClose,
      secondaryAction,
      filters,
      ...props
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const children = 'children' in props ? props.children : undefined;
    const tabs = 'tabs' in props ? props.tabs : undefined;

    const internalRef = useRef<HTMLDivElement | null>(null);
    const descriptionId = useId();
    const headlineId = useId();

    const setRef = useCallback(
      (el: HTMLDivElement | null) => {
        internalRef.current = el;
        if (ref) {
          if (typeof ref === 'function') {
            ref(el);
          } else {
            ref.current = el;
          }
        }
      },
      [ref],
    );

    const confirmSpread = extractSpread(confirmAction);
    const secondarySpread = extractSpread(secondaryAction);

    return (
      <UiLibModal open={open}>
        <DialogStyles
          ref={setRef}
          aria-labelledby={headline ? headlineId : descriptionId}
          $loading={loading}
          data-testid={dataTestId}
          size={props.size}
        >
          <form
            method="POST"
            onSubmit={e => {
              e.stopPropagation();
              e.preventDefault();
              confirmAction.onClick();
            }}
          >
            <Header>
              {icon}
              <Texts>
                {headline && (
                  <Text variant="headlineMedium" as="h2" id={headlineId}>
                    {headline}
                  </Text>
                )}
                <Text
                  variant="body"
                  as={typeof description === 'string' ? 'p' : 'div'}
                  id={descriptionId}
                >
                  {description}
                </Text>
              </Texts>
            </Header>
            {tabs && (
              <TabsPadding>
                <Tabs
                  stickyHeader
                  variant="mainTabs"
                  tabs={tabs.map(tab => ({
                    title: tab.title,
                    dataTestId: tab.dataTestId,
                    renderFilter: tab.renderFilter,
                    renderContent: () => (
                      <Container $hasTabs $hasFilter={!!tab.renderFilter}>
                        {tab.renderContent()}
                      </Container>
                    ),
                  }))}
                />
              </TabsPadding>
            )}
            {children ? (
              <Container $hasFilter={!!filters}>
                {filters && <Filters>{filters}</Filters>}
                {children}
              </Container>
            ) : null}
            <ButtonRow
              $hasContainerContent={!!children || !!tabs}
              $hasTertiaryAction={!!tertiaryAction}
            >
              {tertiaryAction && (
                <Button
                  {...extractSpread(tertiaryAction)}
                  type="button"
                  configuration="toned"
                  disabled={tertiaryAction.disabled || loading}
                  onClick={() => tertiaryAction.onClick()}
                >
                  {tertiaryAction.text}
                </Button>
              )}
              <PrimaryButtons>
                <Button
                  {...secondarySpread}
                  data-testid={secondarySpread['data-testid'] || 'dialog_cancel_btn'}
                  type="button"
                  configuration="text"
                  disabled={
                    secondaryAction?.disabled === undefined
                      ? loading && !onClose
                      : secondaryAction.disabled
                  }
                  loading={secondaryAction?.loading}
                  onClick={() => {
                    if (onClose) {
                      return onClose();
                    }
                    secondaryAction.onClick();
                  }}
                >
                  {secondaryAction?.text ?? t('common_close')}
                </Button>
                <Button
                  {...confirmSpread}
                  data-testid={confirmSpread['data-testid'] || 'dialog_submit_btn'}
                  type="submit"
                  loading={confirmAction.loading}
                  disabled={confirmAction.disabled || loading}
                >
                  {confirmAction.text || t('common_confirm')}
                </Button>
              </PrimaryButtons>
            </ButtonRow>
          </form>
        </DialogStyles>
      </UiLibModal>
    );
  },
);
