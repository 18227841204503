import DayPicker from 'react-day-picker';
import styled from 'styled-components';
import { GREY_200, GREY_600, GREY_800, NEUTRAL_WHITE } from '../../theme';

const StyledDayPicker = styled(DayPicker)`
  position: relative;
  clear: both;
  .DayPicker-Months {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    margin: -1rem;

    & > * {
      margin: 1rem;
    }
  }

  .DayPicker-Month {
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
    user-select: none;
  }

  .DayPicker-Caption {
    display: table-caption;
    text-align: center;
    margin-top: 0.5rem;
    margin-bottom: 0.75rem;
  }

  .DayPicker-Caption > div {
    font-size: 1.125rem;
  }

  .DayPicker-Weekdays {
    display: table-header-group;
  }

  .DayPicker-WeekdaysRow {
    display: table-row;
  }

  .DayPicker-Weekday {
    display: table-cell;
    padding: 0.5rem;
    color: ${GREY_800};
    text-align: center;
  }

  .DayPicker-Weekday abbr[title] {
    border-bottom: none;
    text-decoration: none;
  }

  .DayPicker-Body {
    display: table-row-group;
  }

  .DayPicker-Week {
    display: table-row;
  }

  .DayPicker-Day,
  .DayPicker-WeekNumber {
    display: table-cell;
    padding: 0.5rem;
    vertical-align: middle;
    cursor: pointer;
  }

  .DayPicker-Day {
    text-align: center;
    background-color: ${NEUTRAL_WHITE};
  }

  .DayPicker-Day--selected {
    background-color: ${props => props.theme.colorBackgroundButtonTonedOnLightDefault};
    color: ${props => props.theme.colorTextButtonTextTonedOnLightDefault};
  }
  .DayPicker-Day--disabled {
    background-color: ${GREY_200};
    color: ${GREY_600};
    pointer-events: none;
  }

  .DayPicker-Day--start,
  .DayPicker-Day--end {
    background-color: ${props => props.theme.colorBackgroundButtonFilledOnLightDefault};
    color: ${NEUTRAL_WHITE};
  }

  .DayPicker-Day--outside,
  .DayPicker-Day--selected.DayPicker-Day--outside {
    opacity: 0.5;
    background-color: ${NEUTRAL_WHITE};
    cursor: default;
  }

  .DayPicker-Day--outside.DayPicker-Day--selected {
    color: unset;
  }
`;

StyledDayPicker.defaultProps = {
  fixedWeeks: true,
};

export default StyledDayPicker;
