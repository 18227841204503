import styled from 'styled-components';
import { RED_600 } from '../../theme';

const SvgSymbolWrapper = styled.i<{ $size?: 'small' | 'medium' | 'large' }>`
  display: inline-block;
  width: ${({ $size }) => ($size === 'large' ? '4.5rem' : $size === 'small' ? '2rem' : '3.125rem')};
  height: ${({ $size }) =>
    $size === 'large' ? '4.5rem' : $size === 'small' ? '2rem' : '3.125rem'};
  svg {
    width: 100%;
    height: 100%;
  }

  .cls-2,
  .cls-3,
  path[style='fill:#8075f2'],
  path[style='fill: #8075f2'],
  path[style='fill: rgb(128, 117, 242);'] {
    fill: ${RED_600} !important;
  }

  .cls-1,
  path[style='fill:#e8e5fe'],
  path[style='fill: #e8e5fe'],
  path[style='fill: rgb(232, 229, 254);'] {
    fill: ${RED_600} !important;
    opacity: 0.15;
  }
`;

export default SvgSymbolWrapper;
