import { components, defaultTheme } from 'react-select';
import styled from 'styled-components';
import { GREY_600, NEUTRAL_WHITE, SHADOW_1 } from '../../../../theme';

type Props = Parameters<typeof components.Menu>[number];

const Menu = (props: Props) => <components.Menu {...props} theme={defaultTheme} />;

const StyledMenu: any = styled(Menu).attrs(props => ({
  innerProps: {
    ...props.innerProps,
    'data-testid': 'select-menu',
  },
}))`
  && {
    background-color: ${NEUTRAL_WHITE};
    box-shadow: ${SHADOW_1};
    border: 1px solid ${GREY_600};
    overflow: hidden;
  }
`;

export default StyledMenu;
