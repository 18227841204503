import { isEqual } from 'lodash-es';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

type UseStateWithOverwriteOptions = {
  serverValueLoading: boolean;
};

export const useStateWithOverwrite = <T>(
  serverValue: T,
  options?: UseStateWithOverwriteOptions,
): [T, Dispatch<SetStateAction<T>>, boolean, () => void] => {
  const [value, setter] = useState(serverValue);
  const dirty = useMemo(() => !isEqual(serverValue, value), [serverValue, value]);
  const reset = useCallback(() => setter(serverValue), [serverValue]);

  useEffect(() => {
    if (!options?.serverValueLoading) {
      setter(serverValue);
    }
  }, [options?.serverValueLoading, serverValue]);

  return [value, setter, dirty, reset];
};
