import styled from 'styled-components';

const PaginationInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  border: 0;
  text-align: center;
  outline: none;

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }
`;

PaginationInput.defaultProps = {};

export default PaginationInput;
