import styled, { css } from 'styled-components';

type BodyProps = { $noPadding?: boolean };

const Body = styled.div<BodyProps>`
  padding: 1.5rem;

  ${({ $noPadding }) =>
    $noPadding &&
    css`
      padding: 0;
    `};
`;

export default Body;
