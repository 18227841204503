import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { GREY_400, SHADOW_2 } from '../../theme';
import { H3 } from '../Typo';

export const StyledHeader = styled.div<{ $noBorder: boolean; $sticky: boolean; $stuck: boolean }>`
  border-bottom: 1px solid ${GREY_400};
  padding: 1rem 1.5rem;
  background: white;
  position: ${props => (props.$sticky ? 'sticky' : 'relative')};
  top: ${props => props.$sticky && '-2px'};
  z-index: ${props => props.$sticky && 3};

  box-shadow: ${props => props.$stuck && SHADOW_2};

  & > * {
    margin: 0;
  }

  ${({ $noBorder }) =>
    $noBorder &&
    css`
      border: none;
    `}
`;

type HeaderProps = {
  noBorder?: boolean;
  style?: React.CSSProperties;
  className?: string;
  isSticky?: boolean;
  children: ReactNode;
  customHeader?: boolean;
};

const Header = ({
  style,
  className,
  noBorder = false,
  isSticky = false,
  children,
  customHeader = false,
}: HeaderProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isStuck, setIsStuck] = useState(false);

  useEffect(() => {
    if (!ref.current || !isSticky) return;

    const observer = new IntersectionObserver(
      ([entry]) => setIsStuck(entry.intersectionRatio < 1),
      { threshold: [1] },
    );
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [isSticky]);

  return (
    <StyledHeader
      ref={ref}
      style={style}
      className={className}
      $noBorder={noBorder}
      $sticky={isSticky}
      $stuck={isStuck}
    >
      {customHeader ? children : <H3>{children}</H3>}
    </StyledHeader>
  );
};

export default Header;
