import { ScreenWrapper } from '../Screen/styles';

export const getPortalContainer = (
  suffix: string,
  parentTypes: ('screen' | 'modal')[] = ['modal', 'screen'],
) => {
  if (typeof window === 'undefined') return null;

  let screen: HTMLDivElement | undefined = undefined;
  let modal: HTMLDivElement | undefined = undefined;
  if (parentTypes.includes('screen')) {
    screen = document.querySelector(ScreenWrapper.toString()) as HTMLDivElement;
  }
  if (parentTypes.includes('modal')) {
    const allModals = document.querySelectorAll('[data-backdrop]') as NodeListOf<HTMLDivElement>;
    modal = allModals[allModals.length - 1];
  }
  const id = modal ? `modal-${suffix}` : screen ? `screen-${suffix}` : `app-${suffix}`;
  const parent = modal || screen || document.body;
  const el = parent.querySelector(`#${id}`);
  if (el) {
    return el;
  }
  const container = document.createElement('div');
  container.setAttribute('id', id);
  parent.appendChild(container);
  return container;
};
