import styled from 'styled-components';

const Fieldset = styled.fieldset`
  /* resets */
  border: 0;
  margin: 1.5rem 0 0;
  min-width: 0;
  padding: 0;
`;

export default Fieldset;
