import { css } from 'styled-components';
import { media } from '../utils/media';

type Size = 'xs' | 'sm' | 'md' | 'lg';

export const selectMedia =
  (propName: string, sizeKey?: Size) =>
  ({ [propName]: prop }: any) =>
    /* a style block without sizeKey implies the default style
     which is either passed as {xs: value } or value */
    prop && (sizeKey ? prop[sizeKey] : typeof prop === 'object' ? prop.xs : prop);

type StyleFunc = (size?: Size) => any;

const withMediaQueries = (styleBlock: StyleFunc) => css`
  /* passing no sizeKey here made selectMedia called without sizeKey */
  ${styleBlock()}

  ${media.greaterThan('mobile')} {
    ${styleBlock('sm')}
  }

  ${media.greaterThan('tablet')} {
    ${styleBlock('md')}
  }

  ${media.greaterThan('desktop')} {
    ${styleBlock('lg')}
  }
`;

export default withMediaQueries;
