import { toBoolean } from '@mnd-frontend/ts';

export const getBrandReportSearchProfiles = (
  report?: {
    searchProfiles?: ({ id: string; name: string } | null)[] | null;
    __typename: string;
  } | null,
): string[] => {
  if (report?.__typename !== 'BrandReport') return [];

  return report.searchProfiles?.map(a => a?.id).filter(toBoolean) ?? [];
};
