import { interpolateRgbBasis } from 'd3-interpolate';
import { scaleSequential } from 'd3-scale';
import { GRAPH_COLORS_ALL } from '../../../theme';

const WIDER = 0.5;

const getColorScale = (graphColors: string[], count: number, offset = 0) =>
  scaleSequential()
    .domain([offset - WIDER / count, count - 1 + offset + WIDER / count])
    .interpolator(interpolateRgbBasis(graphColors));

const getScalesByGroupSize =
  (palettes: string[][], count: number, groupSize: number) => (index: number) => {
    const group = Math.floor(index / groupSize);
    const actualGroupSize = Math.min(count - group * groupSize, groupSize);
    return getColorScale(palettes[group], actualGroupSize, groupSize * group)(index);
  };

export const getSingleColorScale = (group: number, count: number, offset?: number) =>
  getColorScale(GRAPH_COLORS_ALL[group], count, offset);

export const getMultiColorScale = (count: number, palettes = GRAPH_COLORS_ALL) => {
  const paletteCount = palettes.length;
  if (count <= 3) return getScalesByGroupSize(palettes, count, 1);
  if (count <= 3 * 2) return getScalesByGroupSize(palettes, count, 2);
  if (count <= 5 * 3) return getScalesByGroupSize(palettes, count, 3);
  return (index: number) => {
    const minSize = Math.floor(count / paletteCount);
    const maxSize = minSize + 1;
    const leftover = count % paletteCount;
    const group = Math.max(Math.floor(index / maxSize), Math.floor((index - leftover) / minSize));
    const groupSize = minSize + (group < leftover ? 1 : 0);
    const groupOffset = minSize * group + Math.min(group, leftover);
    return getColorScale(palettes[group], groupSize, groupOffset)(index);
  };
};

export const getAlternatingColorScale = (count: number, palettes = GRAPH_COLORS_ALL) => {
  const paletteCount = palettes.length;
  return (index: number) => {
    const minSize = Math.floor(count / paletteCount);
    const leftover = count % paletteCount;
    const group = index % paletteCount;
    const groupSize = minSize + (group < leftover ? 1 : 0);
    return getColorScale(palettes[group], groupSize)(Math.floor(index / paletteCount));
  };
};
