import React from 'react';
import styled from 'styled-components';
import { P } from '../Typo';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 0.375rem;
  i {
    flex-shrink: 0;
    height: ${({ theme }) => theme.sizingIconXxs};
    width: ${({ theme }) => theme.sizingIconXxs};
  }
`;

type Props = {
  text: string;
  icon: React.ReactNode;
  className?: string;
};

const Helper: React.FC<Props> = ({ text, icon, className }) => (
  <Wrapper className={className}>
    {icon}
    <P $size="small" $subdued>
      {text}
    </P>
  </Wrapper>
);

export default Helper;
