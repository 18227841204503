import { css } from 'styled-components';

export const withLineClamp = (lines: number) => css`
  text-overflow: ellipsis;
  overflow: hidden;
  line-clamp: ${lines};
  -webkit-line-clamp: ${lines};
  display: -webkit-box;
  box-orient: vertical;
  -webkit-box-orient: vertical;
`;
